import { Box, FormControlLabel, Radio, RadioGroup, TableCell, TableRow } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetValue } from 'react-hook-form/dist/types';

import { IPaymentDetailForm } from '../../../models/reservation';
import * as Images from '../../Images';
interface IReservationPaymentListingItem {
  reservationRow: any;
  selectedCard: string | null;
  setSelectedCard: UseFormSetValue<IPaymentDetailForm>;
  showRadioButton?: boolean;
  setOpenDeleteModal: (id: string) => void;
}

const ReservationPaymentListingItem = (props: IReservationPaymentListingItem) => {
  const {
    reservationRow = {},
    selectedCard,
    setSelectedCard,
    showRadioButton = false,
    setOpenDeleteModal,
  } = props || {};
  const {
    billTo = {},
    payment: { creditCard = {} } = {},
    customerPaymentProfileId = '',
  } = reservationRow || {};

  const onChangeRow = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCard('selectedCard', e.target.value);
    setSelectedCard('cardNumber', creditCard.cardNumber);
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell scope='row'>
        {!showRadioButton ? (
          billTo?.firstName ?? ''
        ) : (
          <RadioGroup
            aria-labelledby='radio-buttons-group-label'
            name='radio-buttons-group'
            value={selectedCard || null}
            onChange={onChangeRow}
          >
            <FormControlLabel
              value={customerPaymentProfileId}
              control={<Radio />}
              label={billTo?.firstName ?? ''}
            />
          </RadioGroup>
        )}
      </TableCell>
      <TableCell>{creditCard.cardNumber}</TableCell>
      <TableCell>{creditCard.expirationDate}</TableCell>
      <TableCell>{creditCard.cardType}</TableCell>
      <TableCell align='right'>
        <Box
          component='button'
          className='tdcell_action'
          onClick={() => setOpenDeleteModal(reservationRow?.id)}
        >
          <Box component='img' src={Images.deleteIc}></Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ReservationPaymentListingItem;
