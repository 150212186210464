import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { CustomTooltip } from '../../CustomTooltip';
import {
  ExpandLess,
  ExpandMore,
  ManageAccounts as ManageAccountsIcon,
  DisplaySettings as DisplaySettingsIcon,
  Summarize as SummarizeIcon,
  CurrencyExchange as CurrencyExchangeIcon,
} from '@mui/icons-material';
import useMediaQuery from '../../../hooks/useMediaQuery';
interface INavBarItem {
  open: boolean;
  label: string;
  url: string;
  icon: JSX.Element | ReactElement<any, any>;
  isSelectedTab: boolean;
  setOpen: (open: boolean) => void;
  currentPath?: string;
}

const NavBarItem = ({
  open,
  label,
  icon,
  url,
  isSelectedTab,
  setOpen,
  currentPath,
}: INavBarItem): JSX.Element => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [reportCollapse, setReportCollapse] = useState(false);
  const matches = useMediaQuery('(max-width: 1200px)');
  const navigate = useNavigate();
  const handleClick = () => {
    if (label == 'Settings') {
      setOpenDropdown(!openDropdown);
    } else if (label == 'Reports') {
      setReportCollapse(!reportCollapse);
    } else handleNavigate(url);
  };
  const handleNavigate = (url: string) => {
    navigate(url);
    if (matches) {
      setOpen(true);
    }
  };

  return (
    <ListItem disablePadding sx={{ display: 'block' }} selected={isSelectedTab}>
      <ListItemButton
        onClick={handleClick}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        className={label == 'Settings' ? 'arrow' : ''}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: !open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {open ? (
            <CustomTooltip title={label} placement='right' arrow>
              {icon}
            </CustomTooltip>
          ) : (
            <>{icon}</>
          )}
        </ListItemIcon>
        <ListItemText primary={label} sx={{ opacity: !open ? 1 : 0 }} />
        {label == 'Settings' && (openDropdown ? <ExpandLess /> : <ExpandMore />)}
        {label == 'Reports' && (reportCollapse ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {label == 'Settings' && (
        <Collapse in={openDropdown} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItemButton
              sx={{ pl: 3 }}
              onClick={() => navigate('/admin-settings')}
              selected={currentPath === '/admin-settings'}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {open ? (
                  <CustomTooltip title='Reservation' placement='right' arrow>
                    <ManageAccountsIcon />
                  </CustomTooltip>
                ) : (
                  <>
                    <ManageAccountsIcon />
                  </>
                )}
              </ListItemIcon>
              <ListItemText primary='Reservation' sx={{ opacity: !open ? 1 : 0 }} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 3 }}
              onClick={() => navigate('/cms-settings')}
              selected={currentPath === '/cms-settings'}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {open ? (
                  <CustomTooltip title='Homepage' placement='right' arrow>
                    <DisplaySettingsIcon />
                  </CustomTooltip>
                ) : (
                  <>
                    <DisplaySettingsIcon />
                  </>
                )}
              </ListItemIcon>
              <ListItemText primary='Homepage' sx={{ opacity: !open ? 1 : 0 }} />
            </ListItemButton>
          </List>
        </Collapse>
      )}
      {label == 'Reports' && (
        <Collapse in={reportCollapse} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItemButton
              sx={{ pl: 3 }}
              onClick={() => navigate('/inventory-reports')}
              selected={currentPath === '/inventory-reports'}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {open ? (
                  <CustomTooltip title='Inventory Report' placement='right' arrow>
                    <SummarizeIcon />
                  </CustomTooltip>
                ) : (
                  <>
                    <SummarizeIcon />
                  </>
                )}
              </ListItemIcon>
              <ListItemText primary='Inventory Report' sx={{ opacity: !open ? 1 : 0 }} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 3 }}
              onClick={() => navigate('/reservation-revenue')}
              selected={currentPath === '/reservation-revenue'}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {open ? (
                  <CustomTooltip title='Homepage' placement='right' arrow>
                    <CurrencyExchangeIcon />
                  </CustomTooltip>
                ) : (
                  <>
                    <CurrencyExchangeIcon />
                  </>
                )}
              </ListItemIcon>
              <ListItemText primary='Revenue Report' sx={{ opacity: !open ? 1 : 0 }} />
            </ListItemButton>
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};

export default NavBarItem;
