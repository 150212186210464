import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { Alert, Box, CircularProgress, Grid } from '@mui/material';
import './ChangePassword.scss';
import { ModalTitle } from '../../components/sidenav/modal/ModalHeader';
import * as Yup from 'yup';
import { Messages, Severity } from '../../enum/enumList';
import { PasswordChange } from '../../models/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from '../../graphql/api/user';
import { formIsValid } from '../../utils/formValidations';
import { ChangePasswordFormSchema } from './Validations/ChangePasswordValidate';
import { CustomSnackbar } from '../../hoc/CustomSnackbar';
import { ILayoutWrapper } from '../../models/pages';
import { ISnackBar } from '../../models/common';
/* eslint-disable no-useless-escape */
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface Props {
  modelOpen: boolean;
  setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  snackbarShowMessage?: ISnackBar;
}
interface PasswordValidate {
  hasNumber: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasSpecial: boolean;
  hasMinLength: boolean;
}

const ChangePassword: FC<Props> = ({ modelOpen, setModelOpen, snackbarShowMessage }: Props) => {
  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<PasswordChange>({
    resolver: yupResolver(ChangePasswordFormSchema),
  });
  const initialState = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };
  const validateInitial = {
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecial: false,
    hasMinLength: false,
  };
  // const [setValues] = useState<PasswordChange>(initialState);
  const [validateStatus, setValidationStatus] = useState<PasswordValidate>(validateInitial);
  const [isFormValid, isValid] = useState<boolean>(false);

  const [changePassword, { data, error, reset }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: () => {
      if (snackbarShowMessage)
        snackbarShowMessage('Password updated successfully.', Severity.Success);
      isValid(false);
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
    onError: (error) => {
      if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
      setTimeout(() => {
        isValid(false);
      }, 10);
    },
  });

  const onSubmit = (data: PasswordChange) => {
    const areTrue = Object.values(validateStatus).every((value) => value === true);
    if (areTrue) {
      isValid(true);
      changePassword({
        variables: {
          changePasswordArgs: {
            oldPassword: data.oldPassword,
            password: data.password,
          },
          isAdmin: true,
        },
      });
    }
  };

  const handleClose = () => {
    setModelOpen(false);
    reset();
    resetForm();

    // setValues({ ...initialState });
    setValidationStatus({ ...validateInitial });
  };

  const validatePassword = (values: any) => {
    setValidationStatus({
      ...validateStatus,
      hasNumber: formIsValid(values).hasNumber,
      hasUpperCase: formIsValid(values).hasUpperCase,
      hasLowerCase: formIsValid(values).hasLowerCase,
      hasSpecial: formIsValid(values).hasSpecial,
      hasMinLength: formIsValid(values).hasMinLength,
    });
  };

  // useEffect(() => {
  //   resetForm({ oldPassword: '', password: '', confirmPassword: '' });
  // }, [message]);

  const clearError = () => {
    reset();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='dialog-title'
        fullWidth
        maxWidth='md'
        open={modelOpen}
        className='modal'
      >
        <ModalTitle id='dialog-title' className='modal__heading' onClose={handleClose}>
          Change Password
        </ModalTitle>
        <DialogContent dividers>
          <Grid container component='main'>
            <Grid item xs={false} md={6} sm={6} className='modal-lcol'>
              <Box component='form' className='modal-col__row'>
                <Box component='label' className='row__label'>
                  Old Password<Box component='span'>*</Box>
                </Box>
                <TextField
                  fullWidth
                  id='oldPassword'
                  placeholder='Old Password'
                  type='password'
                  {...register('oldPassword')}
                  onKeyUp={clearError}
                />
                {errors.oldPassword && (
                  <Box component='span' className='error'>
                    {errors.oldPassword?.message}
                  </Box>
                )}
              </Box>

              <Box component='div' className='modal-col__row'>
                <Box component='label' className='row__label'>
                  New Password<Box component='span'>*</Box>
                </Box>
                <TextField
                  fullWidth
                  placeholder='New Password'
                  type='password'
                  onKeyUp={validatePassword}
                  {...register('password', {
                    required: true,
                  })}
                />
                {errors.password && (
                  <Box component='span' className='error'>
                    {errors.password?.message}
                  </Box>
                )}
              </Box>
              <Box component='div' className='modal-col__row'>
                <Box component='label' className='row__label'>
                  Confirm Password<Box component='span'>*</Box>
                </Box>
                <TextField
                  fullWidth
                  id='confirmPassword'
                  placeholder='Confirm Password'
                  type='password'
                  {...register('confirmPassword')}
                />
                {errors.confirmPassword && (
                  <Box component='span' className='error'>
                    {errors.confirmPassword?.message}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={false} md={6} sm={6} className='modal-rcol'>
              <Box component='div' className='modal-rcol__heading'>
                Password Requirements:
              </Box>
              <Box component='ul'>
                <Box component='li' className={validateStatus.hasLowerCase ? 'tick' : ''}>
                  At least one lower case letter [a-z]
                </Box>
                <Box component='li' className={validateStatus.hasUpperCase ? 'tick' : ''}>
                  At least one upper case letter [A-Z]
                </Box>
                <Box component='li' className={validateStatus.hasNumber ? 'tick' : ''}>
                  At least one number [0-9]
                </Box>
                <Box component='li' className={validateStatus.hasSpecial ? 'tick' : ''}>
                  {/* Added *(asterisk) */}
                  At least one symbol [!, @, $, &, #, ^,-,*]
                </Box>
                <Box component='li' className={validateStatus.hasMinLength ? 'tick' : ''}>
                  Minimum 10 characters
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className='modal__footer'>
          <Box sx={{ position: 'relative' }}>
            <Button
              type='submit'
              disabled={isFormValid}
              className='primary-btn'
              onClick={handleSubmit(onSubmit)}
            >
              Update Password
            </Button>
            {isFormValid && <CircularProgress size={24} className='btn-loader' />}
          </Box>
          <Button onClick={handleClose} className='cancel-btn'>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default CustomSnackbar(ChangePassword);
