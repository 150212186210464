import { FC, useContext } from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Stepper, Step, StepLabel, Dialog, styled } from '@mui/material';
import {
  InfoOutlined as InfoOutlinedIcon,
  CollectionsBookmarkOutlined as CollectionsBookmarkOutlinedIcon,
  SubtitlesOutlined as SubtitlesOutlinedIcon,
  ExtensionOutlined as ExtensionOutlinedIcon,
} from '@mui/icons-material';

import { PackageDetail } from './steps/PackageDetail';
import { RenterDetail } from './steps/RenterDetail';
import { ModalTitle } from '../../sidenav/modal/ModalHeader';
import { SubPackageDetail } from './steps/SubPackageDetail';
import { RenterModalContext } from './RenterModalContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const steps = ['Renter Detail', 'Package', 'Sub Package & Addons'];

export const AddEditRenter: FC = () => {
  const { activeStep, setModelOpen, modelOpen, resetModal } = useContext(RenterModalContext);

  const handleClose = () => {
    resetModal();
    setModelOpen(false);
  };

  const formRender = (activeStep: number) => {
    switch (activeStep) {
      case 0: {
        return <RenterDetail />;
      }
      case 1: {
        return <PackageDetail />;
      }
      case 2: {
        return <SubPackageDetail closeModal={handleClose} />;
      }
    }
  };
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 24,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: '#0E6EC2',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: '#0E6EC2',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#003348',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#003348',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: '#0E6EC2',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor: '#0E6EC2',
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <InfoOutlinedIcon />,
      2: <CollectionsBookmarkOutlinedIcon />,
      3: <SubtitlesOutlinedIcon />,
      4: <ExtensionOutlinedIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  return (
    <BootstrapDialog
      aria-labelledby='addon-title'
      fullWidth
      maxWidth='md'
      open={modelOpen}
      className='modal'
    >
      <ModalTitle
        id='addon-title'
        className='modal__heading modal__heading--border-space'
        onClose={handleClose}
      >
        Select Package
      </ModalTitle>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {formRender(activeStep)}
    </BootstrapDialog>
  );
};
