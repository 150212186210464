import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { MonetizationOn as MonetizationOnIcon, Add as AddIcon } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';

import {
  CustomTable,
  ReservationTransactionListItem,
  CustomInput,
  ReservationPaymentTable,
  CustomSubmitButton,
  DeleteConfirmation,
  DeleteModal,
} from '../../../../../components';
import { transactionHeadCells } from '../paymentTabData';
import { PaymentTabContext } from '../PaymentTab';
import formatPrice from '../../../../../utils/formatPrice';
import { Messages, paymentMode, paymentType, Severity } from '../../../../../enum/enumList';
import { paymentTabValidator } from '../../validation/reservationDetailValidation';
import { IPaymentDetailForm } from '../../../../../models/reservation';
import {
  CHARGE_PAYMENT,
  DELETE_CARD,
  REFUND_PAYMENT,
} from '../../../../../graphql/api/reservation';
import { useAuth } from '../../../../../hooks/useAuth';
import TransactionWrapper from './TransactionWrapper';

const PaymentDetails = () => {
  const { user } = useAuth();
  const {
    card,
    pricing,
    reservationId,
    transactions,
    updatePaymentTabData,
    snackbarShowMessage,
    openAddCardModal,
  } = useContext(PaymentTabContext);
  const [openDeleteModal, setOpenDeleteModal] = useState('');
  const [refundConfirmationModal, setRefundModal] = useState(false);
  const [isVoidTransactionLoading, setIsVoidTransactionLoading] = useState(false);

  const [currentTransactionAmount, setCurrentTransactionAmount] = useState<
    number | null | undefined
  >(null);

  const [refundPayment, { loading: refundPaymentLoading }] = useMutation(REFUND_PAYMENT);
  const [chargePayment, { loading: chargePaymentLoading }] = useMutation(CHARGE_PAYMENT);
  const [deleteCard, { loading: deleteCardLoading }] = useMutation(DELETE_CARD);

  const defaultValues: IPaymentDetailForm = {
    isPos: false,
    transactionType: paymentType.charge,
    amount: '',
    notes: '',
    selectedCard: '',
    selectedTransaction: '',
    transactionAmount: null,
    cardNumber: '',
  };
  const {
    trigger,
    watch,
    control,
    handleSubmit,
    setValue,
    resetField,
    clearErrors,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      paymentTabValidator(pricing.amountDue, pricing.actualAmountPaid, currentTransactionAmount),
    ),
  });
  const watchAllFields = watch();

  useEffect(() => {
    if (pricing?.amountDue <= 0) {
      setValue('transactionType', paymentType.refund);
    }
  }, [pricing?.amountDue]);

  useEffect(() => {
    trigger('amount');
  }, [watchAllFields.amount]);

  useEffect(() => {
    if (errors.amount && refundConfirmationModal) {
      setRefundModal(false);
    } else {
      if (watchAllFields.transactionType === paymentType.charge) {
        if (errors.selectedCard && errors.selectedCard.message && snackbarShowMessage) {
          snackbarShowMessage(errors.selectedCard.message, Severity.Error);
        }
      } else {
        if (
          errors.selectedTransaction &&
          errors.selectedTransaction.message &&
          snackbarShowMessage
        ) {
          snackbarShowMessage(errors.selectedTransaction.message, Severity.Error);
        }
      }
    }
  }, [errors.selectedCard, errors.selectedTransaction, errors.amount]);

  // Focus on amount field when error on amount field occurs
  useEffect(() => {
    if (errors.amount?.message) {
      setFocus('amount');
    }
  }, [errors.amount?.message]);

  useEffect(() => {
    if (watchAllFields.amount) {
      trigger('amount');
    }
  }, [watchAllFields.transactionType]);

  useEffect(() => {
    // chek if card has only one then select that else dont select any
    if (watchAllFields.isPos) return;
    if (watchAllFields.transactionType === paymentType.charge) {
      if (card?.paymentProfiles?.length === 1) {
        setValue('selectedCard', card.paymentProfiles[0]?.customerPaymentProfileId);
        setValue('cardNumber', card.paymentProfiles[0]?.payment.creditCard.cardNumber);
      }
    }
  }, [card?.paymentProfiles, watchAllFields.transactionType]);

  // prefill amount value in case of refund transaction
  useEffect(() => {
    const selectedTransaction = transactions?.find(
      (item) => item.transactionId === watchAllFields.selectedTransaction,
    );

    if (selectedTransaction && !watchAllFields?.amount)
      setValue('amount', selectedTransaction?.amount - selectedTransaction?.refundedAmount);
  }, [watchAllFields.selectedTransaction]);

  useEffect(() => {
    if (watchAllFields.isPos) {
      setValue('selectedTransaction', '');
      setValue('transactionAmount', null);
      clearErrors('selectedTransaction');
      // clearErrors('amount');
      trigger('amount');
    }
  }, [watchAllFields.isPos]);

  useEffect(() => {
    setCurrentTransactionAmount(watchAllFields.transactionAmount);
  }, [watchAllFields.transactionAmount]);

  useEffect(() => {
    if (watchAllFields.transactionType === paymentType.charge)
      setValue('amount', pricing?.amountDue || 0);
    if (watchAllFields.transactionType === paymentType.refund) {
      resetField('amount');
    }
  }, [pricing, watchAllFields.transactionType]);

  const handleOpenDeleteModal = (id: string) => {
    // Cannot delete last card
    if (card?.paymentProfiles?.length <= 1 && snackbarShowMessage) {
      snackbarShowMessage(Messages.lastCardDelete, Severity.Error);
    } else {
      setOpenDeleteModal(id);
    }
  };

  const handlePayment = (data: IPaymentDetailForm, isVoidTransaction?: boolean) => {
    const payload = {
      reservationId,
      storeId: user?.selectedStore,
      paymentProfileId: data.isPos ? null : data.selectedCard,
      amount: data.amount,
      mode: data.isPos ? paymentMode.pos : paymentMode.card,
      notes: data.notes,
    };
    if (data.transactionType === paymentType.charge && !isVoidTransaction)
      chargePayment({
        variables: {
          managePaymentArgs: {
            ...payload,
            card: data.isPos ? null : data.cardNumber,
          },
        },
        onCompleted(data) {
          // set amount to remaining amoutn and reset notes field
          setValue('amount', data?.chargePayment?.pricing?.amountDue || 0);
          resetField('notes');

          updatePaymentTabData({
            pricing: data?.chargePayment?.pricing,
            transactions: data?.chargePayment?.transactions,
          });

          if (snackbarShowMessage)
            snackbarShowMessage(data?.chargePayment?.message, Severity.Success);
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
        },
      });

    if (data.transactionType === paymentType.refund || isVoidTransaction) {
      setIsVoidTransactionLoading(true);
      refundPayment({
        variables: {
          manageRefundPaymentArgs: {
            ...payload,
            card: data.isPos ? null : data.cardNumber,
            transactionId: data.isPos ? null : data.selectedTransaction,
            paymentProfileId: transactions?.find(
              (item) => item.transactionId === data.selectedTransaction,
            )?.paymentProfileId,
            isVoidTransaction: !!isVoidTransaction,
          },
        },
        onCompleted(data) {
          setRefundModal(false);
          setIsVoidTransactionLoading(false);
          // reset amount and notes field
          resetField('amount');
          resetField('notes');
          resetField('selectedTransaction');

          updatePaymentTabData({
            pricing: data?.refundPayment?.pricing,
            transactions: data?.refundPayment?.transactions,
          });

          if (snackbarShowMessage)
            snackbarShowMessage(data?.refundPayment?.message || '', Severity.Success);
        },
        onError(error) {
          setIsVoidTransactionLoading(false);
          if (snackbarShowMessage) snackbarShowMessage(error?.message || '', Severity.Error);
        },
      });
    }
  };

  const handleFormSubmit = (data: IPaymentDetailForm) => {
    handlePayment(data, false);
  };

  const handleDeleteCard = (cardId: string) => {
    deleteCard({
      variables: {
        reservationId,
        storeId: user?.selectedStore,
        paymentProfileId: cardId,
      },
      onCompleted(data) {
        setOpenDeleteModal('');
        updatePaymentTabData({
          card: {
            profile: {
              ...card,
              paymentProfiles: card?.paymentProfiles?.filter(
                (item) => item.customerPaymentProfileId !== cardId,
              ),
            },
          },
        });
        if (snackbarShowMessage) snackbarShowMessage(data?.deleteCard?.message, Severity.Success);
      },
      onError(error) {
        if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
      },
    });
  };

  const openRefundConfirmation = () => {
    if (
      watchAllFields?.transactionAmount &&
      watchAllFields?.selectedTransaction &&
      parseFloat(`${watchAllFields?.amount}`) > 0
    ) {
      setRefundModal(true);
    } else {
      if (
        watchAllFields?.selectedTransaction &&
        ((watchAllFields?.amount as string).length === 0 ||
          parseFloat(`${watchAllFields?.amount}`) <= 0)
      ) {
        if (snackbarShowMessage) snackbarShowMessage(Messages.amountMsg, Severity.Error);
      } else {
        if (snackbarShowMessage) snackbarShowMessage(Messages.transactionRequired, Severity.Error);
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={10}>
        <Box className='small-font mb-1'>
          Actual amount paid <strong>{formatPrice(pricing?.actualAmountPaid || 0)}</strong> of total{' '}
          <strong>{formatPrice(pricing?.totalAmount || 0)}</strong> and remaining amount is{' '}
          <strong>{formatPrice(pricing?.amountDue || 0)}</strong>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={10} className='pt-0'>
        <Controller
          control={control}
          name='isPos'
          render={({ field }) => (
            <FormControlLabel
              onChange={field.onChange}
              onBlur={field.onBlur}
              control={<Checkbox />}
              label='POS'
              className='ml-0'
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={10} className='pt-0'>
        <Controller
          control={control}
          name='transactionType'
          render={({ field }) => (
            <RadioGroup
              aria-labelledby='radio-buttons-group-label'
              value={field?.value}
              name='radio-buttons-group'
              className='flex-row'
            >
              {!!pricing.amountDue && (
                <FormControlLabel
                  value={paymentType.charge}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  control={<Radio />}
                  label='Charge'
                />
              )}
              <FormControlLabel
                value={paymentType.refund}
                onChange={field.onChange}
                onBlur={field.onBlur}
                control={<Radio />}
                label='Refund'
              />
            </RadioGroup>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={5} className='pt-0'>
        <Controller
          name='amount'
          control={control}
          render={({
            field: { value, onChange, ref },
            formState: {
              errors: { amount },
            },
          }) => (
            <>
              <Box component='label' className='label'>
                Amount
                <Box component='span'>*</Box>
              </Box>
              <TextField
                fullWidth
                className='amount-input-style'
                id='amount'
                name='amount'
                inputRef={ref}
                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                placeholder='Enter Amount ($)'
                type='number'
                onChange={onChange}
                value={value}
              />
              {amount && amount.message && <Typography color='red'>{amount?.message}</Typography>}
            </>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={10}>
        <Controller
          name='notes'
          control={control}
          render={({ field, formState }) => (
            <CustomInput
              label='Notes'
              mainClassName='label'
              name='notes'
              placeholder='Enter Notes'
              className='textarea-input textarea-input--min-height'
              type='number'
              textInputProps={{ maxLength: 499 }}
              field={field}
              error={formState.errors.notes}
              inputProps={{
                inputComponent: TextareaAutosize,
              }}
              fullWidth
              multiline
            />
          )}
        />
      </Grid>

      <TransactionWrapper transactionType={watchAllFields.transactionType}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {(!watchAllFields.isPos || watchAllFields.transactionType === paymentType.refund) && (
            <Grid>
              <Box
                component='div'
                className='inner-content__heading-outer inner-content__heading-outer--border-btm inner-content__heading-outer--btn inner-content__heading-outer--flex-wrap'
              >
                <Box component='h3' className='heading-outer__flex-box'>
                  Customer Credit Card Profile{' '}
                  <Box component='span'>(Please select a customer profile below to charge)</Box>
                </Box>
                <Button type='button' className='primary-btn' onClick={openAddCardModal}>
                  <AddIcon /> Add Card
                </Button>
              </Box>

              <Controller
                control={control}
                name='selectedCard'
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby='radio-buttons-group-label'
                    name='radio-credit-card'
                    className='flex-row'
                  >
                    <ReservationPaymentTable
                      paymentProfiles={card?.paymentProfiles || []}
                      selectedCard={field.value || ''}
                      setSelectedCard={setValue}
                      showRadioButton={watchAllFields.transactionType === paymentType.charge}
                      setOpenDeleteModal={handleOpenDeleteModal}
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomSubmitButton
            sx={{ marginTop: 1 }}
            onClick={
              watchAllFields.transactionType === paymentType.charge || watchAllFields?.isPos
                ? handleSubmit(handleFormSubmit)
                : openRefundConfirmation
            }
            isLoading={(chargePaymentLoading || refundPaymentLoading) && !isVoidTransactionLoading}
          >
            <MonetizationOnIcon sx={{ fontSize: '20px', marginRight: 1 }} />{' '}
            {watchAllFields.transactionType === paymentType.charge ? 'Charge' : 'Refund'}
          </CustomSubmitButton>
        </Grid>

        {/* {(!watchAllFields.isPos || watchAllFields.transactionType === paymentType.charge) && ( */}
        <Grid item xs={12} sm={12} md={12} lg={12} className='pt-0'>
          <Box
            sx={{ marginTop: 1 }}
            component='div'
            className='inner-content__heading-outer inner-content__heading-outer--border-btm inner-content__heading-outer--btn'
          >
            <Box component='h3' className='mt-1'>
              Transaction Details
            </Box>
          </Box>
          <Controller
            control={control}
            name='selectedTransaction'
            render={({ field }) => (
              <RadioGroup
                aria-labelledby='radio-buttons-group-label'
                name='radio-transactions'
                className='flex-row'
              >
                <CustomTable
                  className='inner-content__table-wrap'
                  tableClassName='table-wrap__table table-wrap__table--trans-history'
                  rows={transactions || []}
                  SingleRowComponent={ReservationTransactionListItem}
                  headCells={transactionHeadCells}
                  tableItemProps={{
                    selectedTransaction: field.value || '',
                    setSelectedTransaction: setValue,
                    showRadioButton:
                      watchAllFields.transactionType === paymentType.refund &&
                      !watchAllFields.isPos,
                    handlePayment,
                    refundPaymentLoading,
                  }}
                  isPagination={false}
                  isActionRow
                />
              </RadioGroup>
            )}
          />
        </Grid>
        {/* )} */}
      </TransactionWrapper>

      <DeleteConfirmation
        modelOpen={!!openDeleteModal}
        selectedItem='this card'
        setModelOpen={() => setOpenDeleteModal('')}
        onDelete={() => handleDeleteCard(openDeleteModal)}
        loading={deleteCardLoading}
      />
      <DeleteModal
        modelOpen={refundConfirmationModal}
        setModelOpen={(status) => setRefundModal(status)}
        onDelete={handleSubmit(handleFormSubmit)}
        loading={refundPaymentLoading}
        label={`You are refunding an Amount of \u0024${watchAllFields?.amount}`}
        buttonText='Refund'
      />
    </Grid>
  );
};

export default PaymentDetails;
