import { Grid } from '@mui/material';
import { Box } from '@mui/system';

import NoRecordFound from '../../../../common/noRecordFound/NoRecordFound';

interface IGeneralTab {
  renterDetail?: any;
}

const EquipmentsTab = ({ renterDetail }: IGeneralTab) => {
  if (!renterDetail?.equipments?.equipment?.length && !renterDetail?.equipments?.manual?.length)
    return <NoRecordFound text='No equipment yet assigned' />;

  return (
    <Grid container component='main' className='modal-lcol modal-lcol--border-none' spacing={3}>
      {renterDetail?.equipments?.equipment?.map((equipment: any) => (
        <Grid item xs={12} md={6} sm={12} key={equipment?.id}>
          <Box component='div' className='panel panel--column panel--stripe'>
            <Box component='div' className='panel__header'>
              <Grid item xs={12} md={12} className='pt-0'>
                <strong className='text-uppercase'>{equipment?.inventoryType}</strong>
              </Grid>
            </Box>
            <Box component='div' className='panel__content-row'>
              <Box component='label'>Manufacture</Box> {equipment?.manufacture || 'N/A'}
            </Box>
            <Box component='div' className='panel__content-row'>
              <Box component='label'>Model</Box> {equipment?.model || 'N/A'}
            </Box>
            <Box component='div' className='panel__content-row'>
              <Box component='label'>Size</Box> {equipment?.size || 'N/A'}
            </Box>
            <Box component='div' className='panel__content-row'>
              <Box component='label'>Barcode</Box>{' '}
              {equipment?.barcode1 || equipment?.barcode2 || 'N/A'}
            </Box>
          </Box>
        </Grid>
      ))}

      {renterDetail?.equipments?.manual?.map((equipment: any) => (
        <Grid item xs={12} md={6} sm={12} key={equipment?.id}>
          <Box component='div' className='panel panel--column panel--stripe'>
            <Box component='div' className='panel__header'>
              <Grid item xs={12} md={12} className='pt-0'>
                <strong className='text-uppercase'>{equipment?.inventoryType}</strong>
              </Grid>
              <Grid item xs={12} md={12} className='pt-0 flex-row'>
                <Box component='span' className='badge badge--manual'>
                  Manual
                </Box>
              </Grid>
            </Box>
            <Box component='div' className='panel__content-row'>
              <Box component='label'>Binding Model</Box> {equipment?.bindingModel || 'N/A'}
            </Box>
            <Box component='div' className='panel__content-row'>
              <Box component='label'>Length</Box> {equipment?.length || 'N/A'}
            </Box>
            <Box component='div' className='panel__content-row'>
              <Box component='label'>Binding Type</Box> {equipment?.type || 'N/A'}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default EquipmentsTab;
