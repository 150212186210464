import { createContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { DELETE_DAILY_ADDONS, GET_ADDONS_LIST } from '../../graphql/api/addons';
import { useAuth } from '../../hooks/useAuth';
import { IAddonContext, IAddonListItem, IAddonProvider } from '../../models/addons';
import { Severity, SeasonLabel } from '../../enum/enumList';

export const AddonContext = createContext<IAddonContext>({} as IAddonContext);

export const AddonProvider = ({
  children,
  tabValue,
  setTabValue,
  snackbarShowMessage,
  isSummerAddon,
}: IAddonProvider) => {
  const [addonsList, setAddonsList] = useState<IAddonListItem[]>([]);
  const [isEditmodal, setIsEditmodal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState({
    isOpen: false,
    deleteId: '',
  });
  const { user } = useAuth();
  const storeId = user?.selectedStore;

  // api call to get addons list
  const [
    getAddonsList,
    { data: { listAddons: { data: addonList = [] } = {} } = {}, loading: addonListLoading },
  ] = useLazyQuery(GET_ADDONS_LIST, {
    variables: {
      storeId,
      season: isSummerAddon ? SeasonLabel.summer : SeasonLabel.winter,
    },
    fetchPolicy: 'network-only',
  });

  // delete adddon api
  const [deleteAddon, { loading: deleteAddonLoading }] = useMutation(DELETE_DAILY_ADDONS);

  useEffect(() => {
    getAddonsList();
  }, [tabValue, isSummerAddon]);

  useEffect(() => {
    setAddonsList(addonList);
  }, [addonList]);

  const handleDeleteAddon = (addonId: string) => {
    deleteAddon({
      variables: {
        id: addonId,
        storeId,
      },
      onCompleted(res) {
        if (snackbarShowMessage)
          snackbarShowMessage(res?.deleteAddon?.message || '', Severity.Success);
        // remove the addon from the list
        const index = addonsList.findIndex((item) => item.id === addonId);
        if (index !== -1) {
          addonsList.splice(index, 1);
          setAddonsList([...addonsList]);
        }

        // close the delete modal
        setOpenDeleteModal({
          isOpen: false,
          deleteId: '',
        });
      },
      onError(error) {
        if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
      },
    });
  };

  const updateAddonList = (addon: IAddonListItem) => {
    // check if that adddon is already present in the list
    const index = addonsList.findIndex((item) => item.id === addon.id);
    if (index !== -1) {
      // if present then update the addon
      addonsList[index] = addon;
    } else {
      // if not present then add the addon
      addonsList.push(addon);
    }
    setAddonsList([...addonsList]);
  };

  const value = useMemo(
    () => ({
      addonsList,
      isEditmodal,
      setIsEditmodal,
      updateAddonList,
      addonListLoading,
      tabValue,
      setTabValue,
      snackbarShowMessage,
      handleDeleteAddon,
      deleteAddonLoading,
      openDeleteModal,
      setOpenDeleteModal,
      isSummerAddon,
    }),
    [
      addonsList,
      updateAddonList,
      addonListLoading,
      tabValue,
      setTabValue,
      snackbarShowMessage,
      handleDeleteAddon,
      isEditmodal,
      setIsEditmodal,
      deleteAddonLoading,
      openDeleteModal,
      setOpenDeleteModal,
      isSummerAddon,
    ],
  );

  return <AddonContext.Provider value={value}>{children}</AddonContext.Provider>;
};
