import { gql } from '@apollo/client';

export const ASSIGN_ADDONS = gql`
  mutation updateAddons($updateRentersAddonsArgs: UpdateRentersAddonsArgs!) {
    updateAddons(updateRentersAddonsArgs: $updateRentersAddonsArgs)
  }
`;

export const GET_BARCODE_DETAILS = gql`
  query getBarcodeDetails(
    $barcode: String!
    $inventoryType: String!
    $reservationId: Float!
    $renterId: String!
    $storeId: Float!
  ) {
    getBarcodeDetails(
      barcode: $barcode
      inventoryType: $inventoryType
      reservationId: $reservationId
      renterId: $renterId
      storeId: $storeId
    )
  }
`;

export const ASSIGN_EQUIPMENT = gql`
  mutation assignEquipment(
    $storeId: Float!
    $reservationId: Float!
    $renterId: String!
    $equipmentId: String!
    $inventoryType: String!
    $din: String
  ) {
    assignEquipment(
      storeId: $storeId
      reservationId: $reservationId
      renterId: $renterId
      equipmentId: $equipmentId
      inventoryType: $inventoryType
      din: $din
    )
  }
`;

export const MANUAL_ASSIGN_EQUIPMENT = gql`
  mutation assignManualEquipment($assignManualEquipmentArgs: AssignManualEquipmentArgs!) {
    assignManualEquipment(assignManualEquipmentArgs: $assignManualEquipmentArgs)
  }
`;

export const DIN_CALCULATIONS = gql`
  query dinCalculation(
    $weight: Float!
    $soleLength: Float!
    $heightFt: Float!
    $heightInches: Float!
    $age: Float!
    $skierType: Float!
  ) {
    dinCalculation(
      weight: $weight
      soleLength: $soleLength
      heightFt: $heightFt
      heightInches: $heightInches
      age: $age
      skierType: $skierType
    )
  }
`;

export const UNASSIGN_EQUIPMENT = gql`
  mutation unassignEquipment(
    $renterInfoArg: AssignManualEquipmentArgs!
    $equipmentInfoArg: TechEquipmentArgs!
  ) {
    unassignEquipment(renterInfoArg: $renterInfoArg, equipmentInfoArg: $equipmentInfoArg)
  }
`;
