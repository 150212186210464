import { FC, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import './ConfirmationModal.scss';

interface Props {
  modelOpen: boolean;
  setModelOpen: React.Dispatch<React.SetStateAction<any>>;
  onDelete: () => void;
  loading: boolean;
  selectedItem?: string;
}

const DeleteConfirmation: FC<Props> = ({
  modelOpen,
  setModelOpen,
  onDelete,
  loading,
  selectedItem,
}: Props) => {
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setModelOpen(false);
    setMessage('');
  };

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    setMessage(value);
  };

  useEffect(() => {
    if (!modelOpen) setMessage('');
  }, [modelOpen]);

  return (
    <Dialog open={modelOpen} onClose={handleClose} className='confirm-modal'>
      <DialogContent className='confirmation-box'>
        <WarningAmberIcon className='confirmation-box__circle' />

        <Box component='div' className='confirmation-box__content'>
          <h1>Are you Sure?</h1>
          <DialogContentText>
            Do you really want to delete {selectedItem}? <br /> This process cannot be undone.
          </DialogContentText>
        </Box>
        <Box component='label' className='confirmation-box__label'>
          Type DELETE to confirm
        </Box>
        <TextField
          autoFocus
          margin='dense'
          id='delete'
          type='text'
          fullWidth
          variant='outlined'
          onChange={onChangeText}
        />
        <DialogActions className='confirmation-box__footer'>
          <Button
            onClick={() => {
              onDelete();
              setMessage('DELETE');
            }}
            variant='contained'
            disabled={(message === 'DELETE' ? false : true) || loading}
            className='footer__btn delete-btn'
          >
            {!loading ? 'Delete' : <CircularProgress size={24} />}
          </Button>
          <Button onClick={handleClose} disabled={loading} className='footer__btn cancel-btn'>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmation;
