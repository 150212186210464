import { gql } from '@apollo/client';

export const GET_ADDONS_LIST = gql`
  query listAddons($storeId: Float!, $season: Season!) {
    listAddons(storeId: $storeId, season: $season) {
      success
      data {
        id
        name
        type
        createdAt
        price
      }
    }
  }
`;

export const ADD_DAILY_ADDONS = gql`
  mutation addAddon($addAddonArgs: AddAddonArgs!) {
    addAddon(addAddonArgs: $addAddonArgs) {
      success
      message
      data {
        id
        name
        type
        createdAt
        price
      }
    }
  }
`;

export const EDIT_DAILY_ADDONS = gql`
  mutation editAddon($editAddonArgs: EditAddonArgs!) {
    editAddon(editAddonArgs: $editAddonArgs) {
      success
      message
      data {
        id
        name
        type
        createdAt
        price
      }
    }
  }
`;

export const DELETE_DAILY_ADDONS = gql`
  mutation deleteAddon($id: String!, $storeId: Float!) {
    deleteAddon(id: $id, storeId: $storeId) {
      success
      message
    }
  }
`;
