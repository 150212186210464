import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface ILoader {
  style?: React.CSSProperties;
}

export default function Loader({
  style = {
    display: 'flex',
    height: 'calc(100vh - 19px)',
    width: 'calc(100vw - 19px)',
    justifyContent: 'center',
    alignItems: 'center',
  },
}: ILoader) {
  return (
    <Box sx={style}>
      <CircularProgress />
    </Box>
  );
}
