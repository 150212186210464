import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';

interface IGeneralTab {
  renterDetail?: any;
}
const GeneralTab = ({ renterDetail }: IGeneralTab) => {
  return (
    <Grid container component='main' className='modal-lcol modal-lcol--border-none' spacing={3}>
      <Grid item xs={12} md={6} sm={6}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Name
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.firstName} {renterDetail?.lastName}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            DOB
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.DOB ? moment(renterDetail?.DOB).format('MM/DD/YYYY') : 'N/A'}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Height
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.feet || renterDetail?.inches
              ? `${renterDetail?.feet ?? 0}' ${renterDetail?.inches ?? 0}" `
              : 'N/A'}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Weight
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.weight ?? 'N/A'}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Gender
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.gender ?? 'N/A'}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Shoe Size
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.shoeSize ?? 'N/A'}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Skier Type
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.skierType || 'N/A'}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Package
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.packages_name || 'N/A'}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Ski / board length
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.skiLengthPreference || 'N/A'}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            First Day of Use
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.reservationData?.startDate
              ? moment(renterDetail?.reservationData?.startDate).format('MM/DD/YYYY')
              : 'N/A'}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='div' className='modal-row'>
          <Box component='label' className='modal-row__heading'>
            Last Day of Use
          </Box>
          <Box component='div' className='modal-row__desc'>
            {renterDetail?.reservationData?.endDate
              ? moment(renterDetail?.reservationData?.endDate).format('MM/DD/YYYY')
              : 'N/A'}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default GeneralTab;
