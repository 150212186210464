import { Fragment, useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, IconButton } from '@mui/material';

interface ICustomThreeDotsMenu {
  labelId: string;
  children: React.ReactNode;
  className?: string;
  onClick?: any;
  disabled?: boolean;
}

const CustomThreeDotsMenu = ({
  labelId,
  children,
  className,
  onClick,
  disabled = false,
}: ICustomThreeDotsMenu) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const openMenu = () => {
    onClick && onClick();
    setMenuOpen(true);
  };
  const closeMenu = () => setMenuOpen(false);

  return (
    <>
      <IconButton
        aria-label='more'
        id={labelId}
        aria-controls={menuOpen ? labelId : undefined}
        aria-expanded={menuOpen ? 'true' : undefined}
        aria-haspopup='true'
        onClick={openMenu}
        ref={anchorEl}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl.current as Element}
        open={menuOpen}
        onClose={closeMenu}
        className={className}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.12))',
            mt: 1.5,
            width: '8em',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div onClick={closeMenu}> {children}</div>
      </Menu>
    </>
  );
};

export default CustomThreeDotsMenu;
