import React from 'react';
import { Box, Grid } from '@mui/material';

interface IPasswordValidation {
  className?: string;
  isLowerCase?: boolean;
  isUpperCase?: boolean;
  isNumber?: boolean;
  isSymbol?: boolean;
  isMinLength?: boolean;
}
const PasswordValidation = ({
  className = 'prwidget-rgt',
  isLowerCase = false,
  isUpperCase = false,
  isNumber = false,
  isSymbol = false,
  isMinLength = false,
}: IPasswordValidation) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} className={className}>
      <Box component='div' className='prwidget-rgt__heading'>
        Password Requirements:
      </Box>
      <Box component='ul'>
        <Box component='li' className={isLowerCase ? 'tick' : ''}>
          At least one lower case letter [a-z]
        </Box>
        <Box component='li' className={isUpperCase ? 'tick' : ''}>
          At least one upper case letter [A-Z]
        </Box>
        <Box component='li' className={isNumber ? 'tick' : ''}>
          At least one number [0-9]
        </Box>
        <Box component='li' className={isSymbol ? 'tick' : ''}>
          {/* Added *(asterisk) */}
          At least one symbol [!, @, $, &, #, ^,-,*]
        </Box>
        <Box component='li' className={isMinLength ? 'tick' : ''}>
          Minimum 10 characters
        </Box>
      </Box>
    </Grid>
  );
};

export default PasswordValidation;
