import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  TextareaAutosize,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';

import { ModalTitle } from '../../../sidenav/modal/ModalHeader';
import CustomSubmitButton from '../../../common/button/SubmitButton';
import { ICommunicationListItem, ICommunicationTabForm } from '../../../../models/reservation';
import { communicationMethod } from '../../../../enum/enumList';
import {
  communicationCallNoteValidator,
  communicationEmailNoteValidator,
} from './validation/communicationValidation';
import {
  CustomInput,
  CustomNumberInput,
  CustomPhoneInput,
} from '../../../common/customFormControl';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IAddCommunicationNote {
  isModalOpen: boolean;
  handleModalClose: () => void;
  modalType: keyof typeof communicationMethod;
  handleSendMessage: (data: Partial<ICommunicationTabForm>, handleClose: () => void) => void;
  isLoading: boolean;
  viewModalData?: ICommunicationListItem | null | undefined;
  userData?: any;
}

const AddCommunicationNote = ({
  isModalOpen,
  handleModalClose,
  modalType,
  handleSendMessage,
  isLoading = false,
  viewModalData = null,
  userData,
}: IAddCommunicationNote) => {
  const initalformValue: Partial<ICommunicationTabForm> = {
    phone: userData?.phone ?? '',
    numberCode: userData?.numberCode ?? '+1',
    email: userData?.email ?? '',
    subject: '',
    message: '',
  };
  const { control, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: initalformValue,
    resolver:
      modalType === communicationMethod.notes || modalType === communicationMethod.text
        ? yupResolver(communicationCallNoteValidator)
        : yupResolver(communicationEmailNoteValidator),
  });

  useEffect(() => {
    if (viewModalData) {
      setValue('phone', viewModalData?.phone);
      setValue('email', viewModalData?.email);
      setValue('subject', viewModalData?.subject);
      setValue('message', viewModalData?.message);
    }
  }, [viewModalData]);

  const handleClose = () => {
    handleModalClose();
    reset(initalformValue);
  };

  const handleSend = (data: Partial<ICommunicationTabForm>) => {
    const payload =
      modalType === communicationMethod.notes || modalType === communicationMethod.text
        ? {
            numberCode: data.numberCode,
            phone: data.phone,
            message: data.message,
          }
        : {
            numberCode: data.numberCode,
            email: data.email,
            subject: data.subject,
            message: data.message,
          };

    handleSendMessage({ ...payload, method: modalType }, handleClose);
  };

  const modalTitle = `${
    viewModalData ? 'View ' : modalType === communicationMethod.notes ? 'Add ' : 'Send'
  }
    ${
      modalType === communicationMethod.email
        ? 'Custom Email'
        : modalType === communicationMethod.notes
        ? 'Call Notes'
        : modalType === communicationMethod.text
        ? 'Custom Text'
        : ''
    }`;

  const modalAction = viewModalData
    ? ''
    : modalType === communicationMethod.email
    ? 'Send Email'
    : modalType === communicationMethod.notes
    ? 'Add Notes'
    : modalType === communicationMethod.text
    ? 'Send Text'
    : '';
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='add-card-title'
        fullWidth
        maxWidth='xs'
        open={isModalOpen}
        className='modal modal--card'
      >
        <ModalTitle id='add-card-title' className='modal__heading' onClose={handleClose}>
          {modalTitle}
        </ModalTitle>
        <DialogContent dividers>
          <Grid
            container
            className='modal-lcol modal-lcol--border-none'
            component='main'
            spacing={3}
          >
            {modalType === communicationMethod.text || modalType === communicationMethod.notes ? (
              <Grid item xs={12} sm={12} md={12}>
                {!viewModalData ? (
                  <Controller
                    control={control}
                    name='phone'
                    render={({ field, formState: { errors } }) => (
                      <CustomNumberInput
                        mainClassName='label'
                        label='Mobile Number'
                        placeholder='Enter phone'
                        onCountryCodeChange={(value) => setValue('numberCode', value)}
                        onNumberChange={(value) => field.onChange(value)}
                        countryCodeValue={watch('numberCode')}
                        numberValue={field.value}
                        error={errors.phone?.message || errors.numberCode?.message}
                        required
                      />
                    )}
                  />
                ) : (
                  <CustomInput
                    mainClassName='label'
                    label='Mobile Number'
                    placeholder='Enter phone'
                    field={{
                      value: userData?.phone,
                      onChange: () => {},
                    }}
                    disabled
                  />
                )}
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    control={control}
                    name='email'
                    render={({ field, formState: { errors } }) => (
                      <CustomInput
                        mainClassName='label'
                        label='Email'
                        placeholder='Enter email'
                        field={field}
                        error={errors.email}
                        textInputProps={{
                          maxLength: 30,
                        }}
                        disabled={!!viewModalData}
                        required={!viewModalData}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    control={control}
                    name='subject'
                    render={({ field, formState: { errors } }) => (
                      <CustomInput
                        mainClassName='label'
                        label='Subject'
                        placeholder='Enter subject of the email'
                        field={field}
                        error={errors.subject}
                        textInputProps={{
                          maxLength: 30,
                        }}
                        disabled={!!viewModalData}
                        required={!viewModalData}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12} sm={12}>
              <Controller
                control={control}
                name='message'
                render={({ field: { value, onChange }, formState: { errors } }) => (
                  <>
                    <Box component='div' className='modal-col__row'>
                      <Box component='label' className='row__label'>
                        Message {!viewModalData && <Box component='span'>*</Box>}
                      </Box>
                      <TextareaAutosize
                        aria-label='Description'
                        minRows={4}
                        className='textarea'
                        value={value}
                        onChange={onChange}
                        disabled={!!viewModalData}
                      />
                    </Box>
                    {errors?.message?.message && (
                      <Typography color='red'>{errors?.message?.message}</Typography>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className='modal__footer'>
          {!viewModalData && (
            <Box sx={{ position: 'relative' }}>
              <CustomSubmitButton onClick={handleSubmit(handleSend)} isLoading={isLoading}>
                {modalAction}
              </CustomSubmitButton>
            </Box>
          )}
          <Button onClick={handleClose} className='cancel-btn' disabled={isLoading}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AddCommunicationNote;
