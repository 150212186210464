export const equipmentTableConfig = [
  { id: 'barcode1', numeric: false, label: 'Barcode', isSortAvailable: false },
  { id: 'model', numeric: false, label: 'Model', isSortAvailable: false },
  { id: 'size', numeric: false, label: 'Size', isSortAvailable: false },
  { id: 'manufacture', numeric: false, label: 'Manufacture', isSortAvailable: false },
  { id: 'startDate', numeric: false, label: 'Start Date', isSortAvailable: false },
  { id: 'endDate', numeric: false, label: 'End Date', isSortAvailable: false },
  { id: 'assignedAt', numeric: false, label: 'Assigned At', isSortAvailable: false },
  { id: 'assignedBy', numeric: false, label: 'Assigned By', isSortAvailable: false },
  { id: 'status', numeric: false, label: 'Return Status', isSortAvailable: false },
  { id: 'returnAt', numeric: false, label: 'Returned At', isSortAvailable: false },
];

export const addonTableConfig = [
  { id: 'addonName', numeric: false, label: 'Addon', isSortAvailable: false },
  { id: 'startDate', numeric: false, label: 'Start Date', isSortAvailable: false },
  { id: 'endDate', numeric: false, label: 'End Date', isSortAvailable: false },
  { id: 'status', numeric: false, label: 'Return Status', isSortAvailable: false },
  { id: 'returnAt', numeric: false, label: 'Return At', isSortAvailable: false },
];
