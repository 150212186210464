import { Link } from 'react-router-dom';
import { Box, TableCell, TableRow } from '@mui/material';
import { TextSnippetOutlined as TextSnippetOutlinedIcon } from '@mui/icons-material';

import { IReturnRenter } from '../../models/returns';
import { formatDate } from '../../utils/formatDate';
import { formatReservationId } from '../../utils/formatReservationId';
import moment from 'moment';
import { equipmentReturn } from '../../enum/enumList';

interface IReturnListItem {
  reservationRow: IReturnRenter;
  openDetailsModal: (id: number) => void;
}

const ReturnListItem = ({ reservationRow, openDetailsModal }: IReturnListItem) => {
  return (
    <TableRow
      hover
      tabIndex={-1}
      key={reservationRow?.id}
      className={
        !(reservationRow?.isReturned === equipmentReturn.yes) &&
        moment(reservationRow?.endDate).isBefore(moment().startOf('day'))
          ? 'item-retured-no'
          : ''
      }
    >
      <TableCell scope='row'>
        <Link to={`/customer-detail/${reservationRow?.userId}`}>{reservationRow?.fullName}</Link>
      </TableCell>
      <TableCell scope='row'>
        {formatReservationId(reservationRow?.id, reservationRow?.storeId)}
      </TableCell>
      <TableCell>{formatDate({ date: reservationRow?.endDate })}</TableCell>
      <TableCell>{reservationRow?.renters}</TableCell>
      <TableCell>
        <Box
          component='span'
          className={`${
            reservationRow?.isReturned === equipmentReturn.no
              ? 'badge badge--red'
              : reservationRow?.isReturned === equipmentReturn.partially
              ? 'badge badge--blue'
              : 'badge badge--green'
          } text-capitalize`}
        >
          {reservationRow?.isReturned}
        </Box>
      </TableCell>
      <TableCell align='right'>
        <Box
          component='span'
          className='link-btn'
          onClick={() => openDetailsModal(reservationRow?.id)}
        >
          <TextSnippetOutlinedIcon /> details
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ReturnListItem;
