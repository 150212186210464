import moment from 'moment';
import * as Yup from 'yup';

import { EmailRegex, Messages } from '../../../enum/enumList';

export const AddReservationValidation = Yup.object().shape(
  {
    reservationType: Yup.string().typeError(Messages.required).required(Messages.required),
    reservationStartDate: Yup.string()
      .typeError(Messages.required)
      .required(Messages.required)
      .test('is valid date format', Messages.invalidDateFormat, (val) => {
        if (!val) return true;
        return moment(val).isValid();
      }),
    reservationEndDate: Yup.string()
      .typeError(Messages.required)
      .test('isBefore', Messages.dateBeforeErr, (val, ctx) => {
        const reservationStartDate = ctx.parent.reservationStartDate;
        if (moment(val).isBefore(reservationStartDate)) {
          return false;
        }
        return true;
      })
      .required(Messages.required)
      .test('is valid date format', Messages.invalidDateFormat, (val) => {
        if (!val) return true;
        return moment(val).isValid();
      }),
    pickUpDateBefore: Yup.boolean().typeError(Messages.required),
  },
  [['reservationStartDate', 'reservationEndDate']],
);

export const AddReservationContactInfoValidation = Yup.object().shape({
  firstName: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  lastName: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  email: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .trim()
    .matches(EmailRegex, Messages.emailErr)
    .typeError(Messages.required)
    .required(Messages.required),
  numberCode: Yup.string().typeError(Messages.required).required(Messages.required),
  phone: Yup.string()
    .typeError(Messages.required)
    .required(Messages.required)
    .min(10, Messages.phoneNumberErr),
  address: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  address2: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .notRequired(),
  country: Yup.string().typeError(Messages.required).required(Messages.required),
  state: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  city: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 100;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  zip: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 15;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
});

export const EditRenterInfoValidation = Yup.object().shape({
  firstName: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  lastName: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  email: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .trim()
    .matches(EmailRegex, Messages.emailErr)
    .typeError(Messages.required)
    .required(Messages.required),
  numberCode: Yup.string().typeError(Messages.required).required(Messages.required),
  phone: Yup.string()
    .max(20, Messages.phoneNumberErr)
    .typeError(Messages.required)
    .required(Messages.required),
  address: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  address2: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .notRequired(),
  country: Yup.string().typeError(Messages.required).required(Messages.required),
  state: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  city: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 100;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  zip: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 15;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),

  reservationStartDate: Yup.string()
    .typeError(Messages.required)
    .required(Messages.required)
    .test('is valid date format', Messages.invalidDateFormat, (val) => {
      if (!val) return true;
      return moment(val).isValid();
    }),
  reservationEndDate: Yup.string()
    .typeError(Messages.required)
    .required(Messages.required)
    .test('is valid date format', Messages.invalidDateFormat, (val) => {
      if (!val) return true;
      return moment(val).isValid();
    }),
  pickUpDateBefore: Yup.boolean().typeError(Messages.required),
  sendReminderText: Yup.boolean().typeError(Messages.required),
});
