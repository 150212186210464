import { Box, MenuItem, TableCell, TableRow } from '@mui/material';
import {
  Edit as EditIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  CancelOutlined as CancelOutlinedIcon,
} from '@mui/icons-material';
import { IEmployeeForm } from '../../models/employee';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { IStores } from '../../models/common';
import { capitalizeFirstLetter, renderStoreName } from '../../utils/utilities';
import { CustomPhoneInput } from '../common/customFormControl';
import { formatDate } from '../../utils/formatDate';
import { rolesEnum } from '../../enum/enumList';
import { IUserProfile } from '../../models/user';

const EmployeeListItem = ({
  reservationRow: employeeRowData,
  editEmployee,
  changeStatus,
  stores,
  userRole,
  user,
}: {
  reservationRow: IEmployeeForm;
  editEmployee: (id: string, employeeData: IEmployeeForm) => void;
  changeStatus: (id: string, status: string, role: string) => void;
  stores: IStores[];
  userRole: string;
  user: IUserProfile;
}) => {
  const {
    id,
    fullName,
    email,
    phone,
    defaultStore,
    status,
    createdAt,
    firstName,
    lastName,
    numberCode,
    roleName,
  } = employeeRowData;
  const { profile = {} } = user || {};
  return (
    <TableRow hover tabIndex={-1} key={employeeRowData.phone}>
      <TableCell>
        {
          <>
            {fullName}
            {(roleName === rolesEnum.admin || roleName === rolesEnum.superadmin) && (
              <Box component='span' className='badge-seasonal'>
                {capitalizeFirstLetter(roleName)}
              </Box>
            )}
          </>
        }
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        {phone && (
          <CustomPhoneInput
            field={{
              value: `${numberCode || +1}${phone}`,
              onChange: () => {},
            }}
            disabled
            className='phone-input-display'
          />
        )}
      </TableCell>
      <TableCell>{renderStoreName(stores, defaultStore)}</TableCell>
      <TableCell>
        <Box
          component='span'
          className={status === '0' ? 'badge badge--red' : 'badge badge--green'}
        >
          {status === '1' ? 'Active' : 'Inactive'}
        </Box>
      </TableCell>
      <TableCell>{createdAt && formatDate({ date: parseInt(createdAt) })}</TableCell>
      <TableCell align='right'>
        <CustomThreeDotsMenu
          labelId={employeeRowData?.fullName as string}
          // Added Changes as per Ticket: 72488
          disabled={
            (userRole == rolesEnum.superadmin
              ? employeeRowData?.id == profile?.id
              : roleName === rolesEnum.admin) || roleName === rolesEnum.superadmin
          }
        >
          <MenuItem
            onClick={() =>
              editEmployee(employeeRowData.id, {
                id,
                firstName,
                lastName,
                email,
                phone,
                defaultStore,
                status,
                numberCode,
                roleName,
                adminPrivileges: employeeRowData.roleName === rolesEnum.admin, // Added Changes as per Ticket: 72488
              })
            }
          >
            <EditIcon />
            Edit {roleName}
          </MenuItem>
          <MenuItem onClick={() => changeStatus(id as string, status, roleName as string)}>
            {employeeRowData.status === '1' ? (
              <>
                <CancelOutlinedIcon />
                Inactivate {roleName}
              </>
            ) : (
              <>
                <CheckCircleOutlinedIcon /> Activate {roleName}
              </>
            )}
          </MenuItem>
        </CustomThreeDotsMenu>
      </TableCell>
    </TableRow>
  );
};

export default EmployeeListItem;
