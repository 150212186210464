import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { styled } from '@mui/material/styles';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';

import { applyDiscountOption, Severity } from '../../../../enum/enumList';
import { ModalTitle } from '../../../sidenav/modal/ModalHeader';
import { applyDiscountValidation } from './validation/applyDiscountValidation';
import { APPLY_ADMIN_DISCOUNT } from '../../../../graphql/api/reservation';
import { PaymentTabContext } from '../../../../pages/reservation/reservationDetail/paymentTab/PaymentTab';
import CustomSubmitButton from '../../../common/button/SubmitButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IApplyDiscountModal {
  modalDiscountOpen: boolean;
  setModalDiscountOpen: Dispatch<SetStateAction<boolean>>;
}

interface IApplyDiscountForm {
  discount: {
    type: string;
    value: number;
  };
}

const ApplyDiscountModal = ({ modalDiscountOpen, setModalDiscountOpen }: IApplyDiscountModal) => {
  const { reservationId, updatePaymentTabData, snackbarShowMessage, pricing, refetch } =
    useContext(PaymentTabContext);

  const defaultValues = {
    discount: {
      type: pricing?.adminDiscount
        ? pricing?.isFixedDiscount
          ? applyDiscountOption.FixedDiscount
          : applyDiscountOption.PercentageDiscount
        : applyDiscountOption.NoDiscount,
      value: pricing?.adminPercentageDiscount || 0,
    },
  };
  const { control, handleSubmit, reset } = useForm<IApplyDiscountForm>({
    resolver: yupResolver(applyDiscountValidation(pricing?.subTotal || null)),
    defaultValues,
  });

  // apply discount api call
  const [applyAdminDiscount, { loading: applyDiscountLoading }] = useMutation(APPLY_ADMIN_DISCOUNT);

  useEffect(() => {
    if (modalDiscountOpen) reset(defaultValues);
  }, [pricing, modalDiscountOpen]);

  const handleClose = () => {
    setModalDiscountOpen(false);
  };

  const handleApply = (data: IApplyDiscountForm) => {
    applyAdminDiscount({
      variables: {
        reservationId,
        isFixedDiscount: data.discount.type !== applyDiscountOption.PercentageDiscount,
        discount: data.discount.value,
      },
      onCompleted: (data) => {
        updatePaymentTabData(data?.applyAdminDiscount?.data || {});
        handleClose();
        setTimeout(() => {
          if (snackbarShowMessage)
            snackbarShowMessage('Discount applied successfully', Severity.Success);
        }, 500);
        refetch();
      },
      onError: (error) => {
        if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
      },
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='add-card-title'
        fullWidth
        maxWidth='sm'
        open={modalDiscountOpen}
        className='modal modal--card'
      >
        <ModalTitle id='add-card-title' className='modal__heading' onClose={handleClose}>
          Apply Admin Discount
        </ModalTitle>
        <DialogContent dividers>
          <Box ml={2} mb={2} component='div'>
            Maximum Discount: <b>${pricing?.maxDiscount}</b>
          </Box>
          <Controller
            name='discount'
            control={control}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <>
                <RadioGroup
                  row
                  aria-labelledby='radio-for-packages-label'
                  name='radio-for-discount'
                >
                  <Grid
                    container
                    className='modal-lcol modal-lcol--border-none'
                    component='main'
                    spacing={3}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <Box component='div' className='modal-col__row'>
                        <FormControlLabel
                          name='radio-for-discount'
                          checked={value.type === applyDiscountOption.NoDiscount}
                          value={applyDiscountOption.NoDiscount}
                          onChange={() => {
                            onChange({ type: applyDiscountOption.NoDiscount, value: 0 });
                          }}
                          control={<Radio />}
                          label='No Discount'
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className='pt-10'>
                      <Box component='div' className='modal-col__row'>
                        <FormControlLabel
                          name='radio-for-discount'
                          checked={value.type === applyDiscountOption.FixedDiscount}
                          value={applyDiscountOption.FixedDiscount}
                          onChange={() => {
                            onChange({ type: applyDiscountOption.FixedDiscount, value: 0 });
                          }}
                          control={<Radio />}
                          label='Amount($) Off'
                        />
                        <TextField
                          fullWidth
                          id='title'
                          name='title'
                          placeholder='Enter Amount($)'
                          type='number'
                          value={
                            value.type !== applyDiscountOption.FixedDiscount ? '' : value.value
                          }
                          onChange={(e) => {
                            onChange({
                              type: applyDiscountOption.FixedDiscount,
                              value: e.target.value,
                            });
                          }}
                          disabled={value.type !== applyDiscountOption.FixedDiscount}
                        />
                      </Box>
                      {value.type === applyDiscountOption.FixedDiscount &&
                        errors.discount &&
                        (errors.discount as any).value &&
                        (errors.discount as any).value.message && (
                          <Typography color='red'>
                            {(errors.discount as any).value.message}
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className='pt-10'>
                      <Box component='div' className='modal-col__row'>
                        <FormControlLabel
                          name='radio-for-discount'
                          checked={value.type === applyDiscountOption.PercentageDiscount}
                          value={applyDiscountOption.PercentageDiscount}
                          onChange={() => {
                            onChange({ type: applyDiscountOption.PercentageDiscount, value: 0 });
                          }}
                          control={<Radio />}
                          label='Percentage(%) Off'
                        />
                        <TextField
                          fullWidth
                          id='title'
                          name='title'
                          placeholder='Enter Percentage(%)'
                          type='number'
                          value={
                            value.type !== applyDiscountOption.PercentageDiscount ? '' : value.value
                          }
                          onChange={(e) => {
                            onChange({
                              type: applyDiscountOption.PercentageDiscount,
                              value: e.target.value,
                            });
                          }}
                          disabled={value.type !== applyDiscountOption.PercentageDiscount}
                        />
                      </Box>
                      {value.type === applyDiscountOption.PercentageDiscount &&
                        errors.discount &&
                        (errors.discount as any).value &&
                        (errors.discount as any).value.message && (
                          <Typography color='red'>
                            {(errors.discount as any).value.message}
                          </Typography>
                        )}
                    </Grid>
                    {value.type !== applyDiscountOption.PercentageDiscount &&
                      value.type !== applyDiscountOption.FixedDiscount &&
                      errors.discount &&
                      (errors.discount as any).value &&
                      (errors.discount as any).value.message && (
                        <Typography color='red'>
                          {(errors.discount as any).value.message}
                        </Typography>
                      )}
                  </Grid>
                </RadioGroup>
              </>
            )}
          />
        </DialogContent>
        <DialogActions className='modal__footer'>
          <Box sx={{ position: 'relative' }}>
            <CustomSubmitButton
              isLoading={applyDiscountLoading}
              onClick={handleSubmit(handleApply)}
            >
              Apply
            </CustomSubmitButton>
          </Box>
          <Button onClick={handleClose} className='cancel-btn' disabled={applyDiscountLoading}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ApplyDiscountModal;
