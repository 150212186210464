import React from 'react';

function Loader() {
  return (
    <div className='loader'>
      <div className='loader__dots'>
        <div className='dot dot1'></div>
        <div className='dot dot2'></div>
        <div className='dot dot3'></div>
      </div>
      {/* <span className="loader__text">Loading records...</span> */}
    </div>
  );
}

export default Loader;
