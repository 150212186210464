import * as Yup from 'yup';
import { Messages } from '../../../enum/enumList';
export const ChangePasswordFormSchema = Yup.object().shape({
  oldPassword: Yup.string().required(Messages.required),
  password: Yup.string().required(Messages.required),

  confirmPassword: Yup.string()
    .required(Messages.required)
    .oneOf([Yup.ref('password')], Messages.matchErr),
});
