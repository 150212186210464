import moment from 'moment';
import * as Yup from 'yup';

import { Messages, OnlyWholeNumbersRegex, DateFormatRegex } from '../../../../enum/enumList';

export const renterDetailsValidator = Yup.object().shape({
  firstName: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  lastName: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 255;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  dob: Yup.string()
    .test('is valid date format', Messages.invalidDOBFormat, (val) => {
      if (!val) return true;
      return moment(val).isValid();
    })
    .test('is valid date format', Messages.invalidDateFormat, (val) => moment(val).isBefore())
    .typeError(Messages.required)
    .required(Messages.required),
  gender: Yup.string().typeError(Messages.required).required(Messages.required),
  feet: Yup.mixed().when('$isSummerSeason', {
    is: false,
    then: Yup.string().typeError(Messages.required).required(Messages.required),
    otherwise: undefined,
  }),
  inches: Yup.mixed().when('$isSummerSeason', {
    is: false,
    then: Yup.string().typeError(Messages.required).required(Messages.required),
    otherwise: undefined,
  }),
  weight: Yup.mixed().when('$isSummerSeason', {
    is: false,
    then: Yup.string()
      .required(Messages.required)
      .matches(OnlyWholeNumbersRegex, Messages.nonDecimalPositiveNumber)
      .test(
        'is less than 400',
        Messages.weigthValidation,
        (val) => !!(val && parseInt(val) <= 400 && parseInt(val) > 0),
      )
      .typeError(Messages.required),
    otherwise: undefined,
  }),
  shoeSize: Yup.mixed().when('$isSummerSeason', {
    is: false,
    then: Yup.string()
      .required(Messages.required)
      .test('len', Messages.fieldTooLong, (val) => {
        if (val) return val?.length <= 10;
        return true;
      })
      .typeError(Messages.required),
    otherwise: undefined,
  }),
});

export const renterCategoriesValidator = Yup.object().shape({
  selectedCatergory: Yup.string()
    .oneOf(['1', '2', '3', '4', '5', '6'])
    .required(Messages.pakagesRequired),
  skierType: Yup.string().when('selectedCatergory', {
    is: '1',
    then: Yup.string().required(Messages.required),
    otherwise: Yup.string().notRequired(),
  }),
  skiLength: Yup.boolean()
    .when('selectedCatergory', {
      is: '1',
      then: Yup.boolean().required(Messages.required),
      otherwise: Yup.boolean().notRequired(),
    })
    .when('selectedCatergory', {
      is: '2',
      then: Yup.boolean().required(Messages.required),
      otherwise: Yup.boolean().notRequired(),
    }),
  skiLengthPreference: Yup.string()
    .when(['selectedCatergory', 'skiLength'], {
      is: (selectedCatergory: string, skiLength: boolean) => selectedCatergory === '1' && skiLength,
      then: Yup.string().required(Messages.required),
      otherwise: Yup.string().notRequired(),
    })
    .when(['selectedCatergory', 'skiLength'], {
      is: (selectedCatergory: string, skiLength: boolean) => selectedCatergory === '2' && skiLength,
      then: Yup.string().required(Messages.required),
      otherwise: Yup.string().notRequired(),
    }),
});

export const addonsValidator = Yup.object().shape({
  addons1: Yup.boolean(),
  addons2: Yup.boolean(),
  addons3: Yup.boolean(),
  addons4: Yup.boolean(),
});
