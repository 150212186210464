// Enums are a feature added to JavaScript in TypeScript
// which makes it easier to handle named sets of constants.

// By default an enum is number based, starting at zero,
// and each option is assigned an increment by one. This is
// useful when the value is not important.

export enum Messages {
  minTen = 'Password must have at least 10 characters,',
  maxForty = 'Password must not exceed 40 characters',
  regexMsg = 'Password must contain at least 10 characters, one uppercase, one number and one special case character.',
  matchErr = 'The passwords do not match.',
  required = 'This is a required field',
  pakagesRequired = 'Please select one package',
  emailErr = 'Please enter valid email',
  minLength = 'The confirm password must be of 10 characters',
  successPasswordChange = 'Password has been changed successfully.',
  pinErrMsg = 'Enter 4 digit to create unlock pin.',
  onlyNumbers = 'Only numbers are allowed.',
  phoneNumberErr = 'Please add valid phone number',
  deletePackage = 'Do you really want to delete package name package? This process cannot be undone.',
  deleteUserImage = 'Do you really want to delete profile image? This process cannot be undone.',
  downloadSuccess = 'Downloaded Successfully',
  positiveNumber = 'Enter a positive number',
  nonDecimalPositiveNumber = 'Enter a non decimal positive number',
  errLog = 'Error in fetching logs',
  dateBeforeErr = 'End date cannot be before start date',
  fieldTooLong = 'Field value is too long',
  fieldTooLong500 = 'Max 500 characters allowed',
  notAvailable = 'N/A',
  cardRequired = 'Select a card to continue',
  transactionRequired = 'Select a transaction to continue',
  priceLenth = 'Price must be less than $10000',
  negaivePriceLength = 'Price must be greater than -$10000',
  maxPercentageDiscount = 'Discount should not contain decimal places and should be less than 100%',
  existingDiscount = 'You are trying to apply discount on existing discounted amount, please remove existing discount first.',
  maxFixedDiscount = 'Discount must be less than sub total',
  maxChargePayment = 'Charge amount must be less than payment due',
  maxRefundAmount = 'Refund amount must be less than total amount paid',
  maxTransRefundAmount = 'Refund amount must be less than selected transaction amount',
  uptoTwoDecimalPlaces = 'Amount must be upto two decimal places',
  lastCardDelete = 'Deletion of remaining one credit card is not allowed. At least one card must remain on your account for transaction purposes',
  weigthValidation = 'Weight should be between 1lbs - 400lbs',
  noAddonsfound = 'No addon found in selected package',
  assignEquipmentError = 'Waiver is not signed yet, cannot assign equipment.',
  cmsBannerPromotionLimit = 'Max 50 characters allowed',
  cmdsecondaryBannerLimit = 'Max 150 characters allowed',
  invalidDateFormat = 'Invalid date',
  invalidDOBFormat = 'Invalid Format, DOB format is "MM/DD/YYYY"',
  amountMsg = 'Amount is invalid',
  equipmentUpdationMessage = 'Equipment dates and status updated successfully',
  selectStore = 'Select at least one store',
}
export const validFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
export enum Constants {
  fileSize = 2097152,
  rowsPerPage = 10,
}
export const RowsPerPageOption = [10, 25, 50, 75, 100];
export const storeIds = [1, 2, 3];

export const BlockDates = { blockedStartDate: null, blockedEndDate: null };

export const SettingDates = {
  SeasonalDates: 'seasonalBlockedDates',
  DailyDates: 'dailyBlockedDates',
};
export const EmailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/;
export const PhoneNumberRegex = /^[0-9]{10}$/;
export const OnlyNumbersRegex = /^[0-9]*$/;
export const OnlyWholeNumbersRegex = /^[0-9]*$/;
export const UptoTwoDecimalPlacesRegex = /^\d+(?:\.\d{1,2})?$/;
export const AlphaCharacterRegex = /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i;

export const MainBannerLimit = 50;
export const MainSubBannerLimit = 50;

export const SecondaryBannerLimit = 50;
export const SecondarySubBannerLimit = 150;

export const PromotionLimit = 50;
export const PromoDescription = 150;

export enum Regex {
  password = '/^.*(?=.{10,})((?=.*[!@#$%^&*()-_=+{};:,<.>]){1})(?=.*d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/',
}
export enum Continent {
  North_America,
  South_America,
  Africa,
  Asia,
  Europe,
  Antartica,
  Australia,
}
export enum Color {
  Green = 'Green',
  LightGreen = 'Light Green',
  White = 'White',
}

export enum Task {
  Name = 'Todo List',
  Assigned = 0,
  InProgress,
  Finished,
}

export enum PackageEnum {
  D = 'Daily',
  S = 'Seasonal',
}

export enum ReservationTypeEnum {
  daily = 'D',
  seasonal = 'S',
  all = '',
}

export enum InventoryType {
  Skis = '276a54bc-64ce-11ed-9b06-34735ae7b561',
  SkiBoots = '276a73b8-64ce-11ed-9b06-34735ae7b561',
  Snowboards = '276a83e3-64ce-11ed-9b06-34735ae7b561',
  SnowboardsBoots = '276a92f7-64ce-11ed-9b06-34735ae7b561',
  SnowShoes = '276aa00a-64ce-11ed-9b06-34735ae7b561',
}

export enum Severity {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export enum NetworkStatus {
  ErrorMessage = 'Response not successful: Received status code 400',
  FallbackMessage = 'Something went wrong. Please try again later.',
}

export enum DateFormat {
  MDY = 'MM/DD/YYYY',
  DMY = 'DD/MM/YYYY',
  YMD = 'YYYY/MM/DD',
}

export enum RenterHeightFeet {
  '0 feet' = '0',
  '1 feet' = '1',
  '2 feet' = '2',
  '3 feet' = '3',
  '4 feet' = '4',
  '5 feet' = '5',
  '6 feet' = '6',
  '7 feet' = '7',
  '8 feet' = '8',
}
export enum RentersHeightInches {
  '0 inches' = '0',
  '1 inches' = '1',
  '2 inches' = '2',
  '3 inches' = '3',
  '4 inches' = '4',
  '5 inches' = '5',
  '6 inches' = '6',
  '7 inches' = '7',
  '8 inches' = '8',
  '9 inches' = '9',
  '10 inches' = '10',
  '11 inches' = '11',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  PreferNotToAnswer = 'Prefer not to answer',
}
export enum SkierType {
  Expert = 'expert',
  Intermediate = 'intermediate',
}

export enum Height {
  '8 feet' = '8',
  '7 feet' = '7',
  '6 feet' = '6',
}
export enum ShoeSize {
  '8 ' = '8',
  '7 ' = '7',
  '6 ' = '6',
}

export interface ICategories {
  id: number;
  name: string;
  image: null;
  bucketName: null;
  createdAt: string;
  updatedAt: string;
}

export enum scriptLoadStatus {
  Idle = 'idle',
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

export enum Months {
  January = '01',
  February = '02',
  March = '03',
  April = '04',
  May = '05',
  June = '06',
  July = '07',
  August = '08',
  September = '09',
  October = '10',
  November = '11',
  December = '12',
}

// reservation enums
export enum equipmentAssignmentStatus {
  Yes = 'yes',
  No = 'no',
  Partially = 'partially',
}

// enum for reservation tabs daily and seasonal
export enum reservationTypeEnum {
  daily = 'D',
  seasonal = 'S',
}

export enum reservationFilter {
  Active = 'Active',
  All = 'All',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Incomplete = 'Incomplete',
  Today = 'Today',
}

export enum applyDiscountOption {
  NoDiscount = 'noDiscount',
  FixedDiscount = 'fixedDiscount',
  PercentageDiscount = 'percentageDiscount',
}

export enum paymentMode {
  card = 'card',
  pos = 'pos',
  refund = 'refund',
  void = 'void',
}

export enum paymentType {
  charge = 'charge',
  refund = 'refund',
  deposit = 'deposit',
}

export enum reservationStatusText {
  completed = 'Completed',
  pending = 'Pending',
  cancelled = 'Cancelled',
}

export enum communicationMethod {
  email = 'email',
  text = 'text',
  notes = 'notes',
}

export enum StoreNames {
  'AF',
  'SF',
  'MT',
}
export enum inventoryTypeEnum {
  skis = 'Skis',
  skiBoots = 'SkiBoots',
  snowboards = 'Snowboards',
  snowboardsBoots = 'SnowboardsBoots',
  snowShoes = 'SnowShoes',
}

export enum bindingTypeEnum {
  Alpine = 'Alpine',
  GripWalk = 'Grip Walk',
  Touring = 'Touring',
}

export enum addonStatus {
  pending = 'pending',
  assigned = 'assigned',
  unassigned = 'unassigned',
}

export enum roles {
  guest = 1,
  user = 2,
  admin = 4,
  employee = 3,
}

export enum equipmentReturn {
  no = 'no',
  yes = 'yes',
  partially = 'partially',
}

export enum reservationStatus {
  completed = 'completed',
  pending = 'pending',
  cancelled = 'cancelled',
  processing = 'processing',
}

// Added this enum for paid column in reservation listings
export enum paidStatus {
  yes = 'Yes',
  no = 'No',
  partial = 'Partially',
}

export enum createdFrom {
  admin = 'admin',
  customer = 'customer',
  guest = 'guest',
  kiosk = 'kiosk',
}

export enum inventoryLogsType {
  M = 'Maintenance',
  V = 'Verification',
}

export enum inventoryReportFilter {
  verified = 'Verified',
  unVerified = 'Unverified',
}

export enum stores {
  'Angel Fire',
  'Santa Fe',
  'Monarch (Shavano)',
}

export enum preferableAudience {
  admin = 'Admins',
  customer = 'Customers',
  guest = 'Guests',
  employee = 'Employees',
}

export enum Seasons {
  summer = 'S',
  winter = 'W',
}

export enum SeasonLabel {
  summer = 'summer',
  winter = 'winter',
}

export enum audienceFilter {
  employee = 1,
  customer = 5,
}

export enum techTypeEnum {
  equipment = 'Equipment',
  addon = 'Addon',
}

export enum rolesEnum {
  superadmin = 'superadmin',
  guest = 'guest',
  user = 'user',
  admin = 'admin',
  employee = 'employee',
  system = 'system',
}

export const DateFormatRegex =
  /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;

export const reservationDefaultCountry = 'US';
export const columnSortingOrder = 'DESC';

// this includes the id of Addons ("I have my own boots", "I have my own jr boots")
// of both types 'Daily' & 'Seasonal' and all stores
export const snowboardBootAddonIds = [
  // "I have my own boots", "Angel Fire"
  '009315f5-e0a1-48f6-8fd6-05fced289f8b', // Daily
  'b0d085d1-10a0-4e39-9a5d-bc5fcafa9726', // Seasonal

  // "I have my own jr boots", "Angel Fire"
  'd69e97fa-1ae7-40fc-8571-3ca7e2ef4308', // Daily
  'f329ea35-4277-42d1-a1cd-fb7f2a73c410', // Seasonal

  // "I have my own boots", "Santa Fe"
  'ac7a6677-a20a-4b27-bf36-23f0fb400fe8', // Daily
  'a2a21498-ad20-4c05-801e-31ca54aad98c', // Seasonal

  // "I have my own jr boots", "Santa Fe"
  'df61a801-d162-4cbc-88d7-bda74ec20215', // Daily
  '98f1001f-5231-4173-a43c-740a5d321641', // Seasonal

  // "I have my own boots", "MT Shavano"
  '3e8a786e-9c5c-4efb-b2cd-c27d15d4add8', // Daily
  '7ad4bcad-98a1-4313-ab5d-31a9b81667aa', // Seasonal

  // "I have my own jr boots", "MT Shavano"
  '5295c345-e827-438a-9966-f81107aad96f', // Daily
  'a6a83c09-4ea2-46c3-9623-421d01f50f19', // Seasonal
];
