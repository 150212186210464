import React from 'react';
import { Box, Grid, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface IReservationCards {
  cardTitle: string;
  cardIcon: React.ReactNode;
  reservationNumber: number;
  colorClass: string;
  isFooter?: boolean;
}

const ReservationCards = ({
  cardIcon,
  cardTitle,
  reservationNumber = 0,
  colorClass,
  isFooter = false,
}: IReservationCards) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box className={`card ${colorClass}`}>
        <Box className='card__header'>
          {cardIcon} {cardTitle}
        </Box>
        <Box className='card__body'>{reservationNumber}</Box>
        {isFooter && (
          <Box className='card__footer'>
            <Box className='footer__l-col'>
              Groups: <Box component='span'>1</Box>
            </Box>
            <Box className='footer__r-col'>
              Individuals: <Box component='span'>1</Box>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default ReservationCards;
