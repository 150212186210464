import { Menu } from '@mui/material';

interface ICustomMenu {
  children: React.ReactNode;
  anchorEl: null | HTMLElement;
  menuOpen: boolean;
  className?: string;
  handleClose: () => void;
}

const CustomMenu = ({ children, anchorEl, menuOpen, handleClose, className = '' }: ICustomMenu) => {
  return (
    <Menu
      id='long-menu'
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleClose}
      className={className}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.12))',
          mt: 1.5,
          width: '8em',
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </Menu>
  );
};

export default CustomMenu;
