import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableContainer,
  AlertColor,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Loader from '../Loader';
import NoRecordFound from '../noRecordFound/NoRecordFound';
import { Constants, RowsPerPageOption } from '../../../enum/enumList';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
}

interface IHeadeCells {
  [key: string]: any;
}

interface ICustomTableContainer {
  className?: string;
  tableClassName?: string;
  rows?: any[];
  SingleRowComponent: (row?: any) => JSX.Element;
  headCells: IHeadeCells[];
  isLoading?: boolean;
  isPagination?: boolean;
  totalRecords?: number;
  callBack?: any;
  tableItemProps?: any;
  rowsChangeCallback?: (pageNumber: number, rowPerPage: number) => void;
  deleteConfirmation?: (param: string | number) => void;
  isActionRow?: boolean;
  APISortable?: boolean;
  defaultSortableColumn?: string;
  noRecordText?: string;
  severity?: AlertColor;
  resetPageDependency?: string | null;
  defaultSortingDirection?: Order;
}

const CustomTableContainer = ({
  className = '',
  tableClassName = 'table-wrap__table table-wrap__table--reservation',
  rows = [],
  headCells = [],
  SingleRowComponent,
  isLoading = false,
  isPagination = true,
  totalRecords = 0,
  tableItemProps = {},
  callBack,
  deleteConfirmation,
  isActionRow = false,
  APISortable = false,
  defaultSortableColumn = '',
  noRecordText = 'No Record Found',
  severity = 'info',
  resetPageDependency = null,
  defaultSortingDirection = 'desc',
}: ICustomTableContainer) => {
  const [order, setOrder] = useState<Order>(defaultSortingDirection);
  const [orderBy, setOrderBy] = useState<string>(defaultSortableColumn);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Constants.rowsPerPage);
  const { handleRowsPageState } = tableItemProps;
  // useEffect(() => {
  //   setOrderBy(defaultSortableColumn);
  // }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell?.isSortAvailable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
          {isActionRow && <TableCell align='right'></TableCell>}
        </TableRow>
      </TableHead>
    );
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (APISortable) callBack(page, rowsPerPage, isAsc ? 'DESC' : 'ASC', property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (callBack) callBack(newPage, rowsPerPage, order === 'asc' ? 'ASC' : 'DESC', orderBy);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    callBack(0, parseInt(event.target.value, 10), order === 'asc' ? 'ASC' : 'DESC', orderBy);
    if (handleRowsPageState) {
      handleRowsPageState(parseInt(event.target.value, 10));
    }
    // setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, resetPageDependency]);

  const updatedRows = APISortable ? rows : stableSort(rows as any, getComparator(order, orderBy));

  return (
    <TableContainer className={className}>
      <Table
        aria-labelledby='Reservation Listing'
        // className='table-wrap__table table-wrap__table--reservation'
        className={tableClassName}
      >
        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        {isLoading ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={12}>
                <Loader />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : updatedRows?.length ? (
          <TableBody>
            {updatedRows.map((row, index) => (
              <SingleRowComponent
                key={row?.id}
                reservationRow={row}
                index={index}
                deleteConfirmation={deleteConfirmation}
                {...tableItemProps}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={12}>
                <NoRecordFound text={noRecordText} severity={severity} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      {isPagination && (
        <TablePagination
          rowsPerPageOptions={RowsPerPageOption}
          component='div'
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={totalRecords <= rowsPerPage ? 0 : Math.min(totalRecords, page)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default CustomTableContainer;
