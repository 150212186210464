import { Box, MenuItem, TableCell, TableRow } from '@mui/material';
import {
  CheckCircleOutlined as CheckCircleOutlineOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  CancelOutlined as CancelOutlinedIcon,
} from '@mui/icons-material';
import { CustomerData } from '../../models/customer';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { CustomPhoneInput } from '../common/customFormControl';
import { formatDate } from '../../utils/formatDate';
import { roles } from '../../enum/enumList';
import { useNavigate } from 'react-router-dom';

const CustomerListItem = ({
  reservationRow,
  changeStatus,
  tabValue,
}: {
  reservationRow: CustomerData;
  changeStatus: (id: string, status: string) => void;
  tabValue: number;
}) => {
  const {
    firstName,
    lastName,
    email,
    phone = 0,
    status,
    fullName,
    createdAt,
    numberCode,
    accountVerified,
  } = reservationRow;

  const navigate = useNavigate();

  const navigateToDetail = (id?: string) => {
    navigate(`/customer-detail/${id}`);
  };

  return (
    <TableRow hover tabIndex={-1} key={reservationRow.phone}>
      <TableCell>{firstName + ' ' + lastName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        {phone && (
          <CustomPhoneInput
            field={{
              value: `${numberCode || +1}${phone}`,
              onChange: () => {},
            }}
            disabled
            className='phone-input-display'
          />
        )}
      </TableCell>
      {tabValue === roles.user && (
        <TableCell>
          <Box
            component='span'
            className={status === '0' ? 'badge badge--red' : 'badge badge--green'}
          >
            {status === '1' ? 'Active' : 'Inactive'}
          </Box>
        </TableCell>
      )}
      {tabValue === roles.user && (
        <TableCell>
          <Box
            component='span'
            className={accountVerified === '0' ? 'badge badge--orange' : 'badge badge--green'}
          >
            {accountVerified === '1' ? 'Verified' : 'Not Verified'}
          </Box>
        </TableCell>
      )}
      <TableCell>{createdAt && formatDate({ date: parseInt(createdAt) })}</TableCell>
      <TableCell align='right'>
        <CustomThreeDotsMenu labelId={reservationRow?.firstName as string}>
          <MenuItem onClick={() => navigateToDetail(reservationRow?.id)}>
            <VisibilityOutlinedIcon />
            View Detail
          </MenuItem>
          {tabValue === roles.user && (
            <MenuItem
              onClick={() => changeStatus(reservationRow?.id as string, reservationRow?.status)}
            >
              {reservationRow.status === '1' ? (
                <>
                  <CancelOutlinedIcon />
                  Inactivate User
                </>
              ) : (
                <>
                  <CheckCircleOutlineOutlinedIcon /> Activate User
                </>
              )}
            </MenuItem>
          )}
        </CustomThreeDotsMenu>
      </TableCell>
    </TableRow>
  );
};

export default CustomerListItem;
