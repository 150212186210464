import { SyntheticEvent, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { Alert, Snackbar, Slide, AlertColor, SnackbarCloseReason } from '@mui/material';
import { ISnackBar } from '../models/common';
import { NetworkStatus } from '../enum/enumList';

export function CustomSnackbar<T>(
  WrappedComponent: React.ComponentType<T>,
): (hocProps: T) => JSX.Element {
  // split message if it is more than 1 error messages
  const renderMessage = (message: string) => {
    let updatedMessage = message;
    if (updatedMessage.includes(NetworkStatus.ErrorMessage))
      updatedMessage = NetworkStatus.FallbackMessage;

    if (updatedMessage.split('*|').length <= 1) {
      return updatedMessage;
    }
    return (
      <ul>
        {' '}
        {updatedMessage.split('*|').map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    );
  };

  return function withSnackbar(hocProps: T) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string>('Im a custom snackbar');

    const [duration, setDuration] = useState(3000);
    const [severity, setSeverity] = useState<AlertColor>('success'); /** error | warning | info */

    const showMessage: ISnackBar = (message: string, severity: AlertColor, duration = 3000) => {
      setMessage(message);
      setSeverity(severity);
      setDuration(duration);
      setOpen(true);
    };

    const handleClose = (
      event: Event | SyntheticEvent<Element, Event>,
      reason?: SnackbarCloseReason,
    ) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const SnackbarPortal = useCallback(
      () =>
        ReactDOM.createPortal(
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={duration}
            open={open}
            onClose={handleClose}
            className={message.split('*|').length <= 1 ? '' : 'snackbar-multiline'}
            TransitionComponent={Slide}
          >
            <Alert variant='filled' onClose={handleClose} severity={severity}>
              {renderMessage(message)}
            </Alert>
          </Snackbar>,
          document.body,
        ),
      [open, message, duration],
    );

    return (
      <>
        <WrappedComponent {...hocProps} snackbarShowMessage={showMessage} />
        <SnackbarPortal />
      </>
    );
  };
}
