import { ChangeEvent, useState } from 'react';
import moment from 'moment';
import { UseFormSetValue } from 'react-hook-form/dist/types';
import { TableRow, TableCell, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';

import formatPrice from '../../utils/formatPrice';
import { IPaymentDetailForm } from '../../models/reservation';
import { paymentMode, paymentType } from '../../enum/enumList';
import DeleteModal from '../modal/confirmation-modal/DeleteModal';
interface IReservationTransactionListItem {
  reservationRow: any;
  selectedTransaction: string | null;
  setSelectedTransaction: UseFormSetValue<IPaymentDetailForm>;
  showRadioButton?: boolean;
  handlePayment: (data: IPaymentDetailForm, isVoidTransaction?: boolean) => void;
  refundPaymentLoading?: boolean;
}

const ReservationTransactionListItem = ({
  reservationRow,
  selectedTransaction,
  setSelectedTransaction,
  showRadioButton = false,
  handlePayment,
  refundPaymentLoading = false,
}: IReservationTransactionListItem) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleVoidTansaction = () => {
    handlePayment(
      {
        isPos: false,
        transactionType: paymentType.refund,
        amount: reservationRow?.amount,
        notes: null,
        selectedCard: '',
        selectedTransaction: reservationRow?.transactionId,
        cardNumber: reservationRow?.card ?? '',
        paymentProfileId: '',
        transactionAmount: reservationRow?.amount,
      },
      true,
    );
    setOpenDeleteModal(false);
  };
  const radioLabel = (
    <>
      {formatPrice(reservationRow?.amount ?? 0)} <br />
      <span className='label-refund'>
        {' '}
        {reservationRow?.refundedAmount && reservationRow?.paymentType !== paymentType.refund
          ? '(Refund ' + formatPrice(reservationRow?.refundedAmount * -1) + ')'
          : ''}
      </span>
    </>
  );

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedTransaction('selectedTransaction', e.target.value);
    setSelectedTransaction(
      'transactionAmount',
      reservationRow?.amount - reservationRow.refundedAmount ?? 0,
    );
  };

  return (
    <>
      <TableRow
        key={reservationRow?.transactionId}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell scope='row'>
          {!showRadioButton ||
          reservationRow?.paymentMode === paymentMode.pos ||
          reservationRow?.paymentType === paymentMode.refund ||
          reservationRow?.amount === reservationRow?.refundedAmount ||
          !reservationRow?.isSettled ||
          reservationRow?.isVoidTransaction ? (
            radioLabel
          ) : (
            <RadioGroup
              aria-labelledby='radio-buttons-group-label'
              name='radio-transactions'
              value={selectedTransaction || null}
              onChange={onSelect}
            >
              <FormControlLabel
                value={reservationRow?.transactionId}
                control={<Radio />}
                label={radioLabel}
              />
            </RadioGroup>
          )}
        </TableCell>
        <TableCell scope='row'>{reservationRow?.transactionId ?? 'N/A'}</TableCell>
        <TableCell>{reservationRow?.card ?? 'N/A'}</TableCell>
        <TableCell className='text-capitalize'>{reservationRow?.paymentType ?? '--'}</TableCell>
        <TableCell>{reservationRow?.notes ?? '--'}</TableCell>
        <TableCell>
          {reservationRow?.paymentMode === paymentMode.pos
            ? 'POS'
            : reservationRow?.paymentMode === paymentMode.card
            ? 'Card'
            : '--'}
        </TableCell>
        <TableCell>
          {moment(reservationRow?.createdAt).isValid()
            ? moment(reservationRow?.createdAt).format('MM/DD/YYYY hh:mm A')
            : '--'}
        </TableCell>
        <TableCell sx={{ width: 110 }}>
          {!reservationRow?.isSettled &&
            !reservationRow?.isVoidTransaction &&
            reservationRow?.paymentType !== paymentType.refund && (
              <Button
                type='button'
                className='primary-btn primary-btn--sm'
                onClick={() => setOpenDeleteModal(true)}
                disabled={refundPaymentLoading}
              >
                Void
              </Button>
            )}
        </TableCell>
      </TableRow>

      <DeleteModal
        label='Do you really want to void this transaction '
        buttonText='Void Transaction'
        modelOpen={openDeleteModal}
        setModelOpen={setOpenDeleteModal}
        onDelete={handleVoidTansaction}
        loading={refundPaymentLoading}
      />
    </>
  );
};

export default ReservationTransactionListItem;
