import Box from '@mui/material/Box';

import './PageLoader.scss';

const PageLoader = () => {
  return (
    <Box className='page-loader'>
      <Box className='loader-ring'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Box>
    </Box>
  );
};
export default PageLoader;
