import { MenuItem, TableCell, TableRow } from '@mui/material';
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  ReplayOutlined as ReplayOutlinedIcon,
} from '@mui/icons-material';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { ICommunicationListItem, ICommunicationTabForm } from '../../models/reservation';
import { communicationMethod } from '../../enum/enumList';
import { formatDate } from '../../utils/formatDate';
import { CustomPhoneInput } from '../common/customFormControl';

interface IReservationCommunicationListItem {
  reservationRow: ICommunicationListItem;
  index: number;
  openModal: (type: keyof typeof communicationMethod, modalData?: ICommunicationListItem) => void;
  handleAddCommunicationLog: (data: Partial<ICommunicationTabForm>) => void;
}

const ReservationCommunicationListItem = ({
  reservationRow,
  index,
  openModal,
  handleAddCommunicationLog,
}: IReservationCommunicationListItem) => {
  const resendPayload =
    reservationRow?.method === communicationMethod.notes ||
    reservationRow?.method === communicationMethod.text
      ? {
          phone: reservationRow?.phone,
          message: reservationRow?.message,
          method: reservationRow?.method,
        }
      : {
          email: reservationRow?.email,
          subject: reservationRow?.subject,
          message: reservationRow?.message,
          method: reservationRow?.method,
        };

  return (
    <TableRow key={reservationRow?.id}>
      <TableCell scope='row'>{index + 1}</TableCell>
      <TableCell style={{ minWidth: 200 }}>
        {reservationRow?.method === communicationMethod.email
          ? reservationRow?.email
          : (
              <CustomPhoneInput
                field={{
                  value: reservationRow?.phone,
                  onChange: () => {},
                }}
                disabled
                className='phone-input-display'
              />
            ) ?? 'N/A'}
      </TableCell>
      <TableCell>{reservationRow?.employeeName ?? 'N/A'}</TableCell>
      <TableCell className='text-capitalize'>{reservationRow?.method || 'N/A'}</TableCell>
      <TableCell>{reservationRow?.message || 'N/A'}</TableCell>
      <TableCell>
        {reservationRow?.createdAt
          ? formatDate({
              date: parseInt(reservationRow.createdAt),
              requiredHour: true,
            })
          : 'N/A'}
      </TableCell>
      <TableCell align='right'>
        <CustomThreeDotsMenu labelId={reservationRow?.id}>
          <MenuItem onClick={() => openModal(reservationRow?.method, { ...reservationRow })}>
            <VisibilityOutlinedIcon />
            View
          </MenuItem>
          {reservationRow?.method !== communicationMethod.notes && (
            <MenuItem onClick={() => handleAddCommunicationLog(resendPayload)}>
              <ReplayOutlinedIcon />
              Resend
            </MenuItem>
          )}
        </CustomThreeDotsMenu>
      </TableCell>
    </TableRow>
  );
};

export default ReservationCommunicationListItem;
