import { Messages, paymentType } from './../../../../enum/enumList';
import * as Yup from 'yup';
import formatPrice from '../../../../utils/formatPrice';

export const paymentTabValidator = (
  paymentLeft: number,
  amountPayed: number,
  currentTransactionAmount: number | undefined | null,
) =>
  Yup.object().shape({
    isPos: Yup.boolean().typeError(Messages.required).required(Messages.required),
    transactionType: Yup.string().trim().typeError(Messages.required).required(Messages.required),
    amount: Yup.number()
      .min(0, Messages.positiveNumber)
      .test(
        'is more than payment left',
        `${Messages.maxChargePayment} (${formatPrice(paymentLeft || 0)})`,
        (value, context) =>
          !(context.parent.transactionType === paymentType.charge && value && value > paymentLeft),
      )
      .test(
        'is more than amount payed',
        `${Messages.maxRefundAmount} (${formatPrice(amountPayed || 0)})`,
        (value, context) =>
          !(context.parent.transactionType === paymentType.refund && value && value > amountPayed),
      )
      .test(
        'is more than transactionAmount',
        `${Messages.maxTransRefundAmount} (${formatPrice(currentTransactionAmount || 0)})`,
        (value, context) =>
          !(
            !context.parent.isPos &&
            context.parent.transactionType === paymentType.refund &&
            context.parent.selectedTransaction &&
            value &&
            value > context.parent.transactionAmount
          ),
      )
      .typeError(Messages.required)
      .required(Messages.required),
    notes: Yup.string().trim().max(500, Messages.fieldTooLong).typeError(Messages.required),
    selectedCard: Yup.string().test(
      'is card needed',
      Messages.cardRequired,
      (value, context) =>
        !(!context.parent.isPos && context.parent.transactionType === paymentType.charge && !value),
    ),
    selectedTransaction: Yup.string().test(
      'is transaction needed',
      Messages.transactionRequired,
      (value, context) =>
        !(!context.parent.isPos && context.parent.transactionType === paymentType.refund && !value),
    ),
  });
