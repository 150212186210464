import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { addonStatus } from '../../../../../enum/enumList';

import NoRecordFound from '../../../../common/noRecordFound/NoRecordFound';

interface IGeneralTab {
  renterDetail?: any;
}
const AddonsTab = ({ renterDetail }: IGeneralTab) => {
  return (
    <Grid container component='main' className='modal-lcol modal-lcol--border-none' spacing={3}>
      {renterDetail?.selectedPackage?.addons?.length ? (
        renterDetail?.selectedPackage?.addons?.map((item: any) => (
          <Grid item xs={12} md={6} sm={6} key={item.id}>
            <Box component='div' className='modal-row'>
              <Box component='div'>
                <Box component='label' className='modal-row__heading'>
                  {item.addons_name}
                </Box>
                {item?.status === addonStatus.assigned && (
                  <Box component='span' className='badge badge--addon-selected-badge'>
                    Assigned
                  </Box>
                )}
              </Box>
              <Box component='div' className='modal-row__desc'>
                {item?.startDate ? moment(item?.startDate).format('MM/DD/YYYY') : 'N/A'}
              </Box>
            </Box>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} md={12} sm={12}>
          <NoRecordFound text='No addons selected with current package' />
        </Grid>
      )}
    </Grid>
  );
};

export default AddonsTab;
