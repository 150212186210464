import { Alert, AlertColor } from '@mui/material';

interface INoRecordFound {
  severity?: AlertColor | undefined;
  text?: string;
}

const NoRecordFound = ({ severity = 'info', text = 'No record found' }: INoRecordFound) => {
  return (
    <Alert sx={{ width: '100%' }} severity={severity}>
      {text}
    </Alert>
  );
};

export default NoRecordFound;
