import { Button, CircularProgress } from '@mui/material';
import { CSSProperties } from 'react';

interface ISubmitButton {
  isLoading?: boolean;
  disabled?: boolean;
  sx?: CSSProperties;
  onClick: (e?: any) => void;
  children: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

const SubmitButton = ({
  isLoading = false,
  onClick,
  children,
  disabled,
  sx = {},
  className = 'primary-btn',
  type = 'button',
}: ISubmitButton) => {
  return (
    <Button
      type={type}
      className={className}
      sx={sx}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      {!isLoading ? children : <CircularProgress size={24} />}
    </Button>
  );
};

export default SubmitButton;
