import { createContext, useContext, useState } from 'react';
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

type ErrorContextType = {
  errorLink?: ApolloLink;
  uploadLink?: ApolloLink;
  isError?: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  isLocked: boolean;
  setIsLocked: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ErrorContext = createContext<ErrorContextType>({} as ErrorContextType);

export const ErrorProvider = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate();
  const [isError, setError] = useState<string>('');
  const [isLocked, setIsLocked] = useState(localStorage.getItem('isLocked') === 'true');

  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    credentials: 'include',
  });

  const errorLink = onError(({ graphQLErrors, networkError }: any) => {
    if (networkError)
      console.error(
        '🚀 ~ file: errorHandler.ts ~ line 12 ~ errorHandler ~ networkError',
        networkError,
      );

    // Check for GraphQL errors
    if (graphQLErrors) {
      if (
        graphQLErrors[0].extensions?.code &&
        graphQLErrors[0].extensions?.code === 'UNAUTHENTICATED'
      ) {
        setError(graphQLErrors[0].extensions?.code as string);
      } else if (graphQLErrors[0]?.message && graphQLErrors[0].message === 'Access denied.') {
        setError('UNAUTHENTICATED');
      } else if (graphQLErrors[0].code && graphQLErrors[0].code === 'FORBIDDEN') {
        localStorage.setItem('isLocked', 'true');
        setIsLocked(true);
        navigate('/unlock');
      }
    } else {
      console.error('Something went wrong! Please try again.');
    }
  });

  return (
    <ErrorContext.Provider
      value={{ isError, uploadLink, errorLink, setError, isLocked, setIsLocked }}
    >
      <ApolloProvider
        client={
          new ApolloClient({
            link: ApolloLink.from([errorLink, uploadLink]),
            cache: new InMemoryCache(),
          })
        }
      >
        {children}
      </ApolloProvider>
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  return useContext(ErrorContext);
};
