import { Box, Grid } from '@mui/material';
import formatPrice from '../../../../../utils/formatPrice';
interface IGeneralTab {
  renterDetail?: any;
}

const PricingTab = ({ renterDetail }: IGeneralTab) => {
  return (
    <Grid container component='main' className='modal-lcol modal-lcol--border-none' spacing={3}>
      <Grid item xs={12} md={12} sm={12}>
        <Box component='div' className='panel panel--column'>
          <Box component='div' className='panel__header'>
            <strong className='text-uppercase'>Pricing</strong>
          </Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box component='div' className='panel__content-row'>
                <Box component='label'>Packages</Box>
                {formatPrice(renterDetail?.pricing?.packageAmount ?? 0)}
              </Box>
              <Box component='div' className='panel__content-row'>
                <Box component='label'>Add-ons</Box>
                {formatPrice(renterDetail?.pricing?.addonsAmount ?? 0)}
              </Box>
              <Box component='div' className='panel__content-row'>
                <Box component='label'>Sub Total</Box>
                {formatPrice(renterDetail?.pricing?.subTotal ?? 0)}
              </Box>
              <Box component='div' className='panel__content-row'>
                <Box component='label'>Adv. Res. Discount</Box>
                {renterDetail?.pricing?.discount !== 0 ? '-' : ''}
                {formatPrice(renterDetail?.pricing?.discount ?? 0)}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component='div' className='panel__content-row'>
                <Box component='label'>Admin Discount</Box>
                {renterDetail?.pricing?.adminDiscount !== 0 ? '-' : ''}
                {formatPrice(renterDetail?.pricing?.adminDiscount ?? 0)}
              </Box>
              <Box component='div' className='panel__content-row'>
                <Box component='label'>Tax</Box>
                {formatPrice(renterDetail?.pricing?.tax ?? 0)}
              </Box>
              <Box component='div' className='panel__content-row'>
                <Box component='label'> Total</Box>
                {formatPrice(renterDetail?.pricing?.totalAmount ?? 0)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PricingTab;
