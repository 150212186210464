interface HeadCell {
  id: string;
  label: string;
  numeric: boolean;
}

export const cardHeadCells: HeadCell[] = [
  {
    id: 'Name',
    numeric: false,
    label: 'Name',
  },
  {
    id: 'Email',
    numeric: false,
    label: 'Email',
  },
  {
    id: 'Card',
    numeric: false,
    label: 'Card',
  },
  {
    id: 'Created At',
    numeric: false,
    label: 'Created At',
  },
];

function createCreditCardData(name: string, email: string, card: string, createdAt: string) {
  return { name, email, card, createdAt };
}
export const reservationNotesRows = [
  createCreditCardData('Chris Abbruzzese', 'cea210@yahoo.com', 'xxxx 1234', '12/26/2021 20:35:11'),
  createCreditCardData('Chris Jorden', 'cea210@yahoo.com', 'xxxx 1234', '12/26/2021 20:35:11'),
];

export const transactionHeadCells: HeadCell[] = [
  {
    id: 'Amount',
    numeric: true,
    label: 'Amount',
  },
  {
    id: 'Transaction ID',
    numeric: false,
    label: 'Transaction ID',
  },
  {
    id: 'Card',
    numeric: false,
    label: 'Card',
  },
  {
    id: 'type',
    numeric: false,
    label: 'Type',
  },
  {
    id: 'Notes',
    numeric: false,
    label: 'Notes',
  },
  {
    id: 'Payment Method',
    numeric: false,
    label: 'Payment Method',
  },
  {
    id: 'Created At',
    numeric: false,
    label: 'Created At',
  },
];
