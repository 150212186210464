import { Box, MenuItem, TableCell, TableRow } from '@mui/material';

import { IRenterDetails } from '../../models/techStation';
import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { Seasons } from '../../enum/enumList';

interface IAssignEquipmentsListItem {
  reservationRow: IRenterDetails;
  unassignEquipment: (data: IRenterDetails) => void;
}

const AssignEquipmentsListItem = ({
  reservationRow,
  unassignEquipment,
}: IAssignEquipmentsListItem) => {
  return (
    <TableRow key={reservationRow?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell scope='row'>
        {reservationRow?.firstName || ''} {reservationRow?.lastName || ''}
      </TableCell>
      <TableCell>{reservationRow?.age ?? 'N/A'}</TableCell>
      <TableCell>{reservationRow?.gender || 'N/A'}</TableCell>
      <TableCell>
        {reservationRow?.reservations_season === Seasons.winter
          ? `${reservationRow?.feet ?? 0}' ${reservationRow?.inches ?? 0}"`
          : 'N/A'}
      </TableCell>
      <TableCell>
        {reservationRow?.reservations_season === Seasons.winter
          ? reservationRow?.weight ?? 'N/A'
          : 'N/A'}
      </TableCell>
      <TableCell>
        {reservationRow?.reservations_season === Seasons.winter
          ? reservationRow?.shoeSize ?? 'N/A'
          : 'N/A'}
      </TableCell>
      <TableCell>
        {reservationRow?.reservations_season === Seasons.winter
          ? reservationRow?.skiLengthPreference || 'N/A'
          : 'N/A'}
      </TableCell>
      <TableCell>{reservationRow?.selectCategory?.skierType || 'N/A'}</TableCell>
      <TableCell>{reservationRow?.selectedPackage?.name || 'N/A'}</TableCell>
      <TableCell>
        <Box
          component='span'
          className={`badge text-capitalize ${
            reservationRow?.equipmentAssigned === 'no'
              ? 'badge--red'
              : reservationRow?.equipmentAssigned === 'yes'
              ? 'badge--green'
              : 'badge--blue'
          }`}
        >
          {reservationRow?.equipmentAssigned || 'NA'}
        </Box>
      </TableCell>
      {reservationRow?.equipmentAssigned !== 'no' ? (
        <TableCell align='right'>
          <CustomThreeDotsMenu labelId={reservationRow?.id}>
            <MenuItem onClick={() => unassignEquipment(reservationRow)}>
              Unassign Equipment
            </MenuItem>
          </CustomThreeDotsMenu>
        </TableCell>
      ) : null}
    </TableRow>
  );
};

export default AssignEquipmentsListItem;
