import { Link, useNavigate } from 'react-router-dom';
import { Box, MenuItem, TableCell, TableRow } from '@mui/material';
import {
  EngineeringOutlined as EngineeringOutlinedIcon,
  TextSnippetOutlined as TextSnippetOutlinedIcon,
} from '@mui/icons-material';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { IRenterDetails } from '../../models/techStation';
import { Seasons } from '../../enum/enumList';

interface ITrchStationListingItem {
  reservationRow: IRenterDetails;
}

const TechStationListingItem = ({ reservationRow }: ITrchStationListingItem) => {
  const navigate = useNavigate();

  const handleAssignEquipment = () => {
    navigate('/reservation/assign-equipment', {
      state: {
        renterId: reservationRow?.id,
      },
    });
  };

  const handleReservationDetailsClick = () => {
    navigate('/reservation/reservation-detail');
  };

  return (
    <TableRow hover tabIndex={-1} key={reservationRow?.id}>
      <TableCell id={reservationRow?.id} scope='reservationRow'>
        <Link to='/reservation/assign-equipment' state={{ renterId: reservationRow?.id }}>
          {reservationRow?.firstName} {reservationRow?.lastName}
        </Link>
      </TableCell>
      <TableCell>{reservationRow?.age ?? 'NA'}</TableCell>
      <TableCell>{reservationRow?.gender || 'NA'}</TableCell>
      <TableCell>
        {reservationRow?.reservations_season === Seasons.winter
          ? `${reservationRow?.feet || 0}' ${reservationRow?.inches || 0}"`
          : 'N/A'}
      </TableCell>
      <TableCell>
        {reservationRow?.reservations_season === Seasons.winter
          ? reservationRow?.weight || 'N/A'
          : 'N/A'}
      </TableCell>
      <TableCell>
        {reservationRow?.reservations_season === Seasons.winter
          ? reservationRow?.shoeSize || 'N/A'
          : 'N/A'}
      </TableCell>
      <TableCell>
        {reservationRow?.reservations_season === Seasons.winter
          ? reservationRow?.skiLengthPreference || 'N/A'
          : 'N/A'}
      </TableCell>
      <TableCell>{reservationRow?.selectCategory?.skierType || 'NA'}</TableCell>
      <TableCell>{reservationRow?.selectedPackage?.name || 'NA'}</TableCell>
      <TableCell>
        <Box
          component='span'
          className={`badge text-capitalize ${
            reservationRow?.equipmentAssigned === 'no'
              ? 'badge--red'
              : reservationRow?.equipmentAssigned === 'yes'
              ? 'badge--green'
              : 'badge--blue'
          }`}
        >
          {reservationRow?.equipmentAssigned || 'NA'}
        </Box>
      </TableCell>

      <TableCell align='right'>
        <CustomThreeDotsMenu labelId={reservationRow?.id}>
          <MenuItem onClick={handleAssignEquipment}>
            <EngineeringOutlinedIcon />
            Tech Station
          </MenuItem>
          <MenuItem onClick={handleReservationDetailsClick}>
            <TextSnippetOutlinedIcon />
            Reservation Detail
          </MenuItem>
        </CustomThreeDotsMenu>
      </TableCell>
    </TableRow>
  );
};

export default TechStationListingItem;
