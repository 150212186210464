import { useContext, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { renterDetailsValidator } from '../validations/addRenterValidation';
import { CustomDatePicker, CustomInput, CustomSelect } from '../../customFormControl';
import {
  DateFormat,
  Gender,
  RenterHeightFeet,
  RentersHeightInches,
  Seasons,
} from '../../../../enum/enumList';
import { RenterModalContext } from '../RenterModalContext';
import { useAuth } from '../../../../hooks/useAuth';

export const RenterDetail = () => {
  const { currentSeason } = useAuth();
  const { handleNext, setRenterDeatils, renterDetails, preFillData } =
    useContext(RenterModalContext);

  const formInitalValue = {
    firstName: (preFillData?.firstName as string) ?? renterDetails?.firstName ?? '',
    lastName: (preFillData?.lastName as string) ?? renterDetails?.lastName ?? '',
    dob: renterDetails?.dob ?? null,
    gender: renterDetails?.gender ?? '',
    feet: renterDetails?.feet ?? '',
    inches: renterDetails?.inches ?? '',
    weight: renterDetails?.weight ?? '',
    shoeSize: renterDetails?.shoeSize ?? '',
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(renterDetailsValidator),
    defaultValues: formInitalValue,
    context: { isSummerSeason: currentSeason == Seasons.summer },
  });

  const genderOptions = useMemo(
    () =>
      Object.keys(Gender).map((name) => {
        return {
          label: name === 'PreferNotToAnswer' ? 'Prefer not to answer' : name,
          value:
            name === 'PreferNotToAnswer'
              ? 'Prefer not to answer'
              : Gender[name as keyof typeof Gender],
        };
      }),
    [Gender],
  );

  const feetHeightOptions = useMemo(
    () =>
      Object.keys(RenterHeightFeet).map((name) => {
        return {
          label: name,
          value: RenterHeightFeet[name as keyof typeof RenterHeightFeet],
        };
      }),
    [RenterHeightFeet],
  );
  const inchHeightOptions = useMemo(
    () =>
      Object.keys(RentersHeightInches).map((name) => {
        return {
          label: name,
          value: RentersHeightInches[name as keyof typeof RentersHeightInches],
        };
      }),
    [RentersHeightInches],
  );
  const handleFormSubmit = (data: any) => {
    setRenterDeatils((prev) => ({ ...prev, ...data }));
    handleNext();
  };

  useEffect(() => {
    reset(formInitalValue);
  }, [renterDetails]);

  return (
    <>
      <DialogContent>
        <Grid
          container
          component='main'
          className='modal-lcol modal-lcol--border-none modal-lcol--mt'
          spacing={4}
        >
          <Grid container component='div' spacing={5} className='renter-detail'>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                name='firstName'
                render={({ field, formState }) => {
                  return (
                    <CustomInput
                      label='First Name'
                      mainClassName='label'
                      name='firstName'
                      placeholder='Enter First Name'
                      type='text'
                      field={field}
                      error={formState.errors.firstName}
                      textInputProps={{ maxLength: 100 }}
                      fullWidth
                      required
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                name='lastName'
                render={({ field, formState }) => (
                  <CustomInput
                    label='Last Name'
                    mainClassName='label'
                    name='lastName'
                    placeholder='Enter Last Name'
                    type='text'
                    field={field}
                    error={formState.errors.lastName}
                    textInputProps={{ maxLength: 100 }}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                name='dob'
                render={({ field, formState }) => (
                  <CustomDatePicker
                    label='DOB'
                    name='dob'
                    mainClassName='label label--width'
                    placeholder='MM/DD/YYYY'
                    dateFormat={DateFormat.MDY}
                    maxDate={new Date()}
                    field={field}
                    error={formState.errors.dob}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                name='gender'
                render={({ field, formState }) => (
                  <CustomSelect
                    id='gender'
                    label='Gender'
                    mainClassName='label label--width'
                    placeholder='-- Select --'
                    menuItems={genderOptions}
                    field={field}
                    error={formState.errors.gender}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            {currentSeason === Seasons.winter ? (
              <Grid item xs={12} md={6} lg={6}>
                <Box component='label' className='label label--width'>
                  Height
                  <Box component='span'>*</Box>
                </Box>
                <Grid container spacing={3} className='mt-0'>
                  <Grid item xs={6} md={6} lg={6} className='pb-0'>
                    <Controller
                      control={control}
                      name='feet'
                      render={({ field }) => {
                        return (
                          <CustomSelect
                            id='feet'
                            label=''
                            mainClassName='label label--width '
                            placeholder='-- Feet --'
                            menuItems={feetHeightOptions}
                            field={field}
                            fullWidth
                            showLabel={false}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} className='pb-0'>
                    <Controller
                      control={control}
                      name='inches'
                      render={({ field }) => {
                        return (
                          <CustomSelect
                            id='inches'
                            label=''
                            mainClassName='label label--width'
                            placeholder='-- Inch --'
                            menuItems={inchHeightOptions}
                            field={field}
                            fullWidth
                            showLabel={false}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {(errors.feet || errors.inches) &&
                    (errors.feet?.message || errors.inches?.message) && (
                      <Grid item xs={12} md={12} lg={12} className='pb-0'>
                        <Typography color='red'>
                          {errors.feet?.message || errors.inches?.message}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            ) : null}
            {currentSeason === Seasons.winter ? (
              <Grid item xs={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name='weight'
                  render={({ field, formState }) => (
                    <CustomInput
                      label='Weight (in lbs)'
                      mainClassName='label'
                      name='weight'
                      placeholder='Enter Your Weight'
                      type='string'
                      field={field}
                      error={formState.errors.weight}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            ) : null}
            {currentSeason === Seasons.winter ? (
              <Grid item xs={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name='shoeSize'
                  render={({ field, formState }) => (
                    <CustomInput
                      id='shoeSize'
                      label='Shoe size'
                      mainClassName='label label--width'
                      placeholder='Enter Shoe Size'
                      field={field}
                      error={formState.errors.shoeSize}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='modal__footer'>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button disabled sx={{ mr: 2 }} className='back-btn'>
            Previous
          </Button>
          <Button onClick={handleSubmit(handleFormSubmit)} className='primary-btn'>
            Next
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};
