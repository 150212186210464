import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ModalTitle } from '../../sidenav/modal/ModalHeader';
import { CustomSelect } from '../../common/customFormControl';
import { CustomSubmitButton } from '../..';
import { selectStoreValidation } from './validation';
import { useAuth } from '../../../hooks/useAuth';

interface ISelectStoreModal {
  reservationId: string;
  modelOpen: boolean;
  closeModal: () => void;
  callback: (reservationId: string, storeId: string) => void;
  isLoading?: boolean;
}
interface ISelectStoreForm {
  storeId: string;
}

const SelectStoreModal = ({
  modelOpen,
  closeModal,
  callback,
  reservationId,
  isLoading,
}: ISelectStoreModal) => {
  const { user } = useAuth();
  const selecStoreValue = {
    storeId: '',
  };
  const { control, handleSubmit } = useForm<ISelectStoreForm>({
    resolver: yupResolver(selectStoreValidation),
    defaultValues: selecStoreValue,
  });

  const storeListing = useMemo(
    () =>
      user?.stores?.map((store) => ({
        value: store.id,
        label: store.name,
      })),
    [user?.stores],
  );

  const handleClose = () => {
    closeModal();
  };
  const BootstrapDialog = useCallback(
    styled(Dialog)(({ theme }) => ({
      '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
    })),
    [],
  );

  const handleSave = (data: ISelectStoreForm) => {
    callback(reservationId, data?.storeId);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='store-title'
      fullWidth
      maxWidth='sm'
      open={modelOpen}
      className='modal'
    >
      <ModalTitle id='store-title' className='modal__heading' onClose={handleClose}>
        Select Store
      </ModalTitle>

      <DialogContent dividers className='wrapper'>
        <Grid container className='modal-lcol modal-lcol--border-none'>
          <Grid item xs={12} md={6} sm={6} className='pt-10'>
            <Box component='div' className='modal-col__row'>
              <Controller
                defaultValue={''}
                control={control}
                name='storeId'
                render={({ field, formState: { errors } }) => (
                  <CustomSelect
                    id='reservation-payment-month'
                    label='Select store for duplicate reservation'
                    mainClassName='row__label'
                    placeholder='-- Select Store --'
                    menuItems={storeListing}
                    field={field}
                    error={errors?.storeId}
                    fullWidth
                    required
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='modal__footer'>
        <Box sx={{ position: 'relative' }}>
          <CustomSubmitButton onClick={handleSubmit(handleSave)} isLoading={isLoading}>
            Duplicate
          </CustomSubmitButton>
        </Box>
        <Button onClick={handleClose} className='cancel-btn' disabled={isLoading}>
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default SelectStoreModal;
