import React, { FC } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import * as Images from '../components/Images';

import { DrawerHeader, Drawer } from '../components/sidenav/styles/sidebar';
import MenuNavLinks from '../components/sidenav/views/MenuNavLinks';
interface Props {
  open?: boolean;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  handleDrawerOpen?: false;
  setOpen: (open: boolean) => void;
}
export const DrawerOuter: FC<Props> = ({ onClick, open, setOpen }: Props) => {
  return (
    <Drawer variant='permanent' open={!open}>
      <DrawerHeader>
        <Box
          component='img'
          alt='Bluebird Mountain Sports'
          src={Images.logoWhite}
          className='sidebar-logo'
        />
        <IconButton onClick={onClick} className='sidebar-closebtn' name='sidebar close'>
          <ArrowBackIcon />
        </IconButton>
      </DrawerHeader>
      <MenuNavLinks open={open ?? false} setOpen={setOpen} />
    </Drawer>
  );
};
