import { DateRange } from 'react-date-range';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  LocalizationProvider,
  DesktopDatePicker,
  PickersDayProps,
  PickersDay,
} from '@mui/x-date-pickers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PhoneInput from 'react-phone-input-2';
import InfoIcon from '@mui/icons-material/Info';
import { DateFormat, Messages } from '../../../enum/enumList';
import {
  ICustomCheckbox,
  ICustomDateRangePicker,
  ICustomInput,
  INumberInput,
} from '../../../models/common';
import { phoneCountryCode } from '../../../utils/phone-country-code';

import 'react-phone-input-2/lib/style.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { CustomTooltip } from '../..';
import { IMaskInput } from 'react-imask';

// type DateChangeEvent = (value: any, keyboardInputValue?: string | undefined) => void;

export const CustomInput = ({
  label = '',
  required = false,
  mainClassName = '',
  className = '',
  fullWidth = false,
  name = '',
  placeholder = 'Enter the value',
  type = 'text',
  isSibling = false,
  onKeyPress = (event) => {},
  children,
  error,
  field,
  disabled,
  handleChange,
  inputProps,
  textInputProps,
  autoComplete = 'on',
  multiline = false,
  ...props
}: ICustomInput) => {
  const { onChange, value } = field || {};

  return isSibling ? (
    <>
      <Box component='label' className={mainClassName} htmlFor={name}>
        <Box component='div'>
          {label}
          {required && <Box component='span'>*</Box>}
        </Box>
        {children}
      </Box>
      <TextField
        fullWidth={fullWidth}
        name={name}
        id={name}
        placeholder={placeholder}
        type={type}
        onChange={handleChange ? handleChange : onChange}
        value={value}
        disabled={disabled}
        InputProps={inputProps}
        inputProps={textInputProps}
        onKeyPress={onKeyPress}
        {...props}
        autoComplete={autoComplete}
      />
      {error && error.message && (
        <Typography color='red' className='error-fs'>
          {error?.message}
        </Typography>
      )}
    </>
  ) : (
    <>
      <Box component='label' className={mainClassName} htmlFor={name}>
        {label}
        {required && <Box component='span'>*</Box>}
      </Box>
      <TextField
        className={className}
        fullWidth={fullWidth}
        name={name}
        id={name}
        placeholder={placeholder}
        type={type}
        onChange={handleChange ? handleChange : onChange}
        value={value}
        InputProps={inputProps}
        inputProps={textInputProps}
        onKeyPress={onKeyPress}
        multiline={multiline}
        disabled={disabled}
        autoComplete={autoComplete}
        {...props}
      />
      {error && error.message && (
        <Typography color='red' className='error-fs'>
          {error?.message}
        </Typography>
      )}
    </>
  );
};

type renderDayFunction = (
  day: any,
  selectedDays: any[],
  pickersDayProps: PickersDayProps<any>,
) => JSX.Element;

export const CustomDatePicker = ({
  label = '',
  required = false,
  mainClassName = '',
  placeholder = 'Enter the value',
  dateFormat = DateFormat.MDY,
  disabled = false,
  actions = ['cancel'],
  minDate,
  maxDate,
  field,
  children,
  error,
  disableManualInput,
  blockStartDate,
  blockedEndDate,
  ...props
}: ICustomInput) => {
  const { onChange, value } = field || {};
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      if (disableManualInput && !disabled) {
        inputRef.current.disabled = true;
      }
    }
  }, [inputRef.current, disabled]);

  const renderDay: renderDayFunction = (day, selectedDays, pickersDayProps) => {
    // if date is between blockStartDate and blockedEndDate change background color to red
    if (
      blockStartDate &&
      blockedEndDate &&
      day.isBetween(blockStartDate, blockedEndDate, 'day', '[]')
    ) {
      const updatedDay = day['$d' as keyof Date];
      const style = {
        backgroundColor:
          selectedDays.length &&
          moment(updatedDay).isSame(moment(selectedDays[0]['$d' as keyof Date]))
            ? 'blue'
            : '#adadad',
        color:
          selectedDays.length &&
          moment(updatedDay).isSame(moment(selectedDays[0]['$d' as keyof Date]))
            ? 'white'
            : '#e7e7e7',
      };
      return (
        <PickersDay
          {...pickersDayProps}
          // disabled
          style={style}
        />
      );
    }

    return <PickersDay {...pickersDayProps} />;
  };

  return (
    <>
      {(label || children || required) && (
        <Box component='label' className={mainClassName}>
          {label}

          {children}

          {required && <Box component='span'>*</Box>}
        </Box>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          open={openDatePicker}
          disabled={disabled}
          {...field}
          renderDay={renderDay}
          onChange={(value: any) => {
            onChange && onChange(value && value['$d' as keyof Date]);
          }}
          onOpen={() => setOpenDatePicker(true)}
          onClose={() => setOpenDatePicker(false)}
          value={value}
          inputFormat={dateFormat}
          className='w-100'
          inputRef={inputRef}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder={placeholder}
                error={!!error}
                onClick={() => {
                  if (disableManualInput && !disabled) {
                    setOpenDatePicker(true);
                  }
                }}
              />
            );
          }}
          componentsProps={{
            actionBar: {
              actions,
            },
          }}
          minDate={minDate}
          maxDate={maxDate}
          closeOnSelect
          {...props}
        />
      </LocalizationProvider>
      {error && error.message ? (
        <Typography color='red' className='error-fs'>
          {error.message}
        </Typography>
      ) : value?.length && !moment(value).isValid() ? (
        <Typography color='red' className='error-fs'>
          {Messages.invalidDateFormat}
        </Typography>
      ) : null}
    </>
  );
};

// menuItem = [{value: '1', label: '1'}]
export const CustomSelect = ({
  id = '',
  label = '',
  name = '',
  required = false,
  mainClassName = '',
  placeholder = '',
  fullWidth = false,
  displayEmpty = true,
  showLabel = true,
  error,
  field,
  menuItems = [],
  defaultPlaceholderValue = '',
  disablePlaceholder = true,
  showTooltip = false,
  tooltipContent = '',
  autoComplete = 'on',
}: ICustomInput) => {
  const { onChange, value } = field || {};

  return (
    <>
      {showLabel && (
        <Box component='label' id={id} className={mainClassName} htmlFor={id}>
          {label}
          {showTooltip && (
            <CustomTooltip title={tooltipContent} arrow placement='top'>
              <InfoIcon fontSize='small' />
            </CustomTooltip>
          )}
          {required && <Box component='span'>*</Box>}
        </Box>
      )}

      <Select
        labelId={id}
        id={id || 'reservation-type'}
        fullWidth={fullWidth}
        onChange={onChange}
        autoComplete='autoComplete'
        value={value}
        inputProps={{ 'aria-label': 'Without label' }}
        displayEmpty={displayEmpty}
        multiple={false}
        name={name}
      >
        <MenuItem disabled={disablePlaceholder} value={defaultPlaceholderValue}>
          <em>{placeholder}</em>
        </MenuItem>
        {menuItems.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>

      {error && error.message && (
        <Typography color='red' className='error-fs'>
          {error.message}
        </Typography>
      )}
    </>
  );
};

export const CustomCheckbox = ({
  field,
  label,
  mainClassName = '',
  error,
  disabled = false,
}: ICustomCheckbox) => {
  const { onChange, value } = field || {};

  return (
    <>
      <FormControlLabel
        className={mainClassName}
        control={<Checkbox onChange={onChange} checked={value} />}
        label={label}
        disabled={disabled}
      />
      {error && error.message && (
        <Typography color='red' className='error-fs'>
          {error.message}
        </Typography>
      )}
    </>
  );
};

export const CustomPhoneInput = ({
  mainClassName,
  className,
  label,
  required,
  field,
  placeholder = '',
  error,
  disabled = false,
}: Partial<ICustomInput>) => {
  const { onChange, value } = field || {};
  return (
    <>
      {(label || required) && (
        <Box component='label' className={mainClassName}>
          {label}
          {required && <Box component='span'>*</Box>}
        </Box>
      )}
      <PhoneInput
        country={'us'}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        enableSearch
        disabled={disabled}
        containerClass={className}
        inputClass='phone-input'
        countryCodeEditable={false}
      />
      {error && error.message && (
        <Typography color='red' className='error-fs'>
          {error?.message}
        </Typography>
      )}
    </>
  );
};

export const CustomNumberInput = ({
  mainClassName,
  label,
  required,
  onCountryCodeChange,
  onNumberChange,
  countryCodeValue,
  numberValue,
  error,
}: INumberInput) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.autocomplete = 'tel';
    }
  }, [inputRef.current]);

  return (
    <>
      {(label || required) && (
        <Box component='label' className={mainClassName}>
          {label}
          {required && <Box component='span'>*</Box>}
        </Box>
      )}
      <Box className='custom-phone'>
        <Select
          id='country-type'
          autoComplete='autoComplete'
          inputProps={{ 'aria-label': 'Without label' }}
          multiple={false}
          className='custom-phone__sel'
          value={countryCodeValue}
          onChange={(e) => {
            onCountryCodeChange(e.target.value || '');
          }}
        >
          {phoneCountryCode.map((item) => (
            <MenuItem value={item.dialCode} key={item.dialCode}>
              <Box className='con-code'>{item.code} </Box>{' '}
              <Box className='con-code-no'>{item.dialCode}</Box>
            </MenuItem>
          ))}
        </Select>

        <IMaskInput
          value={numberValue}
          inputRef={inputRef}
          unmask={true}
          mask='(000) 000 - 0000'
          onAccept={(value) => {
            onNumberChange(value as string);
          }}
        />
      </Box>
      {error && (
        <Typography color='red' className='error-fs'>
          {error}
        </Typography>
      )}
    </>
  );
};

export const CustomDateRangePicker = ({ startDate, endDate, onChange }: ICustomDateRangePicker) => {
  const [openRangeFilter, setOpenRangeFilter] = useState(false);

  const [state, setState] = useState([
    {
      startDate: moment(startDate).isValid() ? new Date(startDate) : new Date(),
      endDate: moment(endDate).isValid() ? new Date(endDate) : new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (openRangeFilter) {
      setState([
        {
          startDate: moment(startDate).isValid() ? new Date(startDate) : new Date(),
          endDate: moment(endDate).isValid() ? new Date(endDate) : new Date(),
          key: 'selection',
        },
      ]);
    }
  }, [openRangeFilter]);

  const openDateRangeFilter = () => {
    document.body.classList.add('pos-fixed');
    setOpenRangeFilter(true);
  };
  return (
    <>
      <input
        type='text'
        className='range'
        placeholder='Filter By Date'
        value={
          moment(startDate).isValid() && moment(endDate).isValid()
            ? `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`
            : ''
        }
        onClick={() => openDateRangeFilter()}
      />

      {openRangeFilter && (
        <>
          <Box className='date-range__overlay'></Box>
          <Box className='date-range__inner'>
            <DateRange
              editableDateInputs={true}
              onChange={(item: any) => {
                setState([item.selection]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
            <Box className='date-range__actions'>
              <Button
                onClick={() => {
                  onChange(state[0].startDate, state[0].endDate);
                  document.body.classList.remove('pos-fixed');
                  setOpenRangeFilter(false);
                }}
              >
                Ok
              </Button>
              <Button
                onClick={() => {
                  document.body.classList.remove('pos-fixed');
                  setOpenRangeFilter(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onChange('', '');
                  document.body.classList.remove('pos-fixed');
                  setOpenRangeFilter(false);
                }}
              >
                Clear
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
