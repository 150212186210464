import { FC, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, List } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { userRoutes } from '../../../routes';
import NavBarItem from './NavBarItem';
import { useLazyQuery } from '@apollo/client';
import { LOCK_SCREEN } from '../../../graphql/api/user';
import { useAuth } from '../../../hooks/useAuth';
import { ErrorContext } from '../../common/ErrorHandling';

interface IMenuNavLinks {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type navDataType = {
  exact?: boolean;
  path?: string;
  name?: string;
  isSideBar?: boolean;
  icon?: JSX.Element;
  role: string[];
  component?: React.LazyExoticComponent<
    ({ snackbarShowMessage, categories, seasonDates, renterData }: any) => JSX.Element
  >;
};

const MenuNavLinks: FC<IMenuNavLinks> = ({ open, setOpen }: IMenuNavLinks) => {
  const navigate = useNavigate();
  const { setIsLocked } = useContext(ErrorContext);
  const { pathname = '' } = useLocation();
  const { roleName } = useAuth();

  const sidebarRoutes: navDataType[] | [] = userRoutes
    ? userRoutes.filter((route: navDataType) => route.isSideBar && route.role.includes(roleName))
    : [];
  const [lockScreen] = useLazyQuery(LOCK_SCREEN);

  const unlockClick = () => {
    localStorage.setItem('lockFrom', pathname);

    lockScreen({
      onCompleted() {
        navigate('/unlock');
        setIsLocked(true);
        localStorage.setItem('isLocked', 'true');
      },
    });
  };

  const isSelectedTab = (tab: string): boolean => {
    const isMatched = pathname.includes(tab.toLowerCase());

    if (pathname !== '/' && tab === '/') return false;
    return isMatched;
  };

  return (
    <>
      <List className='sidebar-list'>
        {sidebarRoutes && sidebarRoutes.length
          ? sidebarRoutes.map((sidebarRoute) => {
              return (
                <NavBarItem
                  key={sidebarRoute.name}
                  icon={sidebarRoute.icon ?? <></>}
                  label={sidebarRoute.name ?? ''}
                  url={sidebarRoute.path ?? '/'}
                  open={open}
                  setOpen={setOpen}
                  isSelectedTab={isSelectedTab(sidebarRoute.path ?? '/')}
                  currentPath={pathname}
                />
              );
            })
          : null}
      </List>
      <Button
        type='button'
        sx={{ opacity: !open ? 1 : 0 }}
        className='lock-btn'
        onClick={() => unlockClick()}
      >
        <LockOutlinedIcon /> Lock
      </Button>
    </>
  );
};
export default MenuNavLinks;
