// const fieldToRender = [{'model'}, 'bindingModel', 'size', 'soleLength'];
const fieldToRender = [
  {
    label: 'Model',
    value: 'model',
  },
  {
    label: 'Binding Model',
    value: 'bindingModel',
  },
  {
    label: 'Size',
    value: 'size',
  },
  {
    label: 'Manufacture',
    value: 'manufacture',
  },
  {
    label: 'Binding Mft.',
    value: 'bindingManufacture',
  },
  {
    label: 'Sole Length',
    value: 'soleLength',
  },
  {
    label: 'Assigned By',
    value: 'assignedBy',
  },
];

interface IUpdatedData {
  field: {
    label: string;
    value: string;
  };
  value: string;
}
interface IBarcodeList {
  [keyof: string]: string;
}

export const barcodeList = (data: IBarcodeList) => {
  if (!data) return [];

  const temp: IUpdatedData[] = [];
  // loop over data object
  Object.entries(data).forEach(([key, value]) => {
    fieldToRender.forEach((field) => {
      if (field.value === key) {
        temp.push({
          field,
          value,
        });
      }
    });
  });

  return temp;
};
