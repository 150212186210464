import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import * as Images from '../Images';
import { useAuth } from '../../hooks/useAuth';
import { CssBaseline, Paper } from '@mui/material';

const InnerErrorPageContent = (isLoggedIn: boolean) => (
  <Grid container component='main' sx={{ height: '100vh' }} className='error-page-container'>
    <Grid item xs={12} sm={12} md={12}>
      <Box component='div' className='error-page'>
        <Box component='img' src={Images.errorIcon} alt='error' />
        <Box component='h1' className='error-page__heading'>
          404
        </Box>
        <Box component='div' className='error-page__sub-heading'>
          Page not found
        </Box>
        <Box component='p'>
          Oops! The page you are looking for does not exist. It might have been moved or deleted.
          Please click the below button to go back to the {isLoggedIn ? 'home' : 'login'} page.
        </Box>

        <Link to={'/'} className='error-page__link'>
          <ArrowBackIcon /> Go to {isLoggedIn ? 'home' : 'login'}
        </Link>
      </Box>
    </Grid>
  </Grid>
);

const ErrorPage = () => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) return InnerErrorPageContent(true);

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={false} sm={false} md={7} lg={8} className='reg-bg' />
      <Grid item xs={12} sm={12} md={5} lg={4} component={Paper} className='reg-wrap'>
        {InnerErrorPageContent(false)}
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
