export const formIsValid = (
  values: any,
): {
  hasNumber: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasSpecial: boolean;
  hasMinLength: boolean;
} => {
  const value = values?.target?.value;
  return {
    hasNumber: hasNumberCheck(value),
    hasUpperCase: hasUpperCase(value),
    hasLowerCase: hasLowerCase(value),
    hasSpecial: hasSpecial(value),
    hasMinLength: hasMinLength(value),
  };
};

const hasNumberCheck = (value: any): boolean => {
  // const result: boolean = value.match(/\d+/g) != null;
  return value.match(/\d+/g) != null;
};

const hasUpperCase = (value: any): boolean => {
  return value.match(/[A-Z]+/g) != null;
};
const hasLowerCase = (value: any): boolean => {
  return value.match(/[a-z]+/g) != null;
};

const hasSpecial = (value: any): boolean => {
  // eslint-disable-next-line
  const exp = new RegExp(/[\~\`\!\@\-\#\$\%\^\&\*]+/g); // Added *(asterisk) to allow it in password
  return value.match(exp) != null;
};
const hasMinLength = (value: any): boolean => {
  // eslint-disable-next-line
  const exp = new RegExp(/^.{10,}$/);
  return value.match(exp) != null;
};

export const toCamelCase = (str: string) => {
  let newStr = '';

  if (str) {
    const wordArr = str.split(/[-_]/g);
    for (const i in wordArr) {
      if (parseInt(i) > 0) {
        newStr += wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1);
      } else {
        newStr += wordArr[i];
      }
    }
  } else {
    return newStr;
  }
  return newStr;
};
