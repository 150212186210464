import { Grid, Box } from '@mui/material';

import { DateFormat, Messages } from '../../../enum/enumList';
import { IPackageAddons } from '../../../models/techStation';
import formatPrice from '../../../utils/formatPrice';
import { CustomCheckbox, CustomDatePicker } from '../../common/customFormControl';
import NoRecordFound from '../../common/noRecordFound/NoRecordFound';

interface IAddonsSingleCard {
  renterId: string;
  firstName: string;
  lastName: string;
  reservationStartDate: string;
  reservationEndDate: string;
  addonsList: IPackageAddons[];
  setAddonsList: (data: any) => void;
  isOnlyRenter?: boolean;
  reservationType: string;
}

const AddonsSingleCard = ({
  renterId,
  firstName,
  lastName,
  reservationStartDate,
  reservationEndDate,
  addonsList,
  setAddonsList,
  isOnlyRenter = false,
  reservationType,
}: IAddonsSingleCard) => {
  const handleAddonValueChange = (id: string, value: any) => {
    setAddonsList((prev: any) => {
      return prev.map((item: any) => {
        if (item.renterId === renterId) {
          return {
            ...item,
            addons: item.addons.map((addon: any) => {
              if (addon.id === id) {
                return (addon = { ...addon, ...value });
              }
              return addon;
            }),
          };
        }
        return item;
      });
    });
  };

  return (
    <Grid item xs={12} md={isOnlyRenter ? 6 : 12} sm={12}>
      <Box component='div' className='panel'>
        <Box component='div' className='panel__header'>
          <Grid item xs={12} md={7}>
            <strong className='text-uppercase'>{`${firstName || ''} ${lastName || ''}`}</strong>
          </Grid>
          {reservationType !== 'S' && (
            <Grid item xs={12} md={5}>
              {addonsList?.length ? 'First Day of Use' : ''}
            </Grid>
          )}
        </Box>

        {addonsList?.length ? (
          addonsList.map((addon) => (
            <Box key={addon?.id} component='div' className='panel__content-row'>
              <Grid item xs={12} md={7}>
                <CustomCheckbox
                  label={`${addon?.name || ''} (${formatPrice(addon?.price || 0)})`}
                  mainClassName=''
                  field={{
                    value: addon?.status === 'assigned',
                    onChange: (e) =>
                      handleAddonValueChange(addon?.id, {
                        status: e.target.checked ? 'assigned' : 'pending',
                        startDate: addon.startDate ?? new Date(),
                      }),
                  }}
                />
                {!!addon?.isSelected && (
                  <Box component='span' className='badge badge--addon-selected-badge'>
                    Selected
                  </Box>
                )}
              </Grid>
              {reservationType !== 'S' && (
                <Grid item xs={12} md={5}>
                  <CustomDatePicker
                    name='addon1StartList'
                    mainClassName='label label--width'
                    placeholder='MM/DD/YYYY'
                    dateFormat={DateFormat.MDY}
                    field={{
                      value: addon?.startDate || '',
                      onChange: (e) => handleAddonValueChange(addon?.id, { startDate: e }),
                    }}
                    minDate={new Date(reservationStartDate)}
                    maxDate={new Date(reservationEndDate)}
                    disabled={addon?.status !== 'assigned'}
                    disableManualInput
                  />
                </Grid>
              )}
            </Box>
          ))
        ) : (
          <NoRecordFound text={Messages.noAddonsfound} />
        )}
      </Box>
    </Grid>
  );
};

export default AddonsSingleCard;
