import * as Yup from 'yup';
import {
  applyDiscountOption,
  Messages,
  OnlyNumbersRegex,
  UptoTwoDecimalPlacesRegex,
} from '../../../../../enum/enumList';

export const applyDiscountValidation = (maxValue: number | null) => {
  return Yup.object().shape({
    discount: Yup.object().shape({
      type: Yup.string().required(Messages.required).typeError(Messages.required),
      value: Yup.number()
        .min(0, Messages.positiveNumber)
        .test('is max no discount', Messages.required, (value, context): boolean => {
          if (context.parent.type === applyDiscountOption.NoDiscount) {
            if (value !== 0) return false;
          }
          return true;
        })
        // .test(
        //   'is max fixed discount on existing discount',
        //   Messages.existingDiscount,
        //   (value, context): boolean => {
        //     if (
        //       context.parent.type === applyDiscountOption.FixedDiscount ||
        //       context.parent.type === applyDiscountOption.PercentageDiscount
        //     ) {
        //       if (value === 0) return true;
        //       if (isDiscountApplied > 0) return false;
        //     }
        //     return true;
        //   },
        // )
        // .test(
        //   'is max fixed discount',
        //   `${Messages.maxFixedDiscount} ($${maxValue || 0})`,
        //   (value, context): boolean => {
        //     if (context.parent.type === applyDiscountOption.FixedDiscount) {
        //       if (maxValue === null || value === undefined) return false;
        //       if (value > maxValue) return false;
        //     }
        //     return true;
        //   },
        // )
        .test('is max fixed discount', Messages.uptoTwoDecimalPlaces, (value, context): boolean => {
          if (context.parent.type === applyDiscountOption.FixedDiscount && value !== undefined) {
            if (UptoTwoDecimalPlacesRegex.test(value.toString()) === false) return false;
          }
          return true;
        })
        .test(
          'is max percentage discount',
          Messages.maxPercentageDiscount,
          (value, context): boolean => {
            if (context.parent.type === applyDiscountOption.PercentageDiscount) {
              if (value === undefined) return false;
              if (OnlyNumbersRegex.test(value.toString()) === false) return false;
              if (value > 100) return false;
            }
            return true;
          },
        )
        .test('field too long', Messages.fieldTooLong, (value): boolean => {
          if (value && value.toString().length > 10) return false;
          return true;
        })
        .typeError(Messages.required),
    }),
  });
};
