import { MenuItem, TableCell, TableRow } from '@mui/material';
import { Edit as EditIcon, ClearOutlined as ClearOutlinedIcon } from '@mui/icons-material';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { INotesTabRows } from '../../models/reservation';

interface IReservationNotesListItem {
  reservationRow: INotesTabRows;
  openModalNote: (noteId?: string) => void;
  openDeleteNoteModalHandler: (noteId: string) => void;
}

const ReservationNotesListItem = ({
  reservationRow,
  openModalNote,
  openDeleteNoteModalHandler,
}: IReservationNotesListItem) => {
  return (
    <TableRow key={reservationRow?.sno} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell scope='row'>{reservationRow?.sno}</TableCell>
      <TableCell scope='row'>{reservationRow?.byEmployeeName}</TableCell>
      <TableCell>{reservationRow?.description}</TableCell>
      <TableCell>{reservationRow?.createdAt}</TableCell>
      <TableCell align='right'>
        <CustomThreeDotsMenu labelId={reservationRow?.id}>
          <MenuItem onClick={() => openModalNote(reservationRow?.id)}>
            <EditIcon />
            Edit
          </MenuItem>
          <MenuItem onClick={() => openDeleteNoteModalHandler(reservationRow?.id)}>
            <ClearOutlinedIcon />
            Delete
          </MenuItem>
        </CustomThreeDotsMenu>
      </TableCell>
    </TableRow>
  );
};

export default ReservationNotesListItem;
