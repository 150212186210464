import { ICategories } from '../../../enum/enumList';
import { IRenterDetails, ISkierTypeOptions } from '../../../models/reservation';
import { AddEditRenter } from './AddEditRenterModal';
import RenterModalProvider from './RenterModalContext';

export interface IPrefillData {
    firstName: string;
    lastName: string;
}
interface IAddEditRenterWrapper {
    categories: ICategories[];
    modelOpen: boolean;
    setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateRenterData: (data: any) => void;
    details: any;
    skierTypeOptions: ISkierTypeOptions[] | [];
    editRenterDetails?: IRenterDetails;
    preFillData?: IPrefillData | null;
    isFromDetails?: boolean;
}

const AddEditRenterWrapper = ({
    modelOpen,
    setModelOpen,
    categories = [],
    updateRenterData,
    details,
    skierTypeOptions,
    editRenterDetails,
    preFillData,
    isFromDetails = false,
}: IAddEditRenterWrapper) => {
    return (
        <RenterModalProvider
            categories={categories}
            modelOpen={modelOpen}
            setModelOpen={setModelOpen}
            details={details}
            updateRenterData={updateRenterData}
            skierTypeOptions={skierTypeOptions}
            editRenterDetails={editRenterDetails}
            preFillData={preFillData}
            isFromDetails={isFromDetails}
        >
            <AddEditRenter />
        </RenterModalProvider>
    );
};

export default AddEditRenterWrapper;
