import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Button, Dialog, DialogContent, DialogActions } from '@mui/material';

import { ModalTitle } from '../../sidenav/modal/ModalHeader';
import { AddEditAddonModalValidator } from './validation/AddEditAddonModalValidation';
import { CustomInput } from '../../common/customFormControl';
import { ADD_DAILY_ADDONS, EDIT_DAILY_ADDONS } from '../../../graphql/api/addons';
import { useAuth } from '../../../hooks/useAuth';
import { IAddAddonForm, IAddAddonModalOpen } from '../../../models/addons';
import { AddonContext } from '../../../pages/addon/addonContext';
import { PackageEnum, Severity, SeasonLabel } from '../../../enum/enumList';
import SubmitButton from '../../common/button/SubmitButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IAddEditAddonModal {
  modelOpen: IAddAddonModalOpen;
  setModelOpen: Dispatch<SetStateAction<IAddAddonModalOpen>>;
}

const AddEditAddonModal = ({ modelOpen, setModelOpen }: IAddEditAddonModal) => {
  const { user } = useAuth();
  const { snackbarShowMessage, updateAddonList, isEditmodal, isSummerAddon } =
    useContext(AddonContext);
  const storeId = user?.selectedStore;

  const formInitalValue: IAddAddonForm = {
    title: modelOpen?.editModalData?.name ?? '',
    price: modelOpen?.editModalData?.price ?? null,
  };

  const [addAddon, { loading: addAddonsLoading }] = useMutation(ADD_DAILY_ADDONS);
  const [editAddon, { loading: editAddonsLoading }] = useMutation(EDIT_DAILY_ADDONS);

  const handleClose = () => {
    setModelOpen({
      isOpen: false,
      editModalData: null,
      addonType: 'Daily',
    });
    reset();
  };

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AddEditAddonModalValidator),
    defaultValues: formInitalValue,
  });

  const handleSave = (data: IAddAddonForm) => {
    const payloadData = {
      storeId,
      title: data.title,
      price: data.price,
      type: modelOpen.addonType === PackageEnum.D ? 'D' : 'S',
      season: isSummerAddon ? SeasonLabel.summer : SeasonLabel.winter,
    };

    if (isEditmodal) {
      editAddon({
        variables: {
          editAddonArgs: {
            id: modelOpen?.editModalData?.id,
            ...payloadData,
          },
        },
        onCompleted(data) {
          setTimeout(() => {
            if (snackbarShowMessage)
              snackbarShowMessage(data?.editAddon?.message, Severity.Success);
          }, 300);
          updateAddonList(data?.editAddon?.data?.length > 0 ? data.editAddon.data[0] : []);
          handleClose();
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error?.message, Severity.Error);
        },
      });
    } else {
      addAddon({
        variables: {
          addAddonArgs: {
            ...payloadData,
          },
        },
        onCompleted(data) {
          setTimeout(() => {
            if (snackbarShowMessage) snackbarShowMessage(data?.addAddon?.message, Severity.Success);
          }, 300);
          updateAddonList(data?.addAddon?.data?.length > 0 ? data.addAddon.data[0] : []);
          handleClose();
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error?.message, Severity.Error);
        },
      });
    }
  };

  useEffect(() => {
    reset(formInitalValue);
  }, [modelOpen]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='addon-title'
        fullWidth
        maxWidth='sm'
        open={modelOpen.isOpen}
        className='modal'
      >
        <ModalTitle id='addon-title' className='modal__heading' onClose={handleClose}>
          {isEditmodal ? 'Edit' : 'Add'} Addon {isSummerAddon ? '(Summer)' : '(Winter)'}
        </ModalTitle>
        <DialogContent dividers>
          <Grid
            container
            component='main'
            className='modal-lcol modal-lcol--border-none'
            spacing={4}
          >
            <Grid item xs={12} md={6} sm={6}>
              <Box component='div' className='modal-col__row'>
                <Controller
                  control={control}
                  name='title'
                  render={({ field, formState: { errors } }) => (
                    <CustomInput
                      field={field}
                      name='title'
                      label='Title'
                      mainClassName='row__label'
                      placeholder='Enter Title'
                      error={errors.title}
                      textInputProps={{ maxLength: 50 }}
                      required
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sm={6} className='pt-10'>
              <Box component='div' className='modal-col__row'>
                <Controller
                  control={control}
                  name='price'
                  render={({ field, formState: { errors } }) => (
                    <CustomInput
                      field={field}
                      name='price'
                      label='Price $'
                      mainClassName='row__label'
                      placeholder='Enter Price'
                      error={errors.price}
                      textInputProps={{ maxLength: 50 }}
                      type='number'
                      required
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='modal__footer'>
          <Box sx={{ position: 'relative' }}>
            <SubmitButton
              isLoading={addAddonsLoading || editAddonsLoading}
              onClick={handleSubmit(handleSave)}
            >
              Save
            </SubmitButton>
          </Box>
          <Button
            onClick={handleClose}
            className='cancel-btn'
            disabled={addAddonsLoading || editAddonsLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AddEditAddonModal;
