import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  TextareaAutosize,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';

import { ModalTitle } from '../../../sidenav/modal/ModalHeader';
import CustomSubmitButton from '../../../common/button/SubmitButton';
import { IAddNoteModal } from '../../../../models/reservation';
import { Messages, Severity } from '../../../../enum/enumList';
import { ADD_NOTE, UPDATE_NOTE } from '../../../../graphql/api/reservation';
import { ReservationContext } from '../../../../pages/reservation/reservationDetail/ReservationContext';
import { useAuth } from '../../../../hooks/useAuth';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AddNoteModal = ({ modelNoteOpen, handleClose, editModalData }: IAddNoteModal) => {
  const { snackbarShowMessage, setNotesTabData, general } = useContext(ReservationContext);
  const [noteValue, setNoteValue] = useState('');
  const [addNote, { loading: addNoteLoading }] = useMutation(ADD_NOTE);
  const [updateNote, { loading: updateNoteLoading }] = useMutation(UPDATE_NOTE);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const storeId = user?.selectedStore;

  useEffect(() => {
    setNoteValue(editModalData?.note ?? '');
  }, [modelNoteOpen]);

  const handleModalClose = () => {
    handleClose();
    setNoteValue('');
    setError('');
  };

  const checkValidation = () => {
    if (!noteValue.length) {
      setError(Messages.required);
      return true;
    }
    if (noteValue.length > 500) {
      setError(Messages.fieldTooLong);
      return true;
    }
    return null;
  };

  const handleSubmit = () => {
    const error = checkValidation();
    if (error) return;

    if (editModalData) {
      return updateNote({
        variables: {
          editNotesArgs: {
            id: editModalData?.id,
            note: noteValue,
            reservationId: general?.id,
            storeId,
          },
        },
        onCompleted() {
          // update the notes tab data
          setNotesTabData((prev) => {
            const index = prev.findIndex((item) => item.id === editModalData?.id);
            const newPrev = [...prev];
            newPrev[index] = {
              ...newPrev[index],
              note: noteValue,
            };
            return newPrev;
          });

          if (snackbarShowMessage)
            snackbarShowMessage('Note updated successfully', Severity.Success);
          handleModalClose();
        },
        onError(error) {
          if (snackbarShowMessage) snackbarShowMessage(error?.message || '', Severity.Error);
        },
      });
    }

    addNote({
      variables: {
        addNotesArgs: {
          note: noteValue,
          reservationId: general?.id,
          storeId,
        },
      },
      onCompleted(data) {
        setNotesTabData((prev) => [...(data?.addNote?.data || []), ...prev]);
        if (snackbarShowMessage)
          snackbarShowMessage('Note added successfully' || '', Severity.Success);
        handleModalClose();
      },
      onError(error) {
        if (snackbarShowMessage) snackbarShowMessage(error?.message || '', Severity.Error);
      },
    });
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setError('');
    setNoteValue(e.target.value);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby='add-card-title'
        fullWidth
        maxWidth='xs'
        open={modelNoteOpen}
        className='modal modal--card'
      >
        <ModalTitle id='add-card-title' className='modal__heading' onClose={handleModalClose}>
          Note
        </ModalTitle>
        <DialogContent dividers>
          <Grid
            container
            className='modal-lcol modal-lcol--border-none'
            component='main'
            spacing={3}
          >
            <Grid item xs={12} md={12} sm={12}>
              <Box component='div' className='modal-col__row'>
                <Box component='label' className='row__label'>
                  Notes <Box component='span'>*</Box>
                </Box>
                <TextareaAutosize
                  aria-label='Description'
                  minRows={4}
                  className='textarea'
                  value={noteValue}
                  onChange={handleTextAreaChange}
                />
                {error && <Typography color='red'>{error}</Typography>}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='modal__footer'>
          <Box sx={{ position: 'relative' }}>
            <CustomSubmitButton
              onClick={handleSubmit}
              isLoading={addNoteLoading || updateNoteLoading}
            >
              Save
            </CustomSubmitButton>
          </Box>
          <Button
            onClick={handleModalClose}
            className='cancel-btn'
            disabled={addNoteLoading || updateNoteLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AddNoteModal;
