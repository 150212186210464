import { Box, TableCell, TableRow } from '@mui/material';
import { formatDate } from '../../utils/formatDate';
import { capitalizeFirstLetter, setSessionStorage } from '../../utils/utilities';
import { useNavigate } from 'react-router-dom';
import { formatReservationId } from '../../utils/formatReservationId';

interface ICustomerDetailType {
  id: number;
  pickupDate: string;
  endDate: string;
  status: string;
  storeId: number;
}

const CustomerTableList = ({
  reservationRow,
}: {
  reservationRow: ICustomerDetailType;
  changeStatus: (id: string, status: string) => void;
  tabValue: number;
}) => {
  const navigate = useNavigate();
  const { id, pickupDate, endDate, status, storeId } = reservationRow;

  const reservationDetailClick = () => {
    setSessionStorage('reservationId', id ?? null);
    navigate('/reservation/reservation-detail');
  };

  return (
    <TableRow hover tabIndex={-1} key={reservationRow.id}>
      <TableCell className='cell-wrap'>
        <Box component='a' className='cell-wrap__text-ellipse' onClick={reservationDetailClick}>
          {formatReservationId(id, storeId)}
        </Box>
      </TableCell>
      <TableCell>{formatDate({ date: pickupDate })}</TableCell>
      <TableCell>{formatDate({ date: endDate })}</TableCell>
      <TableCell>{capitalizeFirstLetter(status)}</TableCell>
    </TableRow>
  );
};

export default CustomerTableList;
