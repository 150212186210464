import React, { FC, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, CircularProgress } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import './ConfirmationModal.scss';

interface Props {
  modelOpen: boolean;
  setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
  loading: boolean;
  selectedItem?: string;
  buttonText?: string;
  label?: string;
}

const DeleteModal: FC<Props> = ({
  modelOpen,
  setModelOpen,
  onDelete,
  loading,
  selectedItem,
  buttonText = 'Delete',
  label,
}: Props) => {
  const renderLabel = label ? label : `Do you really want to delete ${selectedItem}?`;

  const handleClose = () => {
    setModelOpen(false);
  };

  return (
    <Dialog open={modelOpen} onClose={handleClose} className='confirm-modal'>
      <DialogContent className='confirmation-box'>
        <WarningAmberIcon className='confirmation-box__circle' />

        <Box component='div' className='confirmation-box__content'>
          <h1>Are you Sure?</h1>
          <DialogContentText>
            {renderLabel}
            <br /> This process cannot be undone.
          </DialogContentText>
        </Box>
        <DialogActions className='confirmation-box__footer'>
          <Button
            onClick={onDelete}
            variant='contained'
            disabled={loading}
            className='footer__btn delete-btn'
          >
            {loading ? <CircularProgress size={24} /> : buttonText}
          </Button>
          <Button onClick={handleClose} disabled={loading} className='footer__btn cancel-btn'>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModal;
