import Logo from '../assets/logo.png';
import logoWhite from '../assets/logo-white.png';
import headerUser from '../assets/header-user.png';
import listIcon from '../assets/list.png';
import profilePic from '../assets/profile.png';
import demoSki from '../assets/demo-ski.png';
import sportsSki from '../assets/sports-ski.png';
import juniorSki from '../assets/junior-ski.png';
import deleteIc from '../assets/delete-ic.svg';
import unlockBg from '../assets/unlock-bg.jpg';
import deleteIcBlue from '../assets/delete-ic-blue.svg';
import skiPackages from '../assets/ski-packages.jpg';
import boardPackages from '../assets/board-packages.jpg';
import otherPackages from '../assets/other-packages.jpg';
import renterSportPackage from '../assets/sport-ski.jpg';
import renterDemoPackage from '../assets/demo-ski.jpg';
import renterBootsPackage from '../assets/boots-only.jpg';
import renterDemoSkiPackage from '../assets/demoski.jpg';
import renterSportsSkiPackage from '../assets/sportsski.jpg';
import errorIcon from '../assets/error.svg';
import snowboardPackage from '../assets/snowboard-package.jpg';
import snowShoesPackage from '../assets/snowshoe-package.jpg';
import skiPackage from '../assets/ski-package.jpg';
import kayak from '../assets/kayak.jpg';
import tube from '../assets/tube.jpg';
import paddleboard from '../assets/paddleboard.jpg';

const packagesImg = [skiPackage, snowboardPackage, snowShoesPackage, paddleboard, kayak, tube];

export {
  Logo,
  logoWhite,
  headerUser,
  listIcon,
  profilePic,
  demoSki,
  sportsSki,
  juniorSki,
  deleteIc,
  deleteIcBlue,
  unlockBg,
  skiPackages,
  boardPackages,
  otherPackages,
  renterSportPackage,
  renterDemoPackage,
  renterBootsPackage,
  renterDemoSkiPackage,
  renterSportsSkiPackage,
  errorIcon,
  packagesImg,
};
