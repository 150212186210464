import { Suspense, useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { Loader } from './components';
import { guestRoutes, userRoutes } from './routes';
import { useAuth } from './hooks/useAuth';
import GuestLayout from './components/layout/GuestLayout';
import UserRoute from './components/layout/UserRoute';
import ErrorPage from './components/common/ErrorPage';

import './pages/login/login.scss';
import { ISnackBar } from './models/common';
import { ErrorContext } from './components/common/ErrorHandling';

interface routeTypes {
  exact?: boolean;
  path: string;
  name: string;
  component?: React.LazyExoticComponent<
    (hocProps: { snackbarShowMessage?: ISnackBar | undefined }) => JSX.Element
  >;
  redirectRoute?: boolean;
  role?: string[];
}

export default function App() {
  const { isLoggedIn, roleName } = useAuth();
  const { isLocked } = useContext(ErrorContext);
  const routes = isLoggedIn ? userRoutes : guestRoutes;
  const { pathname } = useLocation();

  const mainContent = routes.map((route: routeTypes) => {
    return route.component ? (
      <Route
        path={route.path}
        element={
          route?.role?.length ? (
            route.role.includes(roleName) ? (
              <route.component />
            ) : (
              <ErrorPage />
            )
          ) : (
            <route.component />
          )
        }
        key={route.name}
      />
    ) : (
      <Route
        path='*'
        key={route.name}
        element={
          !isLoggedIn || isLocked ? (
            <Navigate to={isLocked ? '/unlock' : route.path} />
          ) : (
            <ErrorPage />
          )
        }
      />
    );
  });

  if (!isLoggedIn) {
    return (
      <GuestLayout>
        <Suspense fallback={<Loader />}>
          <Routes>{mainContent}</Routes>
        </Suspense>
      </GuestLayout>
    );
  }
  if (
    !pathname.includes('/return-details') &&
    !pathname.includes('/inventories/view-history') &&
    !pathname.includes('/customer-detail') &&
    !routes.filter((route) => route.path === pathname).length
  )
    return (
      <Suspense fallback={<Loader />}>
        <Routes>{mainContent}</Routes>
      </Suspense>
    );
  return (
    <UserRoute>
      <Suspense fallback={<Loader />}>
        <Routes>{mainContent}</Routes>
      </Suspense>
    </UserRoute>
  );
}
