import React, { ReactNode } from 'react';
import { paymentType } from '../../../../../enum/enumList';

interface ITransactionWrapper {
  children: ReactNode[];
  transactionType: keyof typeof paymentType;
}
const TransactionWrapper = ({ children, transactionType }: ITransactionWrapper) => {
  const newArray = [children[2], children[1], children[0]];

  if (!children) return <></>;

  if (transactionType === paymentType.charge) return <>{children}</>;

  return <>{newArray}</>;
};

export default TransactionWrapper;
