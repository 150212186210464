import { useMemo } from 'react';
import { DialogContent, Grid, Box } from '@mui/material';
import { CustomCheckbox } from '../../customFormControl';
import NoRecordFound from '../../noRecordFound/NoRecordFound';

import { IRenterDetails, ISelectedPackages } from '../../../../models/reservation';
import { Controller, Control } from 'react-hook-form';

interface IAddonsDetail {
  addonDetails: ISelectedPackages;
  selectedSubPackages: ISelectedPackages | null;
  control: Control<any, any>;
  renterDetails: IRenterDetails;
}

export const AddonsDetail = ({ addonDetails, selectedSubPackages, control }: IAddonsDetail) => {
  const addonsList = addonDetails;
  const isSubPackageSelected = useMemo(() => {
    if (selectedSubPackages) {
      return !(selectedSubPackages.id === addonDetails.id);
    }
    return true;
  }, [selectedSubPackages]);

  const selectedPackageId = selectedSubPackages?.id;
  const noOfAddons = [1, 2, 3, 4];

  return (
    <>
      <Box component='h3' className='block-heading block-heading--sm'>
        Addons
      </Box>
      {addonsList?.addon1Id ||
      addonsList?.addon2Id ||
      addonsList?.addon3Id ||
      addonsList?.addon4Id ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box component='div' className='panel'>
              {noOfAddons.map((i) => {
                const addonId = addonsList?.[`addon${i}Id` as keyof ISelectedPackages];
                const addonName = addonsList?.[`addon${i}Name` as keyof ISelectedPackages];
                const addonPrice = addonsList?.[`addon${i}Price` as keyof ISelectedPackages];

                if (addonId) {
                  return (
                    <Box key={i} component='div' className='panel__content-row'>
                      <Grid item xs={12} md={12}>
                        <Controller
                          control={control}
                          name={`addons${i}`}
                          render={({ field }) => (
                            <CustomCheckbox
                              label={`${addonName} ($${addonPrice ?? ''})`}
                              field={{
                                ...field,
                                value: selectedPackageId == addonDetails.id ? field.value : false,
                              }}
                              mainClassName=''
                              disabled={isSubPackageSelected}
                            />
                          )}
                        />
                      </Grid>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <NoRecordFound text='No addons found' />
      )}
    </>
  );
};
