import { gql } from '@apollo/client';

// export const GET_LOCATIONS = gql`
//   query GetIndex {
//     index
//   }
// `;

export const LOGIN_MUTATION = gql`
  mutation login($loginArgs: LoginArgs!) {
    login(loginArgs: $loginArgs)
  }
`;

export const GET_PASSWORD_RESET_LINK = gql`
  query ForgotPassword($email: String!, $isAdmin: Boolean) {
    forgotPassword(email: $email, isAdmin: $isAdmin)
  }
`;

export const VALIDATE_TOKEN = gql`
  query ValidateToken($token: String!) {
    validateToken(token: $token)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($resetPasswordArgs: ResetPasswordArgs!, $isAdmin: Boolean) {
    resetPassword(resetPasswordArgs: $resetPasswordArgs, isAdmin: $isAdmin)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($changePasswordArgs: ChangePasswordArgs!, $isAdmin: Boolean) {
    changePassword(changePasswordArgs: $changePasswordArgs, isAdmin: $isAdmin)
  }
`;

export const GET_PROFILE = gql`
  query GetProfile($isAdmin: Boolean) {
    getProfile(isAdmin: $isAdmin)
  }
`;

export const UPLOAD_PHOTO = gql`
  mutation UploadPhoto($upload: Upload!) {
    uploadPhoto(upload: $upload)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($employeeProfileArgs: EmployeeProfileArgs!) {
    updateProfile(employeeProfileArgs: $employeeProfileArgs) {
      firstName
      lastName
      phone
      unlockPin
      defaultStore
      numberCode
    }
  }
`;

export const DELETE_PROFILE_IMAGE = gql`
  query DeletePhoto {
    deletePhoto
  }
`;

export const LOGOUT = gql`
  query Logout {
    logout
  }
`;

export const GET_USER_LIST = gql`
  query GetUserList($searchText: String!) {
    getUserList(searchText: $searchText)
  }
`;

export const LOCK_SCREEN = gql`
  query lockScreen {
    lockScreen {
      success
      message
    }
  }
`;

export const UNLOCK_SCREEN = gql`
  query unlockScreen($pin: String!) {
    unlockScreen(pin: $pin) {
      success
      message
    }
  }
`;
