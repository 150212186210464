import * as Yup from 'yup';

import { Messages } from '../../../../enum/enumList';

export const AddEditAddonModalValidator = Yup.object().shape({
  title: Yup.string()
    .test('len', Messages.fieldTooLong, (val) => {
      if (val) return val?.length <= 100;
      return true;
    })
    .typeError(Messages.required)
    .required(Messages.required),
  price: Yup.number()
    .min(-10000, Messages.negaivePriceLength)
    .max(10000, Messages.priceLenth)
    .typeError(Messages.required),
});
