import { MenuItem, TableCell, TableRow } from '@mui/material';
import { Edit as EditIcon, ClearOutlined as ClearOutlinedIcon } from '@mui/icons-material';

import CustomThreeDotsMenu from '../customMenu/CustomThreeDotsMenu';
import { IAddonsListItem } from '../../models/addons';
import { snowboardBootAddonIds } from '../../enum/enumList';

const AddonsListItem = ({
  reservationRow,
  openEditModal,
  handleDeleteModalOpen,
}: IAddonsListItem) => (
  <TableRow hover tabIndex={-1} key={reservationRow.srNo}>
    <TableCell id={reservationRow.srNo} scope='reservationRow'>
      {reservationRow.srNo}
    </TableCell>
    <TableCell>{reservationRow.title}</TableCell>
    <TableCell>{reservationRow.price}</TableCell>
    <TableCell align='right'>
      <CustomThreeDotsMenu labelId={reservationRow?.srNo}>
        <MenuItem onClick={() => openEditModal(reservationRow?.id)}>
          <EditIcon />
          Edit
        </MenuItem>
        {!snowboardBootAddonIds.includes(reservationRow.id) ? (
          <MenuItem onClick={() => handleDeleteModalOpen(reservationRow?.id)}>
            <ClearOutlinedIcon />
            Delete
          </MenuItem>
        ) : null}
      </CustomThreeDotsMenu>
    </TableCell>
  </TableRow>
);

export default AddonsListItem;
