import CustomTable from '../../../common/table/CustomTableContainer';
import { equipmentTableConfig, addonTableConfig } from './tableConfig';
import { Box, Dialog, DialogContent, styled } from '@mui/material';
import RenterEquReturnListItem from '../../../reservation/RenterEquReturnListItem';
import RenterAddonReturnListItem from '../../../reservation/RenterAddonReturnListItem';
import { ModalTitle } from '../../../sidenav/modal/ModalHeader';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function RenterReturnEquipModal({ rentersEquipment, handleClose, isVisible }: any) {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='addon-title'
      fullWidth
      maxWidth='lg'
      open={isVisible}
      className='modal'
    >
      <ModalTitle id='addon-title' className='modal__heading' onClose={handleClose}>
        Renter Equipments & Addons
      </ModalTitle>
      <DialogContent dividers className='wrapper'>
        <Box className='wrapper__inner-content'>
          <Box component='div' className='inner-content__modal-subheading'>
            Equipments Detail
          </Box>
          <CustomTable
            className='inner-content__table-wrap'
            rows={rentersEquipment?.equipments}
            SingleRowComponent={RenterEquReturnListItem}
            headCells={equipmentTableConfig}
            isPagination={false}
            tableClassName='table-wrap__table table-wrap__table--addon'
          />
          <Box className='mt-3 pb-2'>
            <Box component='div' className='inner-content__modal-subheading'>
              Addons Detail
            </Box>
            <CustomTable
              className='inner-content__table-wrap'
              rows={rentersEquipment?.addons}
              SingleRowComponent={RenterAddonReturnListItem}
              headCells={addonTableConfig}
              isPagination={false}
              tableClassName='table-wrap__table table-wrap__table--addon'
            />
          </Box>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default RenterReturnEquipModal;
