import { useMemo } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { IPaymentDetailForm, IPaymentProfile } from '../../../models/reservation';
import CustomTable from '../../common/table/CustomTableContainer';
import ReservationPaymentListingItem from './ReservationPaymentListingItem';

interface IReservationPaymentTable {
  paymentProfiles: IPaymentProfile[];
  selectedCard: string | null;
  setSelectedCard: UseFormSetValue<IPaymentDetailForm>;
  showRadioButton?: boolean;
  setOpenDeleteModal: (id: string) => void;
}

const ReservationPaymentTable = ({
  paymentProfiles = [],
  selectedCard,
  setSelectedCard,
  showRadioButton,
  setOpenDeleteModal,
}: IReservationPaymentTable) => {
  const headCells = useMemo(
    () => [
      {
        id: 'cardHolder',
        numeric: false,
        label: 'Card Holder Name',
        isSortAvailable: false,
      },
      {
        id: 'cardNumber',
        numeric: false,
        label: 'Card Number',
        isSortAvailable: false,
      },
      {
        id: 'expiryDate',
        numeric: false,
        label: 'Expiry Date',
        isSortAvailable: false,
      },
      {
        id: 'cardType',
        numeric: false,
        label: 'Card Type',
        isSortAvailable: false,
      },
    ],
    [],
  );

  return (
    <>
      <CustomTable
        className='inner-content__table-wrap'
        tableClassName='table-wrap__table '
        rows={paymentProfiles.map((item) => ({
          ...item,
          id: item?.customerPaymentProfileId,
        }))}
        headCells={headCells}
        SingleRowComponent={ReservationPaymentListingItem}
        isLoading={false}
        tableItemProps={{
          selectedCard,
          setSelectedCard,
          showRadioButton,
          setOpenDeleteModal,
        }}
        isPagination={false}
        isActionRow
      />
    </>
  );
};

export default ReservationPaymentTable;
