import moment from 'moment';
import { Box, TableCell, TableRow } from '@mui/material';
import { TextSnippetOutlined as TextSnippetOutlinedIcon } from '@mui/icons-material';
import { equipmentReturn } from '../../enum/enumList';
import { IInvertoryType } from '../../models/inventory';
import { IAddon } from '../../models/techStation';

interface IReservationReturnListItem {
  reservationRow: any;
  handleReturnsDetailsClick: () => void;
  openReturnDetailModal: (arg: { equipments: IInvertoryType[]; addons: IAddon[] }) => void;
  startDate: string;
  endDate: string;
}

const ReservationReturnListItem = ({
  reservationRow: reservationReturnData,
  handleReturnsDetailsClick,
  openReturnDetailModal,
  startDate,
  endDate,
}: IReservationReturnListItem) => (
  <TableRow
    key={reservationReturnData?.id}
    className={
      !(reservationReturnData?.isReturned === equipmentReturn.yes) &&
      moment(reservationReturnData?.endDate).isBefore(new Date())
        ? 'item-retured-no'
        : ''
    }
  >
    <TableCell
      scope='row'
      className='cell-wrap'
      onClick={() =>
        openReturnDetailModal({
          equipments: reservationReturnData?.equipments,
          addons: reservationReturnData?.addons,
        })
      }
    >
      <Box component='a' className='return-equip-detail-link cell-wrap__text-ellipse'>
        {reservationReturnData?.renterName || 'N/A'}
      </Box>
    </TableCell>
    <TableCell>{startDate}</TableCell>
    <TableCell>{endDate}</TableCell>
    <TableCell>
      <Box
        component='span'
        className={`badge text-capitalize ${
          reservationReturnData?.status === 'no'
            ? 'badge--red'
            : reservationReturnData?.status === 'partially'
            ? 'badge--blue'
            : 'badge--green'
        } text-capitalize`}
      >
        {reservationReturnData?.status}
      </Box>
    </TableCell>
    <TableCell>
      <Box component='span' className='link-btn' onClick={handleReturnsDetailsClick}>
        <TextSnippetOutlinedIcon /> details
      </Box>
    </TableCell>
  </TableRow>
);

export default ReservationReturnListItem;
