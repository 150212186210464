import { useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { GET_PROFILE } from '../../graphql/api/user';
import { useAuth } from '../../hooks/useAuth';

import { Box, CssBaseline } from '@mui/material';
import Header from '../header/Header';
import { DrawerOuter } from '../Drawer';
import { Severity, storeIds } from '../../enum/enumList';
import Loader from '../loader';
import useMediaQuery from '../../hooks/useMediaQuery';
import { CustomSnackbar } from '../../hoc/CustomSnackbar';
import { ISnackBar, IStores } from '../../models/common';

import '../../pages/custom.scss';

interface IUserRoute {
  children: JSX.Element;
  snackbarShowMessage?: ISnackBar;
}

const UserRoute = ({ children, snackbarShowMessage }: IUserRoute) => {
  const { logout, updateUser, logoutLoading, user, setCurrentSeason } = useAuth();
  const [getProfile, { error, data: profileData }] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
  });

  const matches = useMediaQuery('(max-width: 1200px)');
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const handleDrawerClose = () => {
    setOpen(true);
  };
  const handleDrawerOpen = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!profileData) {
      getProfile({
        variables: {
          isAdmin: true,
        },
      });
    }
    const localSelectedStore = parseInt(JSON.parse(`${localStorage.getItem('store')}`));

    const { getProfile: profileInfo = {} } = profileData || {};
    const { data = {} } = profileInfo || {};

    if (profileData && data) {
      if (data?.profile?.roleName === 'user') {
        logout();
      } else {
        const currentStoreSeason = data?.stores?.filter((item: IStores) => {
          const { id } = item;
          return localSelectedStore
            ? id == localSelectedStore
            : id == parseInt(data?.profile?.defaultStore);
        });
        updateUser({
          ...profileData?.getProfile?.data,
          selectedStore:
            localSelectedStore && storeIds.includes(localSelectedStore)
              ? localSelectedStore
              : profileData?.getProfile?.data?.profile?.defaultStore,
        });
        setCurrentSeason(currentStoreSeason[0]?.currentSeason);
        if (!(localSelectedStore && storeIds.includes(localSelectedStore))) {
          localStorage.setItem('store', profileData?.getProfile?.data?.profile?.defaultStore);
        }
      }
    }
  }, [profileData]);

  useEffect(() => {
    setOpen(matches);
  }, [matches]);

  useEffect(() => {
    const localSelectedStore = parseInt(JSON.parse(`${localStorage.getItem('store')}`));

    if (
      user?.selectedStore &&
      localSelectedStore !== user?.selectedStore &&
      snackbarShowMessage &&
      user?.stores !== undefined
    ) {
      localStorage.setItem('store', user?.selectedStore.toString());
      snackbarShowMessage(
        `Currently selected store is ${user?.stores[user?.selectedStore - 1]?.name}`,
        Severity.Success,
        2000,
      );
    }
  }, [user?.selectedStore]);

  if (error) logout();
  if (logoutLoading || !(user?.selectedStore && user?.profile && user?.stores)) return <Loader />;
  if (pathname === '/error' || pathname === '/unlock') return <>{children}</>;

  return (
    <Box className={open ? 'd-flex' : 'overlay-outer'}>
      <CssBaseline />
      <Box className='overlay'></Box>
      <Header open={open} onClick={() => handleDrawerOpen()} />
      <DrawerOuter open={open} onClick={() => handleDrawerClose()} setOpen={setOpen} />

      {children}
      <Box component='p' className='copyright-footer'>
        ©{moment().format('YYYY')} by Bluebird Mountain Sports. All rights reserved.
      </Box>
    </Box>
  );
};

export default CustomSnackbar(UserRoute);
