const formatPrice = (price: number) => {
  // change price to two decimal with round of figure
  const newPrice = Math.round(price * 100) / 100;

  // Add -sign to price if price is negative before $ sign
  const priceSign = newPrice < 0 ? '-' : '';

  // Add $ sign to price
  const priceWithSign = priceSign + '$' + Math.abs(newPrice).toFixed(2);
  return priceWithSign;
};
export default formatPrice;

export const roundedPrice = (price: number | null | undefined) =>
  price ? Math.abs(Math.round(price * 100) / 100).toFixed(2) : '';
