import * as Yup from 'yup';

import { EmailRegex, Messages } from './../../../../../enum/enumList';

const noteValidation = Yup.string()
  .test('is more than 500', Messages.fieldTooLong500, (value) => {
    if (value && value.length > 500) return false;
    return true;
  })
  .required(Messages.required);

export const communicationCallNoteValidator = Yup.object().shape({
  numberCode: Yup.string().typeError(Messages.required).required(Messages.required),
  phone: Yup.string()
    .test('less than 10 digit', Messages.phoneNumberErr, (value) => !(value && value.length < 10))
    .required(Messages.required),
  message: noteValidation,
});
export const communicationEmailNoteValidator = Yup.object().shape({
  email: Yup.string().required(Messages.required).trim().matches(EmailRegex, Messages.emailErr),
  subject: Yup.string().required(Messages.required),
  message: noteValidation,
});
