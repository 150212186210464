import { Dispatch, SetStateAction, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import {
  Box,
  Typography,
  Button,
  Grid,
  Tab,
  Tabs,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';

import { ModalTitle } from '../../../sidenav/modal/ModalHeader';
import GeneralTab from './tabs/GeneralTab';
import EquipmentsTab from './tabs/EquipmentsTab';
import AddonsTab from './tabs/AddonsTab';
import PricingTab from './tabs/PricingTab';
import Loader from '../../../loader';
import { reservationStatus } from '../../../../enum/enumList';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IViewRenterDetail {
  modelViewRenterOpen: boolean;
  setViewRenterOpen: Dispatch<SetStateAction<boolean>>;
  rentersArrayProp: any[];
  seletedRenterId: string;
  reservationData?: any;
  setSelectedRenterId: Dispatch<SetStateAction<string>>;
  openEditRenterModal: (renterData: any) => void;
}

const ViewRenterDetail = ({
  modelViewRenterOpen,
  setViewRenterOpen,
  rentersArrayProp,
  seletedRenterId,
  reservationData,
  setSelectedRenterId,
  openEditRenterModal,
}: IViewRenterDetail) => {
  const handleClose = () => {
    setViewRenterOpen(false);
  };
  const [selectedRenterDetail, setSelectedRenterDetail] = useState<any>({} as any);

  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleRenterEdit = () => {
    openEditRenterModal(selectedRenterDetail);
    handleClose();
  };

  const handlePreviousRenter = () => {
    setLoading(true);
    setTimeout(() => {
      const selectedRenterIndex = rentersArrayProp.findIndex(
        (renter) => renter.id === seletedRenterId,
      );
      if (selectedRenterIndex > 0) {
        setSelectedRenterId(rentersArrayProp[selectedRenterIndex - 1].id);
        setTabValue(0);
      } else {
        setSelectedRenterId(rentersArrayProp[rentersArrayProp.length - 1].id);
        setTabValue(0);
      }
      setLoading(false);
    }, 500);
  };

  const handleNextRenter = () => {
    setLoading(true);
    setTimeout(() => {
      const selectedRenterIndex = rentersArrayProp.findIndex(
        (renter) => renter.id === seletedRenterId,
      );
      if (selectedRenterIndex < rentersArrayProp.length - 1) {
        setSelectedRenterId(rentersArrayProp[selectedRenterIndex + 1].id);
        setTabValue(0);
      } else {
        setSelectedRenterId(rentersArrayProp[0].id);
        setTabValue(0);
      }
      setLoading(false);
    }, 500);
  };

  const TabPanel = useCallback((props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`packages-tabpanel-${index}`}
        aria-labelledby={`packages-tabpanel-${index}`}
        {...other}
        className='tabs-panel'
      >
        {value === index && (
          <Box sx={{ p: 3 }} className='tabs-panel__inner'>
            <Typography component='div'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }, []);

  const a11yProps = useCallback((index: number) => {
    return {
      id: `packages-tabpanel-${index}`,
      'aria-controls': `packages-tabpanel-${index}`,
    };
  }, []);

  const BootstrapDialog = useCallback(
    styled(Dialog)(({ theme }) => ({
      '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
    })),
    [],
  );

  useEffect(() => {
    const selectedRenter = rentersArrayProp.filter((renter) => renter.id === seletedRenterId);
    if (selectedRenter.length > 0) {
      setSelectedRenterDetail({ ...selectedRenter[0], reservationData: { ...reservationData } });
    } else {
      setSelectedRenterDetail({ ...rentersArrayProp[0], reservationData: { ...reservationData } });
    }
  }, [rentersArrayProp, seletedRenterId]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='addon-title'
      fullWidth
      maxWidth='md'
      open={modelViewRenterOpen}
      className='modal'
    >
      <ModalTitle id='addon-title' className='modal__heading' onClose={handleClose}>
        Renter Detail
      </ModalTitle>
      <DialogContent dividers className='wrapper'>
        <Grid container className='wrapper__inner-content wrapper__inner-content--tabs-padding-sm'>
          <Box className='tab-header tab-header--more-tabs'>
            <Tabs value={tabValue} onChange={handleChange} aria-label='packages tab'>
              <Tab label='General' {...a11yProps(0)} />
              <Tab label='Equipments' {...a11yProps(1)} />
              <Tab label='Addons' {...a11yProps(2)} />
              <Tab label='Pricing' {...a11yProps(3)} />
            </Tabs>
          </Box>
          {loading ? (
            <Loader
              style={{
                display: 'flex',
                minHeight: '470px',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          ) : (
            <>
              <TabPanel value={tabValue} index={0}>
                <GeneralTab renterDetail={selectedRenterDetail} />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <EquipmentsTab renterDetail={selectedRenterDetail} />
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <AddonsTab renterDetail={selectedRenterDetail} />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <PricingTab renterDetail={selectedRenterDetail} />
              </TabPanel>
            </>
          )}
        </Grid>
      </DialogContent>
      {/* footer */}
      <DialogActions className='modal__footer modal__footer--flex'>
        {reservationData?.status === reservationStatus.processing ||
        reservationData?.status === reservationStatus.pending ? (
          <Button className='primary-btn' onClick={handleRenterEdit} disabled={loading}>
            Edit
          </Button>
        ) : (
          <div></div>
        )}

        {rentersArrayProp?.length > 1 && (
          <Box className='modal__footer'>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button
                className='back-btn'
                sx={{ mr: 2 }}
                onClick={handlePreviousRenter}
                disabled={loading}
              >
                Previous
              </Button>
              <Button className='primary-btn' onClick={handleNextRenter} disabled={loading}>
                Next
              </Button>
            </Box>
          </Box>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ViewRenterDetail;
