import { Box, TableCell, TableRow } from '@mui/material';
import { formatDate, formatDateTimeAccTimezone } from '../../utils/formatDate';

interface IRenterEquReturnListItem {
  reservationRow: any;
}

const RenterEquReturnListItem = ({ reservationRow: listItem }: IRenterEquReturnListItem) => {
  return (
    <TableRow key={listItem?.id}>
      <TableCell scope='row'>{listItem?.barcode1 || 'N/A'}</TableCell>
      <TableCell scope='row'>{listItem?.model || 'N/A'}</TableCell>
      <TableCell scope='row'>{listItem?.size || 'N/A'}</TableCell>
      <TableCell scope='row'>{listItem?.manufacture || 'N/A'}</TableCell>
      <TableCell scope='row' className='equip-assign-date'>
        {formatDate({ date: listItem?.startDate }) || 'N/A'}
      </TableCell>
      <TableCell scope='row' className='equip-assign-date'>
        {formatDate({ date: listItem?.endDate }) || 'N/A'}
      </TableCell>
      <TableCell scope='row' className='equip-assign-date'>
        {formatDateTimeAccTimezone(listItem?.assignedOn) || 'N/A'}
      </TableCell>
      <TableCell scope='row'>{listItem?.assignedBy || 'N/A'}</TableCell>
      <TableCell>
        <Box
          component='span'
          className={`badge text-capitalize ${
            listItem?.isReturned ? 'badge--green' : 'badge--red'
          } text-capitalize`}
        >
          {listItem?.isReturned ? 'Yes' : 'No'}
        </Box>
      </TableCell>
      <TableCell scope='row'>{formatDateTimeAccTimezone(listItem?.returnAt) || 'N/A'}</TableCell>
    </TableRow>
  );
};

export default RenterEquReturnListItem;
