import React, { useState, FC, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Logout from '@mui/icons-material/Logout';

import { AppBar } from '../sidenav/styles/sidebar';
import ChangePassword from '../../pages/change-password/ChangePassword';
import { useAuth } from '../../hooks/useAuth';
import { DEFAULT_PROFILE_PIC } from '../../assets';
import { ISingleStore } from '../../models/user';
import { useLazyQuery } from '@apollo/client';
import { LOGOUT } from '../../graphql/api/user';
import { CustomSnackbar } from '../../hoc/CustomSnackbar';
import { ISnackBar } from '../../models/common';
import { Severity } from '../../enum/enumList';
import CustomMenu from '../customMenu/CustomMenu';

interface Props {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  handleDrawerOpen?: false;
  snackbarShowMessage?: ISnackBar;
}

const Header: FC<Props> = ({ onClick, open, snackbarShowMessage }: Props) => {
  const { logout, user, updateUser, setCurrentSeason, setforceUpdate } = useAuth();
  const { profile = {} } = user || {};
  const { pathname } = useLocation();
  const stores = user?.stores;
  const anchorEl = useRef(null);

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const [signOut, { error, data }] = useLazyQuery(LOGOUT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      logout();
      navigate('/');
      localStorage.clear();
      sessionStorage.clear();
      document.cookie = 'isLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    },
  });

  const handleClick = () => {
    setOpenMenu(true);
  };
  const handleClose = () => {
    setOpenMenu(false);
  };
  const [modelOpen, setModelOpen] = React.useState<boolean>(false);
  const modalOpen = () => {
    setOpenMenu(false);
    setModelOpen(true);
  };
  const profileLinkClick = () => {
    setOpenMenu(false);
    navigate('/profile');
  };
  const handleLogout = () => {
    signOut();
  };
  const handleMenuItemClick = ({ storeId, season }: { storeId: number; season: string }) => {
    if (user?.stores && user?.stores?.length && snackbarShowMessage)
      snackbarShowMessage(
        `Currently selected store is ${user?.stores[storeId - 1]?.name}`,
        Severity.Success,
        2000,
      );
    localStorage.setItem('store', storeId.toString());
    updateUser({ selectedStore: storeId });
    setCurrentSeason(season);
    setforceUpdate((prevState) => !prevState);
    if (
      !(
        pathname === '/' ||
        pathname === '/reservations' ||
        pathname === '/return' ||
        pathname === '/packages' ||
        pathname === '/addons' ||
        pathname === '/inventories' ||
        pathname === '/employees' ||
        pathname === '/customers' ||
        pathname === '/inventory-reports' ||
        pathname === '/admin-settings' ||
        pathname === '/cms-settings'
      )
    )
      navigate('/');
    handleClose();
  };

  const renderStoreName = useCallback(() => {
    return stores?.filter((item) => item.id === user?.selectedStore)[0].name;
  }, [user?.selectedStore]);

  return (
    <AppBar
      position='fixed'
      open={!open}
      className={open ? 'header' : 'header header--sidebar-opened'}
    >
      <Toolbar
        className={open ? 'header__head-inner' : 'header__head-inner header__head-inner--flex-end'}
      >
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={onClick}
          edge='start'
          sx={{
            marginRight: 5,
            ...(!open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box component='div' className='head-inner__r-col'>
          <IconButton
            onClick={handleClick}
            ref={anchorEl}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            className='r-col__prf-wrap'
          >
            <Box className='prf-wrap__im'>
              {user?.profile?.photo ? (
                <Box
                  component='img'
                  alt='User'
                  src={user?.profile?.photo}
                  width={40}
                  height={40}
                  style={{ objectFit: 'cover' }}
                ></Box>
              ) : (
                <Box className='prf-im__wrap'>
                  <PersonIcon className='default-im' sx={{ fontSize: '30px' }} />
                </Box>
              )}
            </Box>
            <Box component='div' className='prf-wrap__name'>
              <Box className='name-user'> {profile?.firstName ?? ''}</Box>
              <Box className='name-store'>
                <StoreMallDirectoryIcon fontSize='medium' style={{ marginRight: '6px' }} />
                {renderStoreName()}
              </Box>
            </Box>
          </IconButton>
          {openMenu && (
            <CustomMenu
              anchorEl={anchorEl.current}
              menuOpen={openMenu}
              handleClose={handleClose}
              className='profile-menu'
            >
              <Box component='h5'>Store Information</Box>
              {stores &&
                stores.map((store: ISingleStore) => (
                  <MenuItem
                    key={store.id}
                    className={user.selectedStore === store.id ? 'active' : ''}
                    onClick={() =>
                      handleMenuItemClick({
                        storeId: store.id,
                        season: store.currentSeason || '',
                      })
                    }
                  >
                    <ListItemIcon>
                      {user?.selectedStore === store.id ? (
                        <DoneAllIcon fontSize='small' />
                      ) : (
                        <StoreMallDirectoryIcon fontSize='small' />
                      )}
                    </ListItemIcon>
                    {store.name}
                  </MenuItem>
                ))}

              <Box component='h5' className='spacer-2'>
                Other Links
              </Box>
              <MenuItem onClick={profileLinkClick}>
                <ListItemIcon>
                  <PersonIcon fontSize='small' />
                </ListItemIcon>
                Profile
              </MenuItem>
              <MenuItem onClick={() => modalOpen()}>
                <ListItemIcon>
                  <LockIcon fontSize='small' />
                </ListItemIcon>
                Change Password
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                Logout
              </MenuItem>
            </CustomMenu>
          )}
        </Box>
      </Toolbar>
      <ChangePassword modelOpen={modelOpen} setModelOpen={setModelOpen} />
    </AppBar>
  );
};

export default CustomSnackbar(Header);
