import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { LOGOUT } from '../graphql/api/user';
import { IUserProfile } from '../models/user';

import { ErrorContext } from '../components/common/ErrorHandling';
import { useNavigate } from 'react-router-dom';

interface IAuthContext {
  user: IUserProfile | null;
  isLoggedIn: boolean;
  login: (rememberMe: boolean) => void;
  logout: () => void;
  updateUser: (data: IUserProfile) => void;
  logoutLoading: boolean;
  roleName: string;
  currentSeason: string | null;
  setCurrentSeason: React.Dispatch<React.SetStateAction<null | string>>;
  forceUpdate: boolean;
  setforceUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  updateUser: (data: IUserProfile) => {},
  logoutLoading: false,
  roleName: '',
  currentSeason: null,
  setCurrentSeason: () => {},
  forceUpdate: false,
  setforceUpdate: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { isError, setError } = useContext(ErrorContext);

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const isLoggedIn =
      window.localStorage.getItem('isLoggedIn') || document.cookie.includes('isLoggedIn');
    if (isLoggedIn) return true;
    return false;
  });

  const [user, setUser] = useState<IUserProfile | null>(null);

  const [currentSeason, setCurrentSeason] = useState<string | null>(null);

  const [forceUpdate, setforceUpdate] = useState(false);

  const { profile: { roleName = '' } = {} } = user || {};

  const [signOut, { error, loading: logoutLoading }] = useLazyQuery(LOGOUT, {
    fetchPolicy: 'network-only',
  });

  const updateUser = (data: IUserProfile) => {
    setUser((prev) => ({ ...prev, ...data }));
  };

  const login = (rememberMe: boolean) => {
    if (rememberMe) {
      //  remove cookies
      document.cookie = 'isLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      window.localStorage.setItem('isLoggedIn', 'true');
    } else {
      window.localStorage.clear();
      // add isLoogin in to cookies
      document.cookie = 'isLoggedIn=true';
    }
    setIsLoggedIn(true);
  };

  // call this function to sign out logged in user
  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = 'isLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    navigate('/');
  };

  useEffect(() => {
    switch (isError) {
      case 'UNAUTHENTICATED':
        logout();
        setError('');

        break;
      default:
        break;
    }
  }, [isError]);

  const value = useMemo(
    () => ({
      user,
      isLoggedIn,
      login,
      logout,
      updateUser,
      logoutLoading,
      roleName,
      currentSeason,
      setCurrentSeason,
      forceUpdate,
      setforceUpdate,
    }),
    [user, isLoggedIn, logoutLoading, currentSeason, forceUpdate],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
