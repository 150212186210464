/* eslint-disable camelcase */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { LocationOn as LocationOnIcon, Update as UpdateIcon } from '@mui/icons-material';

import { DrawerHeader } from '../../../../../components/sidenav/styles/sidebar';
import { GooglePlacesType, PlaceType } from '../../../../../models/reservation';
import useDebounce from '../../../../../hooks/useDebounce';
import useScript from '../../../../../hooks/useScript';
import { GooglePlacesAPI } from '../../../../../services/scripts';
import { ICountryList, IStateList } from '../../../addReservation/contactInfo/ContactInfo';
import { EditRenterInfoValidation } from '../../../validation/reservationValidations';
import { createdFrom, DateFormat, scriptLoadStatus, Severity } from '../../../../../enum/enumList';
import { parse } from '../../../../../utils/utilities';
import { ReservationContext } from '../../ReservationContext';
import {
  GET_COUNTRIES_LIST,
  UPDATE_RENTER_INFORMATION,
} from '../../../../../graphql/api/reservation';
import {
  CustomCheckbox,
  CustomDatePicker,
  CustomInput,
  CustomNumberInput,
  CustomPhoneInput,
  CustomSelect,
  Loader,
} from '../../../../../components';
import { useAuth } from '../../../../../hooks/useAuth';
import moment from 'moment';

interface IForm {
  firstName: string;
  lastName: string;
  email: string;
  numberCode: string;
  phone: string;
  address: string;
  address2: string;
  country: string;
  state: string;
  city: string;
  zip: string;
  reservationStartDate: string;
  reservationEndDate: string;
  pickUpDateBefore: boolean;
}

const RentalInformation = () => {
  const navigate = useNavigate();
  const {
    general: reservationData,
    snackbarShowMessage,
    reservationData: reservationInfo,
  } = useContext(ReservationContext);
  const settings = reservationInfo?.settings?.length ? reservationInfo?.settings[0] : {};
  const blockDates = reservationInfo?.blockDates?.length ? reservationInfo?.blockDates : [];

  const { user } = useAuth();
  const storeId = user?.selectedStore;

  const [searchAddress, setAddress] = useState('');
  const placesAPIRef = useRef<any>(null);
  const [selectedAddress, setSelectedAddress] = useState<PlaceType | string | null>({
    description: reservationData?.address ?? '',
    structured_formatting: {
      main_text: '',
      secondary_text: '',
      main_text_matched_substrings: [{ offset: 0, length: 0 }],
    },
  });
  const [addressOptions, setAddressOptions] = useState<readonly PlaceType[]>([]);

  const debouncedAddress = useDebounce<string>(searchAddress, 500);
  const autoCompleteRef = useRef<HTMLElement>(document.createElement('div'));
  const scriptStatus = useScript(GooglePlacesAPI, document.querySelector('head'));

  const [updateRenterInformation, { loading: updateRenterLoading }] =
    useMutation(UPDATE_RENTER_INFORMATION);
  const [getCountry, { data: countryList, loading: countryListLoading }] =
    useLazyQuery(GET_COUNTRIES_LIST);

  const CountryMenuList = useMemo(() => {
    const countryArray: ICountryList[] | [] =
      countryList?.getContactProperties?.data?.countries || [];
    return countryArray.map((item) => ({
      value: item.countryCode,
      label: item.countryName,
    }));
  }, [countryList]);

  const StateMenuList = useMemo(() => {
    const countryArray: IStateList[] | [] = countryList?.getContactProperties?.data?.states || [];
    return countryArray.map((item) => ({
      value: item.code,
      label: item.name,
    }));
  }, [countryList]);

  const formInitalValue: IForm = {
    firstName: reservationData?.firstName ?? '',
    lastName: reservationData?.lastName ?? '',
    email: reservationData?.email ?? '',
    numberCode: reservationData?.numberCode ?? '+1',
    phone: reservationData?.phone ?? '',
    address: reservationData?.address ?? '',
    address2: reservationData?.address2 ?? '',
    country: reservationData?.countryId ?? '',
    state: reservationData?.stateId ?? reservationData?.state ?? '',
    city: reservationData?.city ?? '',
    zip: reservationData?.zip ?? '',
    reservationStartDate: reservationData?.startDate || null,
    reservationEndDate: reservationData?.endDate || null,
    pickUpDateBefore: reservationData?.isPickupDayBefore ? true : false,
  };
  const {
    control,
    handleSubmit,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditRenterInfoValidation),
    defaultValues: formInitalValue,
  });

  const watchCountry = watch('country');
  const reservationStartDate = watch('reservationStartDate');
  const countryRef = useRef('');

  useEffect(() => {
    if (scriptStatus === scriptLoadStatus.Ready) {
      placesAPIRef.current = new (window as any).google.maps.places.AutocompleteService();
    }
  }, [scriptStatus]);

  useEffect(() => {
    getCountry();
  }, []);

  // Reset state form value when country change
  useEffect(() => {
    resetField('state');
  }, [watchCountry]);

  // For Goople Places List Generation
  useEffect(() => {
    if (debouncedAddress) {
      if (placesAPIRef.current) {
        placesAPIRef.current.getPlacePredictions(
          { input: debouncedAddress },
          (results: readonly PlaceType[]) => {
            let newOptions: readonly PlaceType[] = [];
            if (typeof searchAddress === 'object' && selectedAddress) {
              newOptions = [selectedAddress as PlaceType];
            }
            if (results) {
              newOptions = [...newOptions, ...results];
            }
            setAddressOptions(newOptions);
          },
        );
      }
    }
  }, [debouncedAddress]);

  // Set the selected place from the list
  const setAddressValues = (data: PlaceType) => {
    const { place_id = '' } = data || {};
    if ((window as any).google) {
      // PlaceService for fetching details of selected place
      const PlacesService = new (window as any).google.maps.places.PlacesService(
        autoCompleteRef.current,
      );
      try {
        PlacesService.getDetails(
          {
            placeId: place_id,
            fields: ['formatted_address', 'address_components'],
          },
          (place: GooglePlacesType) => {
            const { address_components = [] } = place || {};
            // setValue('address2', formatted_address);
            address_components &&
              address_components.map((item: any) => {
                if (item.types.includes('country')) {
                  countryRef.current = item.short_name;
                  setValue('country', item.short_name);
                }
                if (item.types.includes('locality')) {
                  setValue('city', item.long_name);
                }
                if (item.types.includes('postal_code')) {
                  setValue('zip', item.short_name);
                }
                if (item.types.includes('administrative_area_level_1')) {
                  const test = () => {
                    setValue(
                      'state',
                      countryRef.current === 'US' ? item.short_name : item.long_name,
                    );
                  };
                  setTimeout(test, 100);
                }
              });
          },
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleFormCancel = () => navigate('/reservation/reservation-detail');

  const handleUpdate = (data: IForm) => {
    updateRenterInformation({
      variables: {
        reservationContactArgs: {
          storeId,
          id: reservationData?.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          numberCode: data.numberCode,
          phone: data.phone,
          address: data.address,
          address2: data.address2 || null,
          countryId: data.country,
          state: data.state,
          stateId: data.country === 'US' ? data.state : null,
          city: data.city,
          zip: data?.zip,
          createdFrom: createdFrom.admin,
        },
        addReservationArgs: {
          reservationId: reservationData?.id,
          reservationType: reservationData?.reservationType,
          storeId,
          startDate: moment(data.reservationStartDate).format('YYYY-MM-DD'),
          endDate: moment(data.reservationEndDate).format('YYYY-MM-DD'),
          isPickupDayBefore: data.pickUpDateBefore,
          addRenter: false,
        },
      },
      onCompleted() {
        if (snackbarShowMessage)
          snackbarShowMessage('Reservation has been successfully updated', Severity.Success);
        navigate('/reservation/reservation-detail');
      },
      onError(error: any) {
        if (snackbarShowMessage) snackbarShowMessage(error.message, Severity.Error);
      },
    });
  };

  if (countryListLoading || !countryList) return <Loader />;

  return (
    <Box component='main' className='w-100 wrapper' sx={{ flexGrow: 1, p: 3 }}>
      <DrawerHeader />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className='wrapper__inner-content'>
          <Box
            component='div'
            className='inner-content__heading-outer inner-content__heading-outer--border-btm inner-content__heading-outer--btn'
          >
            <Box component='h1'>Rental Information</Box>
            <Button
              type='button'
              className='back-btn'
              onClick={() => navigate(-1)}
              disabled={updateRenterLoading}
            >
              Back
            </Button>
          </Box>
          <Grid container component='div' spacing={3}>
            <Grid item xs={12} sm={6} lg={2}>
              <Controller
                control={control}
                defaultValue=''
                name='firstName'
                render={({ field, formState }) => (
                  <CustomInput
                    label='First Name'
                    mainClassName='label'
                    name='firstName'
                    placeholder='Enter First Name'
                    type='text'
                    textInputProps={{ maxLength: 100 }}
                    field={field}
                    error={formState.errors.firstName}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Controller
                control={control}
                defaultValue=''
                name='lastName'
                render={({ field, formState }) => (
                  <CustomInput
                    label='Last Name'
                    mainClassName='label'
                    name='lastName'
                    placeholder='Enter Last Name'
                    type='text'
                    textInputProps={{ maxLength: 100 }}
                    field={field}
                    error={formState.errors.lastName}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                control={control}
                defaultValue=''
                name='email'
                render={({ field, formState }) => (
                  <CustomInput
                    label='Email'
                    mainClassName='label'
                    name='email'
                    placeholder='Enter Your Email'
                    type='email'
                    textInputProps={{ maxLength: 100 }}
                    field={field}
                    error={formState.errors.email}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Controller
                control={control}
                defaultValue=''
                name='phone'
                render={({ field, formState: { errors } }) => (
                  <CustomNumberInput
                    mainClassName='label'
                    label='Mobile Number'
                    placeholder='Enter phone'
                    onCountryCodeChange={(value) => setValue('numberCode', value)}
                    onNumberChange={(value) => field.onChange(value)}
                    countryCodeValue={watch('numberCode')}
                    numberValue={field.value}
                    error={errors.phone?.message || errors.numberCode?.message}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Box component='label' className='label'>
                Address<Box component='span'>*</Box>
              </Box>

              <Autocomplete
                freeSolo
                id='address'
                // loading={getUserListLoding}
                options={addressOptions}
                value={selectedAddress}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                onChange={(_, newValue: PlaceType | string | null) => {
                  let restructuredObj: PlaceType | null =
                    newValue && typeof newValue === 'object' && Object.assign(newValue);

                  restructuredObj = restructuredObj && {
                    ...restructuredObj,
                    description:
                      (restructuredObj && restructuredObj?.structured_formatting?.main_text) || '',
                  };

                  if (newValue && typeof newValue === 'object') {
                    setSelectedAddress(restructuredObj);
                    setAddressValues(newValue);
                    setValue('address', newValue?.description ?? '');
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setAddress(newInputValue);
                  setValue('address', newInputValue ?? '');
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder='Address'
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <LocationOnIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
                renderOption={(props, option) => {
                  const matches = option?.structured_formatting?.main_text_matched_substrings ?? [];
                  const parts: any[] = parse(
                    option.structured_formatting.main_text,
                    matches?.map((match: any) => [match.offset, match.offset + match.length]),
                  );
                  return (
                    <li {...props}>
                      <Grid container alignItems='center'>
                        <Grid item>
                          <Box component={LocationOnIcon} sx={{ color: 'secondary', mr: 2 }} />
                        </Grid>
                        <Grid item xs>
                          {parts?.map((part, index) => {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            );
                          })}
                          <Typography variant='body2' color='primary'>
                            {option.structured_formatting.secondary_text || ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
              {errors && errors.address && (
                <Typography color='red'>{errors.address.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Controller
                control={control}
                name='address2'
                defaultValue=''
                render={({ field, formState }) => (
                  <CustomInput
                    label=' Apt #, Building, Floor, etc (Optional)'
                    mainClassName='label'
                    name='address2'
                    placeholder='Apt #, Building, Floor, etc (Optional)'
                    type='text'
                    field={field}
                    textInputProps={{ maxLength: 200 }}
                    error={formState.errors.address2}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name='country'
                defaultValue=''
                render={({ field, formState }) => (
                  <CustomSelect
                    id='reservation-type'
                    label='Country'
                    mainClassName='label'
                    placeholder='-- Select Country --'
                    menuItems={CountryMenuList}
                    field={field}
                    error={formState.errors.country}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name='state'
                defaultValue=''
                render={({ field, formState }) => {
                  return watchCountry === 'US' ? (
                    <CustomSelect
                      id='reservation-state-type'
                      label='State'
                      mainClassName='label'
                      placeholder='-- Select State --'
                      menuItems={StateMenuList}
                      field={field}
                      error={formState.errors.state}
                      fullWidth
                      required
                    />
                  ) : (
                    <CustomInput
                      label='State'
                      mainClassName='label'
                      name='state'
                      placeholder='Enter Your State'
                      type='state'
                      field={field}
                      textInputProps={{ maxLength: 200 }}
                      error={formState.errors.state}
                      fullWidth
                      required
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name='city'
                defaultValue=''
                render={({ field, formState }) => (
                  <CustomInput
                    label='City'
                    mainClassName='label'
                    name='city'
                    placeholder='Enter Your City'
                    type='text'
                    field={field}
                    textInputProps={{ maxLength: 50 }}
                    error={formState.errors.city}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                control={control}
                name='zip'
                defaultValue=''
                render={({ field, formState }) => (
                  <CustomInput
                    label='Zip'
                    mainClassName='label'
                    name='zip'
                    placeholder='Enter Zip Code'
                    type='text'
                    field={field}
                    textInputProps={{ maxLength: 20 }}
                    error={formState.errors.zip}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Box component='div' className='grey-bg'>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={3}>
                    <Controller
                      control={control}
                      name='reservationStartDate'
                      render={({ field, formState }) => (
                        <CustomDatePicker
                          label='First Day of Use'
                          name='Dates'
                          mainClassName='label'
                          placeholder='MM/DD/YYYY'
                          dateFormat={DateFormat.MDY}
                          minDate={
                            reservationData?.reservationType === 'D'
                              ? settings?.dailyReservationStartDate
                              : settings?.seasonStartDate
                              ? new Date(settings?.seasonStartDate)
                              : null
                          }
                          maxDate={
                            reservationData?.reservationType === 'D'
                              ? settings?.dailyReservationEndDate
                              : settings?.seasonEndDate
                              ? new Date(settings?.seasonEndDate)
                              : null
                          }
                          field={field}
                          error={formState.errors.reservationStartDate}
                          disableManualInput
                          blockStartDate={
                            blockDates?.filter(
                              (date: any) => date.type === reservationData?.reservationType,
                            )[0]?.blockedStartDate
                          }
                          blockedEndDate={
                            blockDates?.filter(
                              (date: any) => date.type === reservationData?.reservationType,
                            )[0]?.blockedEndDate
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Controller
                      control={control}
                      name='reservationEndDate'
                      render={({ field, formState }) => (
                        <CustomDatePicker
                          label='Last Day of Use'
                          name='Dates'
                          mainClassName='label'
                          placeholder='MM/DD/YYYY'
                          dateFormat={DateFormat.MDY}
                          minDate={
                            reservationStartDate
                              ? new Date(reservationStartDate)
                              : reservationData?.reservationType === 'D'
                              ? settings?.dailyReservationStartDate
                              : settings?.seasonStartDate
                              ? new Date(settings?.seasonStartDate)
                              : null
                          }
                          maxDate={
                            reservationData?.reservationType === 'D'
                              ? settings?.dailyReservationEndDate
                              : settings?.seasonEndDate
                              ? new Date(settings?.seasonEndDate)
                              : null
                          }
                          field={field}
                          error={formState.errors.reservationEndDate}
                          blockStartDate={
                            blockDates?.filter(
                              (date: any) => date.type === reservationData?.reservationType,
                            )[0]?.blockedStartDate
                          }
                          blockedEndDate={
                            blockDates?.filter(
                              (date: any) => date.type === reservationData?.reservationType,
                            )[0]?.blockedEndDate
                          }
                          disableManualInput
                        />
                      )}
                    />
                  </Grid>
                  {reservationData?.reservationType === 'D' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Controller
                        control={control}
                        name='pickUpDateBefore'
                        render={({ field, formState }) => (
                          <CustomCheckbox
                            label='Pickup Day Before'
                            field={field}
                            mainClassName=''
                            error={formState.errors.pickUpDateBefore}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid item sm={12} md={12}>
            <Box component='div' className='btn-wrap'>
              <Button
                className='primary-btn primary-btn--svg'
                onClick={handleSubmit(handleUpdate)}
                disabled={updateRenterLoading}
              >
                <UpdateIcon /> Update
              </Button>
              <Button
                className='cancel-btn'
                onClick={handleFormCancel}
                disabled={updateRenterLoading}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RentalInformation;
